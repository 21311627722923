import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Row, Tooltip, Typography, Button, Col} from "antd";
import CartInitialBudgetStyleHolder from "./Styles/cartInitialBudgetStyle";
import NumberFormat from "./NumberFormat";
import { getTotalPice } from "../services/display";


const CartInitialBudgetComponent = ({
  stepsCurrent,
  updateByAmountBudget,
  amountBudget,
  isPaymentStatusApproved,
  currency,
  cart,
  programmatic,
  invoiceIssuingCountry,
  date_from,
  date_to}) => {

  const { t } = useTranslation();
    const [amountBudgetTraditional, setAmountBudgetTraditional] = useState(0)
    useEffect(() => {
      if(programmatic === 'programmatic') return
      setAmountBudgetTraditional(getTotalPice(cart, date_from, date_to))
    },[date_from, date_to, cart])


  return (
    <CartInitialBudgetStyleHolder>
      <div className="cartBudget">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            { programmatic === "programmatic" ? (
              <>
                {t("Campaign budget")}{" "}
                <Tooltip
                  placement="left"
                  title={t(
                    "You can configure the impressions per screen and see how the updated budget varies according to the impact you want."
                  )}
                >
                  <Icon type="question-circle" theme="outlined" style={{ marginLeft: "5px"}}/>
                </Tooltip>
              </>
            ) : (
              t("Total price")
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className='content-info' style={{marginTop: '10px'}}>
                <NumberFormat
                  tax={invoiceIssuingCountry != 'F'}
                  value={ programmatic === 'programmatic' ?
                      Number(amountBudget).toFixed(2)
                      : amountBudgetTraditional
                  }
                  currency= { cart[0] && cart[0].id ? cart[0].price_currency : currency }
                />
              </div>
          </Col>
        </Row>
        {(programmatic === "programmatic" && stepsCurrent !== 0 ) && (
          <Row style={{textAlign: "center"}}>
            {((stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
                <Tooltip
                  className="appear-animation-without-movement"
                  placement="right"
                  title={t('Go back to the original budget (n_amountBudget) and recalculate impressions (changes you have made in impressions for each Spot will be erased)').replace(
                    "n_amountBudget",
                    currency + '$' + amountBudget
                  )}
                >
                  <Button
                    className='groupFilterButton'
                    onClick={() => updateByAmountBudget(amountBudget)}
                  >
                    {t("Redistribute")}
                  </Button>
                </Tooltip>
              )}
          </Row>
        )}
      </div>
    </CartInitialBudgetStyleHolder>
  );
}

export default CartInitialBudgetComponent;

// a library to wrap and simplify api calls
import apisauce from "apisauce";
import storage from "redux-persist/lib/storage";
import qs from 'qs'
import { getDomain } from "../services/utils";

// our "constructor"
const create = (baseURL = process.env.REACT_APP_API_ENDPOINT) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  let config =  {
    baseURL,
    headers: {
      "Cache-Control": "no-cache"
    },
    timeout: 60000,
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'indices'})
    }
  }

  const api = apisauce.create(config);

  const apiFiles = apisauce.create({
    ...config,
    responseType: 'blob'
  })

  const unauthorizedMonitor = async response => {
    if (((
      response.status === 400 && response.data.error == "token_expired")
      || response.status === 401
      || (response.status === 403 && response.data.message == "Password changed")
      || (response.status === 403 && response.data.message == "User deactivated")
    ) && await storage.getItem("authHeaders")) {
      const currentUrl = window.location.pathname;
      const isLoginPage = currentUrl.includes("/login");
      // Unless we are in the login from CMS url, reload page
      if (!isLoginPage) {
        await storage.setItem("authHeaders", null);
        let redirectUrl = "/reload/" + btoa(window.location.pathname);
        if (response.data.message == "Password changed" || response.data.message == "User deactivated") {
          redirectUrl +=  "/" + btoa(JSON.stringify(response.data));
        }
        window.location.href = redirectUrl;
      }
    }
  }
  api.addMonitor(unauthorizedMonitor);

  const authRequestTransform = request => async () => {
    const storageHeaders = await storage.getItem("authHeaders");

    if (!storageHeaders) {
      api.setHeader("access-token", null);
      apiFiles.setHeader("access-token", null);
      return;
    }

    const headers = JSON.parse(storageHeaders);

    api.setHeaders(headers);
    apiFiles.setHeaders(headers);

    request.headers = { ...request.headers, ...headers };
  };

  api.addAsyncRequestTransform(authRequestTransform);
  apiFiles.addAsyncRequestTransform(authRequestTransform);

  const whiteLabelNames = {
    latinad: 'latinad',
    grupoVia: 'grupo_via',
    mediaShake: 'mediashake',
    smartFit: 'smartfit',
    duoPrint:'duoprint',
    territorioDigital: 'territorio_digital',
    movImagen: 'movimagen',
    yoda: 'yoda',
    hakMedia: 'hak_media'
  };

  const whiteLabelRequestTransform = request => async () => {
    const domain = getDomain(window.location.hostname);
    const whiteLabel = whiteLabelNames[domain];
     if (request.data instanceof FormData) {
      request.data.append('white_label_name', whiteLabel);
    } else {
      request.data = { ...request.data, white_label_name: whiteLabel };
    }
    request.url = `${request.url}${request.url.includes('?')?'&':'?'}white_label_name=${whiteLabel}`;
  }

  api.addAsyncRequestTransform(whiteLabelRequestTransform);
  apiFiles.addAsyncRequestTransform(whiteLabelRequestTransform);

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const setHeaders = headers => api.setHeaders(headers);

  const userLogin = (user, password, code2FA) =>
    api.post("/login", { login: user, password, two_factor_code: code2FA, remember: true });
  const getUserInfo = () => api.get("/users/me");
  const userRegister = user => api.post("/users", user);
  const companyRegister = company => api.post("/companies/request-account", company);
  const userResetPassword = (email, password, password_confirmation, token) =>
    api.post("/reset-password", {
      email,
      password,
      password_confirmation,
      token
    });
  const userForgotPassword = email => api.post("/forgot-password", { email });
  const resendVerificationEmail = email => api.get(`/email-verification/resend?email=${email}`);
  const userEmailUpdate = (id, first_name, last_name, username, email) =>
    api.put(`/users/${id}`, { first_name, last_name, username, email });
  const userPasswordUpdate = (
    id,
    old_password,
    password,
    password_confirmation
  ) =>
    api.put(`/users/${id}/password`, {
      old_password,
      password,
      password_confirmation
    });

  const getDisplays = params => api.get("/displays/results", params);
  const getDisplayInfo = (id, clientId, currency, invoice_issuing_country, external_dsp_id) => {
      const queryParams = `external_dsp_id=${external_dsp_id}&&currency=${currency}&&invoice_issuing_country=${invoice_issuing_country}`;
      const url = clientId ? `/displays/${id}/results?client_id=${clientId}&&${queryParams}` : `/displays/${id}/results?${queryParams}`;
      return api.get(url);
  };
  const getExcelDisplays = params => apiFiles.post("/displays/reports/selected", params);
  const getCurrencyExchangeRate = () => api.get(`/currency-rate`);
  const getConversionRates = () => api.get(`/conversion-rates`);
  const getCategories = () => api.get(`/contents/content-categories`);

  const createCampaign = params => api.post(`/campaigns`, params);
  const editCampaign = (campaignId, params) => api.put(`/campaigns/${campaignId}`, params);
  const getCampaigns = params => api.get(`users/me/campaigns`, params);
  const getCampaign = id => api.get(`/campaigns/${id}?show_graphics_evidences=1`);
  const deleteCampaign = campaignId => api.delete(`campaigns/${campaignId}`);
  const createReplicateCampaing = (campaignId, params) => api.post(`/campaigns/${campaignId}/replicate`, params);
  const requestSpecialCpm = (display_id, requested_cpm, currency) => api.post(`/displays/cpm-price-arrangement-request`, {display_id, requested_cpm, currency});
  const requestSpecialPrice = (display_id, requested_base_price_per_day, currency) => api.post(`/displays/price-arrangement-request`, {display_id, requested_base_price_per_day, currency});

  const getClients = userId => api.get(`users/${userId}/clients`);
  const getBrands = clientId => api.get(`clients/${clientId}/brands`);
  const getCountries = () => api.get(`countries`);
  const getLocation = () => api.get(`users-country`);
  const getExternalDsps = () => api.get(`external-dsps`);

  const getProposal = (reportData) =>
    api.post(`/proposal/shared`, { ...reportData });
  const getQuotes = (userId, params) => api.get(`users/${userId}/quotes`, params);
  const setArchivedQuote = (quoteId, archived) => api.put(`quotes/${quoteId}`, { archived });
  const getQuote = token => api.get(`quotes?token=${token}`);
  const createQuote = (quoteData) =>
    api.post("/quotes", {
      ...quoteData
    });
  const editQuote = (quoteId, quoteData) =>
    api.put(`/quotes/${quoteId}`, {
      ...quoteData
    });
  const deleteQuote = quoteId => api.delete(`quotes/${quoteId}`);

  const createCoupon = (campaignId, params) => api.post(`/campaigns/${campaignId}/coupons`, params);
  const deleteCoupon = (campaignId, couponId) => api.delete(`/campaigns/${campaignId}/coupons/${couponId}`);

  const getContent = id => api.get(`/contents/${id}`);
  const updateContent = (id, params) => api.put(`/contents/${id}`, params);

  const getContents = (id, params) => api.get(`/companies/${id}/contents`, params);
  const getTagsContents = (id) => api.get(`/companies/${id}/contents-tags`, {"from": 'dsp'});

  const updateCampaignContentDisplay = (relationId, newRelation, campaignId) => api.put(`/campaigns/${campaignId}/content_display/${relationId}`, newRelation);

  const uploadFile = file => {
    const form = new FormData();
    form.append("name", 'Diseño en canva');
    form.append("type", file.type);
    form.append("canva_id", file.canva_id);
    form.append("file", file.file);
    form.append("tags[]", file.tags);
    const headers = {
      "content-type": "multipart/form-data;"
    };

    return api.post("/contents", form, { headers });
  };

  // const getMercadoPagoPaymentMethods = () =>
  //   api.get("/payments/mercadopago/payment-methods");
  const createPayment = (campaignId, paymentData) =>
    api.post(`/campaigns/${campaignId}/payments`, { ...paymentData });
  const createPurchaseOrders = (companiesId, campaignId) =>
    api.post(`/companies/${companiesId}/campaigns/${campaignId}/purchase_orders`);
  const getCampaignPayments = (campaignId) =>
    api.get(`/campaigns/${campaignId}/payments`);
  const getPayment = (paymentId) =>
    api.get(`/payments/${paymentId}`);
  const deleteCampaignPayment = (paymentId) =>
    api.delete(`/payments/${paymentId}`);
  const getMpInitPoint = (campaignId, paymentData) =>
    api.post(`/campaigns/${campaignId}/mp-init-point`, { ...paymentData });
  const getReport = (reportData) =>
    api.post(`/reports/shared`, { ...reportData });
  const getSingleReport = params => api.get("/reports", params);
  const getLastBillingInfo = (userId) =>
    api.get(`/users/${userId}/last-billing-information`);

  const getAudience = (data) =>
    api.post(`/audience`, { ...data });
  const getAudienceDatsWhy = (params) =>
    api.get(`/dw-audience`, params);
  const getAudienceHoursOfDay = (params) =>
    api.get(`/display_audience/hours_of_day`, params);
  const getAudienceDaysOfWeek = (params) =>
    api.get(`/display_audience/days_of_week`, params);
  const getProposalAudience = (data) => api.post('/proposal_audience', data);

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    setHeaders,
    userLogin,
    getUserInfo,
    userRegister,
    userResetPassword,
    userForgotPassword,
    resendVerificationEmail,
    userEmailUpdate,
    userPasswordUpdate,

    companyRegister,

    getDisplays,
    getDisplayInfo,
    getExcelDisplays,
    getCurrencyExchangeRate,
    getConversionRates,
    getCategories,

    getContent,
    getContents,
    getTagsContents,
    updateContent,

    updateCampaignContentDisplay,
    createCampaign,
    editCampaign,
    getCampaigns,
    getCampaign,
    deleteCampaign,
    uploadFile,
    createReplicateCampaing,
    requestSpecialCpm,
    requestSpecialPrice,

    getClients,
    getBrands,
    getCountries,
    getLocation,
    getExternalDsps,

    getQuotes,
    getQuote,
    deleteQuote,
    createQuote,
    editQuote,
    setArchivedQuote,

    createCoupon,
    deleteCoupon,

    // getMercadoPagoPaymentMethods,
    createPayment,
    createPurchaseOrders,
    getCampaignPayments,
    deleteCampaignPayment,
    getPayment,
    getMpInitPoint,

    getReport,
    getProposal,
    getSingleReport,

    getLastBillingInfo,

    getAudience,
    getAudienceDatsWhy,
    getAudienceHoursOfDay,
    getAudienceDaysOfWeek,
    getProposalAudience,
  };
};

// let's return back our create method as the default.
export default {
  create
};

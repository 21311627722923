import { getUrl } from "../services/utils";

export function getCMSLink (token, campaignId) {
    const loginInfo = {
        token: token,
        campaignId: campaignId
    };
    const base64Info = window.btoa(JSON.stringify(loginInfo));
    let url = getUrl(window.location, `#/login`, `?token=${base64Info}`)
    return url;
}
import React from 'react';
import { Tag, Tooltip, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

const SpecificationAlert = ({
  programmatic,
  slotLength,
  contentLength,
  pricePerDay,
  contentType,
  formatSettings
}) => {

  const { t } = useTranslation();
  const exceededSeconds = Math.floor((contentLength - slotLength) / 1000);
  const permittedTypeContent = !formatSettings[contentType]

  const calculateExtraPrice = () => {
    return Math.round((pricePerDay * (Math.floor(contentLength / 1000) * 1000) / slotLength - pricePerDay) * 100) / 100;
  };

  // If not exceeded duration, not render
  if ( exceededSeconds < 1 && !permittedTypeContent) {
    return null;
  }

  return (
    <>
      {permittedTypeContent && (
        <Tag color="#f74f48" style={{ marginTop: "5px", whiteSpace: "normal", wordBreak: "break-word" }}>
          <span style={{ display: "block", lineHeight: "1.2", textAlign: "center" }}>
            {t('This screen does not accept contents of type')} {t(contentType)}
          </span>
        </Tag>
      )}
      {programmatic === 'programmatic' && contentType === 'video' ? (
        <Tooltip
          placement="left"
          title={
            <span>{t('In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time')}</span>
          }
        >
          <Tag color="#f74f48" style={{marginTop: "5px" }}>
            <span>
              {t('Exceeded in')} {exceededSeconds} {t('secs')} <Icon type="question-circle"/>
            </span>
          </Tag>
        </Tooltip>
      ) : programmatic === 'traditional' && contentType === 'video' ? (
        <Tooltip
          placement="left"
          title={
            <span>
              {t('If you do not change the ad for a shorter one, it will add approximately')} ${calculateExtraPrice()} {t('per day for this screen.')}
            </span>
          }
        >
          <Tag color="#f74f48" style={{marginTop: "5px" }}>
            <span>
              {t('Exceeded in')} {exceededSeconds} {t('secs')} <Icon type="question-circle"/>
            </span>
          </Tag>
        </Tooltip>
      ) : null}
      <br />
    </>
  );
};

export default SpecificationAlert;
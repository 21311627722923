import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Icon, notification } from "antd";
import { trackOutboundLink } from '../middlewares/google-analytics.js';
import FooterHolder from "./Styles/footerStyle";
import { withTranslation } from 'react-i18next';
import logo from "../images/logo.svg";
import logoLatinad from "../images/latinad.svg";
import UrlEnum from "../constants/UrlEnum";

class Footer extends Component {

  openZoomAlert = placement => {
    notification.info({
      message: this.props.t('You can start creating your campaign by entering the zone and date.'),
      duration: 7,
      placement
    });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <FooterHolder>
        <Row type="flex" justify="center" className="footerRow">
          <Col xs={24} sm={24} md={9} lg={9} xl={9}  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h1 class="gradient-title size-title-gradient-footer">{t("Technological solutions for the DOOH industry")}</h1>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xl={5} style={{ display: "flex", justifyContent: "center" }}>
            <Row>
              <h2>{t('Company')}</h2>
              <div>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs} onClick={() => this.openZoomAlert('topRight')}>
                  {t('Get to know us')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/agencias"}>
                    {t('Agency')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/media-owners"}>
                    {t('Media owners')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/contactos"}>
                    {t('Contact')}
                  </a>
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xl={5} style={{ display: "flex", justifyContent: "center" }}>
            <Row>
              <h2>{t("Product")}</h2>
              <div>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/cms"}>
                    {t('CMS')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/ssp"}>
                    {t('SSP')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/audiencia"}>
                    {t('Audience')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/certificacion"}>
                    {t('Certifications')}
                  </a>
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xl={5} style={{ display: "flex", justifyContent: "center" }}>
            <Row>
              <h2>{t('Community')}</h2>
              <div>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/case-studies-page"}>
                    {t('Success cases')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href={ UrlEnum.landingEs + "/blog"}>
                    {t('Blog')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href="https://help.latinad.com/">
                    {t('Help')}
                  </a>
                </p>
                <div>
                  <a target="_blank" href="https://www.facebook.com/LatinAdDOOH" onClick={(e) => trackOutboundLink('https://www.facebook.com/LatinAdDOOH', e)}>
                    <Icon
                      style={{
                        fontSize: "25px",
                        color: "#424242"
                      }}
                      type="facebook"
                    />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/latin.ad/" onClick={(e) => trackOutboundLink('https://www.instagram.com/latin.ad/', e)}>
                    <Icon
                      style={{
                        fontSize: "25px",
                        marginLeft: "10px",
                        color: "#424242"
                      }}
                      type="instagram"
                    />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/latin.ad/" onClick={(e) => trackOutboundLink('https://www.linkedin.com/company/latinad', e)}>
                    <Icon
                      style={{
                        fontSize: "25px",
                        marginLeft: "10px",
                        color: "#424242"
                      }}
                      type="linkedin"
                    />
                  </a>
                  <a target="_blank" href="https://api.whatsapp.com/send?phone=5492615019508" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=5492615019508', e)}>
                    <Icon
                      style={{
                        fontSize: "25px",
                        marginLeft: "10px",
                        color: "#424242"
                      }}
                      type="phone"
                    />
                  </a>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row
              className="disclaimer"
              type="flex"
              justify="center"
              align="middle"
            >
              © {(new Date().getFullYear())} LatinAd. All rights reserved.
            </Row>
          </Col>
        </Row>
      </FooterHolder>
    );
  }
}
export default withTranslation()(Footer);

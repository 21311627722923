import React from "react";
import RegionLangHolder from "./Styles/regionLanguageStyle";
import { CircleFlag } from "react-circle-flags";
import { Row, Col, Button, Icon } from "antd";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

class RegionLanguageComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        const {t,
               invoiceIssuingCountry ,
               billingCountry,
               showModal,
               currency,
               disabledButton
        } = this.props;

        const invoiceIssuingCountryLower = invoiceIssuingCountry ? invoiceIssuingCountry.toLowerCase(): null

        return (
          <RegionLangHolder>
            <Row type="flex" align="middle">
              <Col>
                <Button
                  style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                  }}
                  size="small"
                  onClick={() => showModal()}
                  disabled={disabledButton}
                >
                  <Icon type="global" />
                  {t('language iso 639-1')}
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                  }}
                  size="small"
                  onClick={() => showModal()}
                  disabled={disabledButton}
                >
                  <div style={{ paddingBottom: "2px" }}>
                    {
                      billingCountry === "International billing" || billingCountry === "Factura Internacional" ? <FontAwesomeIcon icon={faGlobeAmericas} /> : <CircleFlag countryCode={invoiceIssuingCountryLower} height="16.5" width="14"/>
                    }
                    <span style={{ paddingLeft: "8px" }}>{billingCountry}</span>
                  </div>
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                  }}
                  size="small"
                  onClick={() => showModal()}
                  disabled={disabledButton}
                >
                  <Icon type="dollar" />
                  {currency}
                </Button>
              </Col>
            </Row>
          </RegionLangHolder>
        );
    }
}

export default withTranslation()(RegionLanguageComponent);

const VersionEnum = [
  { "key": "V1", "name": "V1" },
  { "key": "V2", "name": "V2" },
  { "key": "V3", "name": "V3" },
  { "key": "V4", "name": "V4" },
  { "key": "V5", "name": "V5" },
  { "key": "V6", "name": "V6" },
  { "key": "V7", "name": "V7" },
  { "key": "V8", "name": "V8" },
  { "key": "V9", "name": "V9" },
  { "key": "V10", "name": "V10" },
  { "key": "V11", "name": "V11" },
  { "key": "V12", "name": "V12" },
  { "key": "V13", "name": "V13" },
  { "key": "V14", "name": "V14" },
  { "key": "V15", "name": "V15" },
  { "key": "V16", "name": "V16" },
  { "key": "V17", "name": "V17" },
  { "key": "V18", "name": "V18" },
  { "key": "V19", "name": "V19" },
  { "key": "V20", "name": "V20" },
];

export default VersionEnum;
import { connect } from 'react-redux'
import CartInitialBudgetComponent from "../components/CartInitialBudgetComponent"
import { setProgrammatic } from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  cart: display.cart,
  currency: userAccount.currency,
  currentCampaign: display.currentCampaign,
  editCampaignActive: display.editCampaignActive,
  realAmount: display.realAmount,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
  amountBudget: display.amountBudget,
  programmatic: display.programmatic,
  date_from: display.date_from,
  date_to: display.date_to
})

export default connect(mapStateToProps)(CartInitialBudgetComponent)

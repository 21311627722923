const countriesEnum = [
  { "key": "DE", "name": "Alemania" },
  { "key": "AG", "name": "Antigua y Barbuda" },
  { "key": "AR", "name": "Argentina" },
  { "key": "AW", "name": "Aruba" },
  { "key": "AU", "name": "Australia" },
  { "key": "BS", "name": "Bahamas" },
  { "key": "BB", "name": "Barbados" },
  { "key": "BZ", "name": "Belice" },
  { "key": "BO", "name": "Bolivia" },
  { "key": "BR", "name": "Brasil" },
  { "key": "CA", "name": "Canadá" },
  { "key": "BQ", "name": "Caribe Neerlandés" },
  { "key": "CL", "name": "Chile" },
  { "key": "CN", "name": "China" },
  { "key": "CO", "name": "Colombia" },
  { "key": "CR", "name": "Costa Rica" },
  { "key": "SV", "name": "El Salvador" },
  { "key": "AE", "name": "Emiratos Árabes Unidos" },
  { "key": "EC", "name": "Ecuador" },
  { "key": "ES", "name": "España" },
  { "key": "US", "name": "Estados Unidos" },
  { "key": "PH", "name": "Filipinas" },
  { "key": "FR", "name": "Francia" },
  { "key": "GT", "name": "Guatemala" },
  { "key": "HN", "name": "Honduras" },
  { "key": "IN", "name": "India" },
  { "key": "KY", "name": "Islas Caimán" },
  { "key": "IT", "name": "Italia" },
  { "key": "JM", "name": "Jamaica" },
  { "key": "LA", "name": "Laos" },
  { "key": "MX", "name": "México" },
  { "key": "NI", "name": "Nicaragua" },
  { "key": "PA", "name": "Panamá" },
  { "key": "PY", "name": "Paraguay" },
  { "key": "PE", "name": "Perú" },
  { "key": "PT", "name": "Portugal" },
  { "key": "PR", "name": "Puerto Rico" },
  { "key": "GB", "name": "Reino Unido" },
  { "key": "DO", "name": "República Dominicana" },
  { "key": "RU", "name": "Rusia" },
  { "key": "KN", "name": "San Cristóbal y Nieves" },
  { "key": "LC", "name": "Santa Lucía" },
  { "key": "CH", "name": "Suiza" },
  { "key": "TH", "name": "Tailandia" },
  { "key": "TT", "name": "Trinidad y Tobago" },
  { "key": "TR", "name": "Turquía" },
  { "key": "UY", "name": "Uruguay" },
  { "key": "VE", "name": "Venezuela" }
]

export default countriesEnum;

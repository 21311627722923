import React from "react";
import TopbarHolder from "./Styles/topbarStyle";
import storage from "redux-persist/lib/storage";
import { Link } from "react-router-dom";
import {
  Affix,
  Drawer,
  Modal,
  Button,
  Input,
  Icon,
  Form,
  Avatar,
  Popover,
  message,
  Row,
  Col,
  Divider,
  Result,
  Menu,
  Dropdown,
  Spin,
  AutoComplete,
  Alert,
} from "antd";
import { getUrl } from "../services/utils";
import { withRouter } from "react-router";
import { getValidationError } from "../services/display";

import logo from "../images/latinad.svg";
import logoLatinad from "../images/latinad.svg";
import logoAudience from "../images/products/audience.svg";
import logoCms from "../images/products/cms.svg";
import logoSsp from "../images/products/ssp.svg";
import logoOoh from "../images/products/ooh.svg";
import logoPlayer from "../images/products/player.svg";
import logoFenix from "../images/whiteLabel/fenix-publinet.png";
import logoGrupovia from "../images/whiteLabel/grupo-via.svg";
import logoMediaShake from "../images/whiteLabel/media-shake.png";
import logoSmartFit from "../images/whiteLabel/smart-fit.png";
import logoSmartFitWhite from "../images/whiteLabel/smart-fit-white.svg";
import logoDuoPrint from "../images/whiteLabel/duo-print.png";
import logoTerritorioDigital from "../images/whiteLabel/territorio-digital.png";
import logoMovImagen from "../images/whiteLabel/movimagen.png";
import logoYoda from "../images/whiteLabel/yoda.png";
import logoHakMedia from "../images/whiteLabel/hak-media.png";
import logoWhatsApp from "../images/whatsapp.png";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Dotdotdot from "react-dotdotdot";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole, getDomain } from "../services/utils";
import LanguageCurrencyModalContainer from "../containers/LanguageCurrencyModalContainer";
import RegionLanguageComponent from "./RegionLanguageComponent";
import Colors from "../themes/Colors";
import UrlEnum from "../constants/UrlEnum";
import VerifiedCheck from "./customComponents/VerifiedCheck/VerifiedCheck";
import countriesEnum from "../constants/CountriesEnum";
import { getCMSLink } from "../services/cmsLogin";
import { dom } from "@fortawesome/fontawesome-svg-core";
import countriesList from '../constants/CountriesList';

class TopbarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.exitEdit = this.exitEdit.bind(this);
    this.settingFooterPading = this.settingFooterPading.bind(this);
    this.getFooterClassName = this.getFooterClassName.bind(this);
  }

  state = {
    selectRegistrationTypeModalVisible: false,
    drawerVisible: false,
    loginModalVisible: false,
    registrationModalVisible: false,
    registrationAgencyModalVisible: false,
    providerRegistrationModalVisible: false,
    forgotPasswordModalVisible: false,
    currencyModalVisible: false,
    resendValidationModalVisible: false,
    successfullRegistration: false,
    setModalType: null,
    loginEmail: "",
    loginPassword: "",
    code2FA: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    companyName: "",
    country: "",
    state: "",
    forgottenEmail: "",
    errors: {},
    language: "es",
    exitEditModal: false,
    loadingExitEditModal: false,
    domain: getDomain(window.location.hostname),
    showInformationButton: false,
    logo: "",
    windowWidth: window.innerWidth,
    alert2Fa: false,
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    });
  };
  onClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };


  //finish status edit campaing

  exitEdit() {
    this.setState({ loadingEditCampaign: true });
    this.props.editCampaignInactive();
    this.settingFooterPading(false);
    this.props.clearCampaignData();
    this.props.cleanAudience();
    this.props.removeDisplaysCart();
    this.setState({ exitEditModal: false });
    this.setState({ loadingEditCampaign: false });
    this.props.history.push("/campaigns");
  }

  exitEditWhenIsSteps() {
    this.props.editCampaignInactive();
    this.settingFooterPading(false);
    this.setState({ exitEditModal: false });
    this.setState({ loadingEditCampaign: false });
  }

  exitEditModal = () => {
    this.setState({ exitEditModal: true });
  };
  //Add or delete padding in footer of root class
  settingFooterPading(edit) {
    const rootId = document.querySelector("#root");
    if (edit !== undefined) {
      rootId.className = edit ? "footerEditCampaing" : "footerNoEditCampaing";
      return;
    }
    rootId.className = this.props.editCampaignActive
      ? "footerEditCampaing"
      : "footerNoEditCampaing";
  }

  showLoginModal = () => {
    this.props.cleanErrors();
    this.clearRequire2FA();
    this.setState({
      selectRegistrationTypeModalVisible: false,
    })
    this.setState({
      loginModalVisible: true,
      registrationModalVisible: false,
    });
  };

  checkTwoFactorAndProperties(user, isSuperUser) {
    const properties = (user.company && user.company.properties) || [];
    return (
      !isSuperUser &&
      properties.some(
        prop => prop.property_key === "force_2_factor" && prop.property_value === "1"
      ) &&
      !user.two_factor_confirmed
    );
  }

  handleLoginOk = (e) => {
    this.clearRequire2FA();
    const { user, setBilling, setIsSuperUser, loginInfo, resetInitWithToken } = this.props;
    // Setting F international when holding is magnite or place-exchange
    if (user && user.holding && (user.holding.id === 33 || user.holding.id === 34)) {
      setBilling('USD', 'Factura Internacional', 'F');
    } else if (user && user.company) {
      const companyCountry = countriesEnum.find(c => c.name === user.company.country);
      if (companyCountry) {
        setBilling(user.company.currency, user.company.country, companyCountry.code);
      }
    }
    // When access with token
    if( user && loginInfo ) {
      const { token: accessToken, campaignId, gotoPayment, gotoNewPayment } = loginInfo;
      resetInitWithToken();
      if (accessToken && campaignId) {
        if (gotoPayment) {
          this.props.history.push(`/campaign/${campaignId}/payment`);
        } else if (gotoNewPayment) {
          this.props.history.push(`/campaignManager?campaignPayment=${campaignId}`);
        } else {
          this.props.history.push(`/campaign/${campaignId}`);
        }
      }
    }

    let isSuperUser = (user && hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency, UserRoleEnum.superModeratorMediaCompany]))
    setIsSuperUser(isSuperUser);
    if ( this.checkTwoFactorAndProperties( user, isSuperUser )) {
      this.props.logout();
      this.setState({ alert2Fa: true });
      return;
    }

    this.setState({
      loginModalVisible: false,
    });
  };

  handleLoginCancel = (e) => {
    this.setState({ alert2Fa: false });
    this.props.cleanErrors();
    this.clearRequire2FA();
    this.props.setShouldPromptLogin(false, null);
    this.setState({
      loginModalVisible: false,
    });
  };

  clearRequire2FA = () => {
    this.props.setUserLoginRequire2fa(false);
    this.setState({ code2FA: null });
  }

  showRegistrationModal = () => {
    this.props.cleanErrors();
    this.setState({
      registrationModalVisible: true,
      loginModalVisible: false,
      successfullRegistration: false,
    });
  };


  showRegistrationAgencyModal = () => {
    this.props.cleanErrors();
    this.setState({
      registrationAgencyModalVisible: true,
      loginModalVisible: false,
      successfullRegistration: false
    });
  };

  handleRegistrationOk = (e) => {
    this.setState({
      successfullRegistration: true,
    });
  };

  handleRegistrationCancel = () => {
    this.props.cleanErrors();
    this.setState({
      registrationModalVisible: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      companyName: "",
      country: "",
      state: "",
      errors: {},
      setModalType: null
    });
  };

  handleRegistrationAgencyCancel = () => {
    this.props.cleanErrors();
    this.setState({
      registrationAgencyModalVisible: false,
      companyName: "",
      country: "",
      state: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      errors: {},
      setModalType: null
    });
  };


  showProviderRegistrationModal = () => {
    this.setState({
      providerRegistrationModalVisible: true,
    });
  };

  handleProviderRegistrationOk = () => {
    this.setState({
      providerRegistrationModalVisible: false,
    });
    message.success(
      this.props.t(
        "Data entered correctly, we will send you an email when we confirm your data."
      ),
      8
    );
  };

  handleProviderRegistrationCancel = (e) => {
    this.props.cleanErrors();
    this.setState({
      providerRegistrationModalVisible: false,
    });
  };

  showForgotPasswordModal = () => {
    this.props.cleanErrors();
    this.setState({
      forgotPasswordModalVisible: true,
      loginModalVisible: false,
    });
  };

  handleForgotPasswordOk = (e) => {
    const errors = {};
    this.props.cleanErrors();

    if (!this.state.forgottenEmail) {
      errors["forgottenEmail"] = this.props.t("Can not be blank");
    }
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return;
    }

    this.props.userForgotPasswordRequest(this.state.forgottenEmail);
  };

  handleForgotPasswordCancel = (e) => {
    this.props.cleanErrors();
    this.setState({
      forgotPasswordModalVisible: false,
      loginModalVisible: true,
    });
  };

  showResendValidationModal = () => {
    this.props.cleanErrors();
    this.setState({
      resendValidationModalVisible: true,
      loginModalVisible: false,
    });
  };

  showSelectRegistrationTypeModal = () => {
    this.setState({
      selectRegistrationTypeModalVisible: true,
    });
  };

  handleResendValidationOk = (e) => {
    const errors = {};

    this.props.cleanErrors();

    if (!this.state.resendVerificationEmail) {
      errors["resendVerificationEmail"] = this.props.t("Can not be blank");
    }
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return;
    }

    this.props.resendVerificationEmailRequest(this.state.resendVerificationEmail);
  };

  handleResendValidationCancel = (e) => {
    this.props.cleanErrors();
    this.setState({
      resendValidationModalVisible: false,
    });
  };

  handleInputChange(event) {
    const target = event.target;
    this.isEmailChange(target);
    this.setState({
      [target.name]: target.value,
    });
  }

  isEmailChange = (target) => {
    if (target.name === "loginEmail" && this.props.require2FA) this.clearRequire2FA();
  }

  handleRegister() {
    const errors = {};
    this.props.cleanErrors();
    if (!this.state.firstName) {
      errors["firstName"] = this.props.t("Can not be blank");
    }
    if (!this.state.lastName) {
      errors["lastName"] = this.props.t("Can not be blank");
    }
    if (!this.state.email) {
      errors["email"] = this.props.t("Can not be blank");
    }
    if (!this.state.phone) {
      errors["phone"] = this.props.t("Can not be blank");
    }
    if (!this.state.password) {
      errors["password"] = this.props.t("Can not be blank");
    } else if (this.state.password.length < 6) {
      errors["password"] = this.props.t("Password must be at least 6 characters");
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return;
    }

    const result = this.props.register({
      username: this.state.username,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
    });

    // Only clear the state if there are no errors
    if (!this.state.errors || this.state.errors.length === 0) {
      this.setState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
      });
    }

    return result;
  }

  handleRegisterCompany() {
    const errors = {};
    this.props.cleanErrors();
    if (!this.state.companyName) {
      errors["companyName"] = this.props.t("Can not be blank");
    }
    if (!this.state.firstName) {
      errors["firstName"] = this.props.t("Can not be blank");
    }
    if (!this.state.lastName) {
      errors["lastName"] = this.props.t("Can not be blank");
    }
    if (!this.state.email) {
      errors["email"] = this.props.t("Can not be blank");
    }
    if (!this.state.phone) {
      errors["phone"] = this.props.t("Can not be blank");
    }
    if (!this.state.password) {
      errors["password"] = this.props.t("Can not be blank");
    } else if (this.state.password.length < 6) {
      errors["password"] = this.props.t("Password must be at least 6 characters");
    }
    if (!this.state.country) {
      errors["country"] = this.props.t("Can not be blank");
    }
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return;
    }

    const result = this.props.registerCompany({
      company_name: this.state.companyName,
      country: this.state.country,
      state: this.state.state,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      type: "agency",
    });

    // Only clear the state if there are no errors
    if (!this.state.errors || this.state.errors.length === 0) {
      this.setState({
        companyName: "",
        country: "",
        state: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
      });
    }

    return result;
  }

  handleLogin() {
    this.setState({ alert2Fa: false });
    const errors = {};
    this.props.cleanErrors();
    const filedsRequires = ["loginEmail", "loginPassword"]

    if (this.props.require2FA) filedsRequires.push("code2FA")

    filedsRequires.forEach(require => {
      if (!this.state[require]) {
        errors[require] = this.props.t("Can not be blank");
      }
    });

    if (Object.keys(errors).length > 0) return this.setState({ errors });

    this.setState({ errors: [] });
    this.props.login(
      this.state.loginEmail, this.state.loginPassword, this.state.code2FA ? this.state.code2FA : null
    );
  }

  handleLogout() {
    this.clearRequire2FA();
    this.props.history.push("/");
    this.props.logout();
    message.success(this.props.t("Session closed successfully"));
  }

  getLogo (domain) {
    const path = this.props.history.location.pathname === "/" ? "home" : this.props.history.location.pathname;
    const logos = {
      latinad: logoLatinad,
      fenix: logoFenix,
      grupoVia: logoGrupovia,
      mediaShake: logoMediaShake,
      smartFit: logoSmartFit,
      duoPrint: logoDuoPrint,
      territorioDigital: logoTerritorioDigital,
      movImagen: logoMovImagen,
      yoda: logoYoda,
      hakMedia: logoHakMedia,
    };

    return logos[domain] || logo;
  };

  componentDidMount() {
    this.setState({ alert2Fa: false });
    const { domain } = this.state
    storage.getItem("language").then((language) => {
      this.setState({
        language: language,
      });
    });
    this.checkRegistrationModal()

    if( domain != "latinad" && domain != "yoda" && domain != "hakMedia"){
      this.setState ( {
        showInformationButton: true,
      })
    }

    this.setState({logo: this.getLogo(domain)})

    // Add listener for resize
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // Remove listener when the component is unmounted
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.editCampaignActive && !this.props.currentCampaign) {
      const campaignRoute = '/campaignManager';
      if (this.props.history.location.pathname === campaignRoute)
        this.exitEditWhenIsSteps()
      else
        this.exitEdit()
    }
    if (this.props.creatingCompany && !nextProps.creatingCompany) {
      if (nextProps.errors.length === 0) {
        this.handleProviderRegistrationOk();
      }
    }
    if (this.props.fetching && !nextProps.fetching) {
      if (
        nextProps.user &&
        hasAccessRole(nextProps.user.roles, [
          UserRoleEnum.agencyMember,
          UserRoleEnum.companyMember
        ])
      ) {
        this.props.getClients(nextProps.user.id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) {
      this.handleLoginOk();
      if ( !this.checkTwoFactorAndProperties(this.props.user, false )) {
        message.success(
          "¡" + this.props.t("Welcome") + " " + this.props.user.first_name + "!"
        );
      }
    }

    if (this.props.userAccountCreatedSuccessfully ||
      (prevProps.creatingCompany && !this.props.creatingCompany && this.props.errors.length === 0)) {
      this.handleRegistrationOk();
      this.props.resetUserAccountCreatedSuccessfully();
    }

    if (this.props.shouldPromptLogin) {
      this.showLoginModal();
      this.props.setShouldPromptLogin(false, this.props.redirectTarget);
    }

    if (this.props.user && this.props.redirectTarget) {
      this.props.history.push(this.props.redirectTarget);
      this.props.setShouldPromptLogin(false, null);
    }

    if (prevProps.requestingPassword && !this.props.requestingPassword) {
      if (this.props.errors.length > 0) {
        message.error(this.props.t("Error sending email"));
        // notification.error({
        //   duration: 2,
        //   placement: "bottomRight",
        //   message: "Se produjo un error",
        //   description:
        //     (this.props.errors[0].errors &&
        //       this.props.errors[0].errors.email) ||
        //     this.props.errors[0].message
        // });
      } else {
        message.success(
          this.props.t(
            "Good! An email was sent to your mailbox to retrieve your password."
          )
        );

        this.setState({
          forgotPasswordModalVisible: false,
          loginModalVisible: false,
          registrationModalVisible: false,
        });
      }
    }

    if (prevProps.requestingResendVerification && !this.props.requestingResendVerification) {
      if (this.props.errors.length > 0) {
        message.error(this.props.t("Error sending email"));
      } else {
        message.success(
          this.props.t(
            "Good! An email was sent to your mailbox to activate yout account."
          )
        );
        this.setState({
          resendValidationModalVisible: false,
          loginModalVisible: false,
          registrationModalVisible: false,
        });
      }
    }
  }

  checkRegistrationModal = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const checkRegistrationModal = urlParams.get('registrationModalVisible');
    if (checkRegistrationModal == "true") {
      this.showRegistrationModal()
    }
  }

  handleCurrencyModal = () => {
    this.setState((prevState) => ({
      currencyModalVisible: !prevState.currencyModalVisible,
    }));
  };

  canUseRegionLanguageSelector = () => {
    const currentPath = window.location.pathname;
    const allowedPaths = ["/map", "/", "/login"];
    const isInScreenView = currentPath.split('/')[1] === "screen";
    return allowedPaths.includes(currentPath) || isInScreenView;
  }

  getFooterClassName = (pathname) => {
    return pathname === '/map'
      ? 'sliderContainer footerCardCenter footerCardMap'
      : 'sliderContainer footerCardCenter footerCardCentered';
  };

  render() {
    const { errors, currencyModalVisible, domain } = this.state;
    const { requestingPassword, billingCountry, currency, invoiceIssuingCountry, require2FA, requestingResendVerification, history, editCampaignActive } = this.props;

    const { t, i18n } = this.props;

    const path = history.location.pathname === "/" ? "home" : history.location.pathname;
    const customTopbar = domain === "smartFit" && path === "home" ? "customTopbar" : "";
    const disabledLinkHome = path === "/map" || path === "/videoUpload" || path === "/campaignManager"
    const customMediaOwnerMap = {
      mediaShake: UrlEnum.landingMediaShake + '/ya-tienes-pantallas',
      smartFit: UrlEnum.landingSmartFit,
      duoPrint: UrlEnum.landingDuoprint,
      territorioDigital: UrlEnum.landingTerritorioDigital,
      movImagen: UrlEnum.landingMovImagen,
      yoda: UrlEnum.landingYoda,
      hakMedia: UrlEnum.landingHakMedia
    };

    const domainStyleRegistre = {
      latinad: { height: "50px" },
      hakMedia: { height: "120px" },
      grupoVia: { height: "70px", marginBottom: "30px"},
      yoda: { height: "95px", marginBottom: "20px"},
      movImagen: { height: "70px", marginBottom: "20px"},
      duoPrint: { height: "60px", marginBottom: "25px"},
      smartFit: { height: "95px", marginBottom: "10px"},
      mediaShake: { height: "85px", marginBottom: "20px"}
    };

    const customMediaOwner = customMediaOwnerMap[domain] || "";

    const getContactInfo = (domain, customMediaOwner) => {
      if (domain === "territorioDigital") {
        return `${customMediaOwner}/publicidad-dooh/`;
      }
      if (domain === "yoda") {
        return "mailto:info@yodaooh.com";
      }
      return "https://help.latinad.com/";
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href={((i18n.language == 'es' || i18n.language == 'es_ar') ? UrlEnum.landingEs : "https://en.latinad.com")} style={{ padding: "20px" }}>
            <img src={logoCms} className="logo" alt="" style={{ width: "90px", paddingRight: "10px" }} /> <span style={{ fontSize: "12px" }}>{t("Content Manager System")}</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="https://latinad.com/medios#player" style={{ padding: "20px" }}>
            <img src={logoPlayer} className="logo" alt="" style={{ width: "90px", paddingRight: "10px" }} /> <span style={{ fontSize: "12px" }}>{t("Screen Player")}</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="https://latinad.com/medios/ssp" style={{ padding: "20px" }}>
            <img src={logoSsp} className="logo" alt="" style={{ width: "90px", paddingRight: "10px" }} /> <span style={{ fontSize: "12px" }}>{t("Supply Side Platform")}</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="https://latinad.com/medios/audience" style={{ padding: "20px" }}>
            <img src={logoAudience} className="logo" alt="" style={{ width: "90px", paddingRight: "10px" }} /> <span style={{ fontSize: "12px" }}>{t("Audience measurement")}</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="https://latinad.oohmanager.cl/" style={{ padding: "20px" }}>
            <img src={logoOoh} className="logo" alt="" style={{ width: "90px", paddingRight: "10px" }} /> <span style={{ fontSize: "12px" }}>{t("Static OOH platform")}</span>
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <TopbarHolder>
        <Affix>
          <div className={"headerContainerPadding"}>
            <div className={"headerContainer " + customTopbar}>
              <div className="mobile">
                <Button className="button" onClick={this.showDrawer}>
                  <Icon
                    style={{ fontSize: "18px", color: Colors.primary }}
                    type="menu"
                  />{" "}
                </Button>
                <div>
                  <Link
                    className={`${editCampaignActive || disabledLinkHome ? "disabled-link" : ""}`}
                    to="/">
                    {domain === "latinad" ? (
                      <img src={logoLatinad} className="logo" alt="" />
                    ) : domain === "fenix" ? (
                      <img src={logoFenix} className="logoFenixMobile" alt="" />
                    ) : domain === "grupoVia" ? (
                      <img src={logoGrupovia} className="logoGrupoviaMobile" alt="" />
                    ) : domain === "mediaShake" ? (
                      <img src={logoMediaShake} className="logoMediaShake" alt="" />
                    ) : domain === "smartFit" ? (
                      path == "home" ?
                        <img src={logoSmartFitWhite} className="logoSmartFit" alt="logoSmartFit" /> :
                        <img src={logoSmartFit} className="logoSmartFit" alt="" />
                    ) : domain === "duoPrint" ? (
                      <img src={logoDuoPrint} className="logoMediaShake" alt="" />
                    ) : domain === "territorioDigital" ? (
                      <img src={logoTerritorioDigital} className="logoTerritorioDigital" alt="" />
                    ) : domain === "movImagen" ? (
                      <img src={logoMovImagen} className="logoMovImagen" alt="" />
                    ) : domain === "yoda" ? (
                      <img src={logoYoda} className="logoYoda" alt="" />
                    ) : domain === "hakMedia" ? (
                      <img src={logoHakMedia} className="logoHakMedia" alt="" />
                    ) : (
                      <img src={logo} className="logo" alt="" />
                    )}
                  </Link>
                </div>
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.drawerVisible}
                >
                  <div>
                    <Link to="/">
                      <div className="drawer-link">
                        {domain === "latinad.com" ? (
                          <img src={logoLatinad} style={{ height: "50px" }} alt="" />
                        ) : domain === "fenix.publinet.us" ? (
                          <img src={logoFenix} style={{ height: "75px" }} alt="" />
                        ) : domain === "grupoVia" ? (
                          <img src={logoGrupovia} style={{ height: "50px" }} alt="" />
                        ) : domain === "mediaShake" ? (
                          <img src={logoMediaShake} style={{ width: 'auto', height: '50px' }} alt="" />
                        ) : domain === "smartFit" ? (
                          <img src={logoSmartFit} style={{ width: 'auto', height: '80px' }} />
                        ) : domain === "duoPrint" ? (
                          <img src={logoDuoPrint} style={{ width: 'auto', height: '30px' }} alt="" />
                        ) : domain === "territorioDigital" ? (
                          <img src={logoTerritorioDigital} style={{ width: 'auto', height: '80px' }} alt="" />
                        ) : domain === "movImagen" ? (
                          <img src={logoMovImagen} style={{ width: 'auto', height: '60px' }} alt="" />
                        ) : domain === "yoda" ? (
                          <img src={logoYoda} style={{ width: 'auto', height: '60px' }} alt="" />
                        ) : domain === "hakMedia" ? (
                          <img src={logoHakMedia} style={{ width: 'auto', height: '100px' }} alt="" />
                        ) : (
                          <img src={logo} className="logo" alt="" />
                        )}
                      </div>
                    </Link>
                  </div>
                  {this.props.user ? (
                    <div>
                      <a className="link">
                        <div className="drawer-link">
                          <Link to={"/campaigns"} className="link myCampaign">{t("My campaigns")}</Link>
                        </div>
                      </a>
                      <a className="link">
                        <div className="drawer-link">
                          <Link to={"/quotes"} className="link myCampaign">
                            {t("My quotes")}
                          </Link>
                        </div>
                      </a>
                      <a className="link">
                        <div className="drawer-link">
                          <Link to={"/userSettings"} className="link myCampaign">{t("My profile")}</Link>
                        </div>
                      </a>
                      <a
                        className="link"
                        onClick={this.handleLogout.bind(this)}
                      >
                        <div className="drawer-link">{t("Sign off")}</div>
                      </a>
                      <a
                        className="link"
                        href={getCMSLink(this.props.token)}
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("Go to CMS")} <FontAwesomeIcon icon={faExternalLinkAlt} className="cmsLinkIcon" />
                      </a>
                      <Divider />
                      {!window.location.pathname.includes("/payment") &&
                        !this.props.editCampaignActive &&
                        !window.location.pathname.includes("/campaign/") ? (
                        <RegionLanguageComponent
                          disabledButton={!this.canUseRegionLanguageSelector()}
                          invoiceIssuingCountry={invoiceIssuingCountry}
                          currency={currency}
                          billingCountry={billingCountry}
                          showModal={this.handleCurrencyModal}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      {this.state.domain != "territorioDigital" &&
                        <a className="link" onClick={this.showSelectRegistrationTypeModal}>
                          <div className="drawer-link">{t("Sign up")}</div>
                        </a>
                      }
                      <a className="link" onClick={this.showLoginModal}>
                        <div className="drawer-link">{t("Log in")}</div>
                      </a>
                      <Divider />
                      {!window.location.pathname.includes("/payment") &&
                        !this.props.editCampaignActive &&
                        !window.location.pathname.includes("/campaign/") ? (
                        <RegionLanguageComponent
                          disabledButton={!this.canUseRegionLanguageSelector()}
                          invoiceIssuingCountry={invoiceIssuingCountry}
                          currency={currency}
                          billingCountry={billingCountry}
                          showModal={this.handleCurrencyModal}
                        />
                      ) : null}
                    </div>
                  )}
                  { this.state.showInformationButton && (
                    <div style={{ marginTop: "20px" }}>
                      {customMediaOwner != "" ?
                        <Button
                          size="default"
                          className="btn-secondary"
                          type="secondary"
                          href={customMediaOwner}
                          target="_blank"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Media owners")}
                        </Button> :
                        <Dropdown overlay={menu}>
                          <Button
                            size="default"
                            className="btn-secondary"
                            type="secondary"
                            onClick={e => e.preventDefault()}
                            style={{ marginBottom: "10px" }}
                          >
                            {t("Media owners")}
                          </Button>
                        </Dropdown>
                      }
                      {domain != "duoPrint" ?
                        <Button
                          size="default"
                          className="btn-secondary"
                          type="secondary"
                          target="_blank"
                          href={getContactInfo(domain, customMediaOwner)}
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Help")}
                        </Button>
                      : null}
                    </div>
                  )}
                  { domain === "hakMedia" &&
                      <div className="left" >
                        <Col lg={24} md={12} style={{ marginTop: "5px" }}>
                            <Button
                              size="default"
                              className="btn-secondary"
                              target="_blank"
                              type="secondary"
                              href={ UrlEnum.helpHakMedia }
                              style={{ marginBottom: "10px" }}
                            >
                              {t("Contact us")}
                            </Button>
                        </Col>
                      </div>
                    }
                </Drawer>
              </div>
              <Link
                className={`left content-logo ${editCampaignActive || disabledLinkHome ? "disabled-link" : ""}`}
                to="/">
                {domain === "latinad.com" ? (
                  <img src={logoLatinad} className="logo" alt="" />
                ) : domain === "fenix.publinet.us" ? (
                  <img src={logoFenix} className="logoFenix" alt="" />
                ) : domain === "grupoVia" ? (
                  <img src={logoGrupovia} className="logoGrupovia" alt="" />
                ) : domain === "mediaShake" ? (
                  <img src={logoMediaShake} className="logoMediaShake" alt="" />
                ) : domain === "smartFit" ? (
                  path == "home" ?
                    <img src={logoSmartFitWhite} className="logoSmartFit" alt="" /> :
                    <img src={logoSmartFit} className="logoSmartFit" alt="" />
                ) : domain === "duoPrint" ? (
                  <img src={logoDuoPrint} className="logoMediaShake" alt="" />
                ) : domain === "territorioDigital" ? (
                  <img src={logoTerritorioDigital} className="logoTerritorioDigital" alt="" />
                ) : domain === "movImagen" ? (
                  <img src={logoMovImagen} className="logoMovImagen" alt="" />
                ) : domain === "yoda" ? (
                  <img src={logoYoda} className="logoYoda" alt="" />
                ) : domain === "hakMedia" ? (
                  <img src={logoHakMedia} className="logoHakMedia" alt="" />
                ) : (
                  <img src={logo} className="logo" alt="" />
                )}
              </Link>
              <Row className="maxWidth">
                <Col span={6}>
                  { this.state.showInformationButton && (
                    <div className="left" >
                      <Col lg={24} md={12} style={{ marginTop: "5px" }}>
                        {customMediaOwner != "" ?
                          <Button
                            size="default"
                            className="btn-secondary"
                            type="secondary"
                            href={customMediaOwner}
                            target="_blank"
                            style={{ marginBottom: "10px" }}
                          >
                            {t("Media owners")}
                          </Button> :
                          <Dropdown overlay={menu}>
                            <Button
                              size="default"
                              className="btn-secondary"
                              type="secondary"
                              onClick={e => e.preventDefault()}
                              style={{ marginBottom: "10px" }}
                            >
                              {t("Media owners")}
                            </Button>
                          </Dropdown>
                        }
                        {domain != "duoPrint" ?
                          <Button
                            size="default"
                            className="btn-secondary"
                            target="_blank"
                            type="secondary"
                            href={getContactInfo(domain, customMediaOwner)}
                            style={{ marginBottom: "10px" }}
                          >
                            {t("Help")}
                          </Button> : null}
                      </Col>
                    </div>
                  )}
                  { domain === "hakMedia" &&
                    <div className="left" >
                      <Col lg={24} md={12} style={{ marginTop: "5px" }}>
                          <Button
                            size="default"
                            className="btn-secondary"
                            target="_blank"
                            type="secondary"
                            href={ UrlEnum.helpHakMedia }
                            style={{ marginBottom: "10px" }}
                          >
                            {t("Contact us")}
                          </Button>
                      </Col>
                    </div>
                  }
                </Col>

                <Col span={18}>
                  {this.props.user ? (
                    <div className="right">
                      {this.props.user.roles.map((role, index) => {
                        if (
                          role === UserRoleEnum.agencyMember ||
                          role === UserRoleEnum.companyMember
                        ) {
                          return (
                            <div key={index} className="agencyMember">
                              {t("Agency")}: {this.props.user.company.name}
                              {this.props.user.company.is_verified ? <VerifiedCheck /> : <></>}
                            </div>
                          );
                        }
                      })}
                      {this.props.user.company && (
                        <div className="cmsLink">
                          <a href={getCMSLink(this.props.token)}
                            target="_blank"
                            rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faExternalLinkAlt} className="cmsLinkIcon" />
                            {t("Go to CMS")}
                          </a>
                        </div>
                      )
                      }
                      <LanguageCurrencyModalContainer
                        visible={currencyModalVisible}
                        onCancel={this.handleCurrencyModal}
                        onOk={this.handleCurrencyModal}
                        user={this.props.user}
                      />
                      {!window.location.pathname.includes("/payment") &&
                        !this.props.editCampaignActive &&
                        !window.location.pathname.includes("/campaign/") ? (
                        <RegionLanguageComponent
                          disabledButton={!this.canUseRegionLanguageSelector()}
                          invoiceIssuingCountry={invoiceIssuingCountry}
                          currency={currency}
                          billingCountry={billingCountry == 'Factura Internacional' ? t('International billing') : billingCountry}
                          showModal={this.handleCurrencyModal}
                        />
                      ) : null}
                      <Popover
                        placement="bottomRight"
                        title={
                          this.props.user.first_name +
                          " " +
                          this.props.user.last_name
                        }
                        content={
                          <div>
                            <Link
                              to={"/userSettings"}
                              className="link myProfile"
                            >
                              {t("My profile")}
                            </Link>
                            <br />
                            <br />
                            {this.props.user.roles.map((role, index) => {
                              if (
                                role === UserRoleEnum.agencyMember ||
                                role === UserRoleEnum.companyMember
                              ) {
                                return (
                                  <>
                                    <Link
                                      key={role}
                                      to={"/quotes"}
                                      className="link myCampaign"
                                    >
                                      {t("My quotes")}
                                    </Link>
                                    <br />
                                    <br />
                                  </>
                                );
                              }
                            })}
                            <Link to={"/campaigns"} className="link myCampaign">
                              {t("My campaigns")}
                            </Link>
                            <br />
                            <br />
                            <a
                              className="link"
                              onClick={this.handleLogout.bind(this)}
                            >
                              {t("Sign off")}
                            </a>
                          </div>
                        }
                        trigger="click"
                      >
                        {this.props.user.avatar ? (
                          <img
                            src={this.props.user.avatar}
                            className="avatar-user"
                            alt=""
                          />
                        ) : (
                          <Avatar
                            icon="user"
                            style={{
                              backgroundColor: Colors.primary,
                              verticalAlign: "middle",
                            }}
                          ></Avatar>
                        )}
                      </Popover>
                    </div>
                  ) : (
                    <Spin style={{ width: '145%' }} spinning={this.props.fetching}>
                      <div className="right">
                        <LanguageCurrencyModalContainer
                          visible={currencyModalVisible}
                          onCancel={this.handleCurrencyModal}
                          onOk={this.handleCurrencyModal}
                          user={this.props.user}
                          t={t}
                        />
                        <RegionLanguageComponent
                          disabledButton={!this.canUseRegionLanguageSelector()}
                          invoiceIssuingCountry={invoiceIssuingCountry}
                          currency={currency}
                          billingCountry={billingCountry}
                          showModal={this.handleCurrencyModal}
                        />
                        {this.state.domain != "territorioDigital" &&
                          <a
                            className="link"
                            style={{ alignItems: "center", display: "flex" }}
                            onClick={this.showSelectRegistrationTypeModal}
                          >
                            {t("Sign up")}
                          </a>
                        }
                        <a
                          className="link"
                          style={{ alignItems: "center", display: "flex" }}
                          onClick={this.showLoginModal}
                        >
                          {t("Log in")}
                        </a>
                      </div>
                    </Spin>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Affix>
        <Modal
          title={t("Consider the following")}
          onCancel={() => this.setState({ exitEditModal: false })}
          visible={this.state.exitEditModal}
          onOk={() => this.exitEdit()}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ exitEditModal: false })}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.loadingEditCampaign}
              onClick={() => this.exitEdit()}
            >
              {t("Exit edit")}
            </Button>,
          ]}
        >
          <p style={{ fontSize: "15px", padding: "30px", color: Colors.primary }}>
            {t("If you exit out of edit mode, the cart and contents empty")}
          </p>
        </Modal>
        {/* Modal select registration type */}
        <Modal
          visible={this.state.selectRegistrationTypeModalVisible}
          onCancel={() => {
            this.handleRegistrationCancel();
            this.handleRegistrationAgencyCancel();
            this.setState({ selectRegistrationTypeModalVisible: false });
          }}
          footer={null}
          className="registration-type-modal"
        >
          <div className="modalContent" style={{ width: "40rem !important" }}>
            <div className="logo-container">
              <img
                src={this.state.logo}
                alt="Latinad"
                style={ domainStyleRegistre[domain]} />
            </div>
            { domain === "latinad" &&
              <h1
              className="modal-title"
              style={{ color: "#3996f3", fontWeight: "bolder", margin: "20px 0 20px 0" }}>
              {t("Welcome to LatinAd")}
              </h1>
            }
            { domain != "latinad" &&
              <h1
                className="modal-title modal-title-brande"
                style={{fontWeight: "bolder"}}>
                {t("Welcome")}
              </h1>
            }
            <div className="registration-options" style={{
              width: "100%",
              display: "flex",
              flexDirection: this.state.windowWidth <= 768 ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <div className="option-container" style={{
                flex: 2,
                textAlign: "center",
                width: "100%",
                marginBottom: this.state.windowWidth <= 768 ? "30px" : "0"
              }}>
                <h3 style={{ fontWeight: "600", fontSize: "1.1rem" }}>{t("Are you an agency?")}</h3>
                <Button
                  onClick={() => {
                    this.setState({ selectRegistrationTypeModalVisible: false });
                    this.showRegistrationModal();
                    this.setState({ setModalType: "agency" });
                  }}
                  type="primary"
                  shape="round"
                  style={{ fontSize: "1.1rem", width: "12rem", height: "auto" }}
                >
                  {t("Register here")}
                </Button>
              </div>
              
              {this.state.windowWidth > 768 && (
                <div style={{
                  width: "2px",
                  height: "120px",
                  backgroundColor: "black",
                  opacity: "0.2",
                  margin: "0 20px"
                }} />
              )}
              
              <div className="option-container" style={{
                flex: 2,
                textAlign: "center",
                width: this.state.windowWidth <= 768 ? "100%" : "auto"
              }}>
                <h3 style={{ fontWeight: "600", fontSize: "1.1rem" }}>{t("Are you an advertiser?")}</h3>
                <Button
                  onClick={() => {
                    this.setState({ selectRegistrationTypeModalVisible: false });
                    this.showRegistrationModal();
                    this.setState({ setModalType: "advertiser" });
                  }}
                  type="primary"
                  shape="round"
                  style={{ fontSize: "1.1rem", width: "12rem", height: "auto" }}
                >
                  {t("Register here")}
                </Button>
              </div>
            </div>

            <div className="login-link" style={{
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <span style={{ fontSize: "1rem" }}>{t("Are you a media owner?")} <a className="link" target="_blank" href={'https://gestor.dev.latinad.com/#/create-account'}>
                {t("Register here")}
              </a></span>{" "}
              <span className="login-link-text" style={{ fontSize: "1rem" }}>{t("Do you already have your account?")} <a onClick={this.showLoginModal}>{t("Log in")}</a></span>{" "}
            </div>
          </div>
        </Modal>
        {/* modal user register */}
        <Modal
          visible={this.state.registrationModalVisible}
          onOk={this.handleRegistrationOk}
          onCancel={this.handleRegistrationCancel}
          footer={null}
        >
          {!this.state.successfullRegistration ?
            <>
              <div className="modalHeader">
                <div className="modal-title">
                  {this.state.setModalType == "advertiser" ? 
                    <h2>{t("Sign up as an advertiser")}</h2> : 
                    <h2>{t("Sign up as an agency")}</h2>
                  }
                </div>
              </div>
              <div className="modalContent">
                {this.state.setModalType == "advertiser" ? (
                  <Form.Item
                    validateStatus={!!errors["firstName"] ? "error" : ""}
                    help={errors["firstName"]}
                  >
                    <Input
                      autoFocus
                      name="firstName"
                      className="modalInput"
                      placeholder={t("First Name")}
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                      prefix={<Icon type="user" className="inputIcon" />}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    validateStatus={!!errors["companyName"] ? "error" : ""}
                    help={errors["companyName"]}
                  >
                    <Input
                      autoFocus
                      name="companyName"
                      className="modalInput"
                      placeholder={t("Company name")}
                      value={this.state.companyName}
                      onChange={this.handleInputChange}
                      prefix={<Icon type="bank" className="inputIcon" />}
                    />
                  </Form.Item>
                )}
                {this.state.setModalType == "advertiser" ? (
                  <Form.Item
                    validateStatus={!!errors["lastName"] ? "error" : ""}
                    help={errors["lastName"]}
                  >
                    <Input
                      name="lastName"
                      className="modalInput"
                      placeholder={t("Last name")}
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                      prefix={<Icon type="user" className="inputIcon" />}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    validateStatus={!!errors["country"] ? "error" : ""}
                    help={errors["country"]}
                  >
                    <AutoComplete
                      className="modalInput-autocomplete"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ width: 300 }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={t("Country")}
                      value={this.state.country}
                      onChange={(value) => this.setState({ country: value, state: '' })}
                      dataSource={countriesList.map(country => country.name)}
                      filterOption={(inputValue, option) => {
                        return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                      }}
                    >
                      <Input
                        prefix={<Icon type="global" className="inputIcon" />}
                        suffix={<Icon type="search" />}
                      />
                    </AutoComplete>
                  </Form.Item>
                )}
                {this.state.setModalType == "agency" && (
                  <Form.Item
                    validateStatus={!!errors["state"] ? "error" : ""}
                    help={errors["state"]}
                  >
                    <AutoComplete
                      className="modalInput-autocomplete"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ width: 300 }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={t("State (optional)")}
                      value={this.state.state}
                      onChange={(value) => this.setState({ state: value })}
                      dataSource={
                        (countriesList.find(country => country.name === this.state.country) || { states: [] }).states
                      }
                      filterOption={(inputValue, option) => {
                        return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                      }}
                      disabled={!this.state.country}
                    >
                      <Input
                        prefix={<Icon type="compass" className="inputIcon" />}
                        suffix={<Icon type="search" />}
                      />
                    </AutoComplete>
                  </Form.Item>
                )}
                <Form.Item
                  validateStatus={!!errors["phone"] ? "error" : ""}
                  help={errors["phone"]}
                >
                  <Input
                    name="phone"
                    className="modalInput"
                    placeholder={t("Phone")}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    autoComplete="new-phone"
                    prefix={<Icon type="phone" className="inputIcon" />}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["email"] ? "error" : ""}
                  help={errors["email"]}

                >
                  <Input
                    name="email"
                    className="modalInput"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    type="email"
                    autoComplete="new-username"
                    prefix={<Icon type="mail" className="inputIcon" />}
                  />
                </Form.Item>
                {this.state.setModalType == "agency" && (
                  <Form.Item
                    validateStatus={!!errors["firstName"] ? "error" : ""}
                    help={errors["firstName"]}
                  >
                    <Input
                      autoFocus
                      name="firstName"
                      className="modalInput"
                      placeholder={t("First Name")}
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                      prefix={<Icon type="user" className="inputIcon" />}
                    />
                  </Form.Item>
                )}
                {this.state.setModalType == "agency" && (
                  <Form.Item
                    validateStatus={!!errors["lastName"] ? "error" : ""}
                    help={errors["lastName"]}
                  >
                    <Input
                      name="lastName"
                      className="modalInput"
                      placeholder={t("Last name")}
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                      prefix={<Icon type="user" className="inputIcon" />}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  validateStatus={!!errors["password"] ? "error" : ""}
                  help={errors["password"]}
                >
                  <Input
                    name="password"
                    className="modalInput"
                    placeholder={t("Password")}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    type="password"
                    autoComplete="new-password"
                    prefix={<Icon type="lock" className="inputIcon" />}
                  />
                </Form.Item>
                {this.props.errors && this.props.errors.length > 0 && (
                  <p className="errors">
                    {getValidationError(this.props.errors, this.props.t)}
                  </p>
                )}
                {this.state.domain != "territorioDigital" &&
                  <Button
                    className="modalButton"
                    onClick={() => (this.state.setModalType === "agency" ? this.handleRegisterCompany() : this.handleRegister())}
                    loading={this.props.fetching}
                  >
                    {t("Sign up")}
                  </Button>
                }
              </div>
            </> :
            <div className="modalContent">
              <Result
                status="success"
                title={this.props.t(
                  this.state.setModalType === 'agency'
                    ? 'Good! Just one step left, activate your account.'
                    : 'Good! You just need to verify your email'
                )}
                subTitle={this.props.t(
                  this.state.setModalType === 'agency'
                    ? 'In order to use your account you have to activate it. Check your email address to finish the registration process.'
                    : 'To begin you must verify your email. Check your email box to complete the registration process. Remember to check your spam folder.'
                )}
              />
            </div>
          }
        </Modal>
        <Modal
          visible={this.state.loginModalVisible}
          onOk={this.handleLoginOk}
          onCancel={window.location.pathname != "/map" ? this.handleLoginCancel : null}
          footer={null}
        >
          <div className="modalHeader">
            <h2 className="modal-title">{t("Log in")}</h2>
          </div>
          <div className="modalContent">
            <Form.Item
              validateStatus={!!errors["loginEmail"] ? "error" : ""}
              help={errors["loginEmail"]}
            >
              <Input
                autoFocus
                name="loginEmail"
                autoComplete="username"
                className="modalInput"
                placeholder="Email"
                value={this.state.loginEmail}
                onChange={this.handleInputChange}
                type="email"
                prefix={<Icon type="user" className="inputIcon" />}
                onPressEnter={() => this.handleLogin()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["loginPassword"] ? "error" : ""}
              help={errors["loginPassword"]}
            >
              <Input.Password
                className="modalInput"
                name="loginPassword"
                autoComplete="current-password"
                placeholder={t("Password")}
                value={this.state.loginPassword}
                onChange={this.handleInputChange}
                type="password"
                prefix={<Icon type="lock" className="inputIcon" />}
                onPressEnter={() => this.handleLogin()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["code2FA"] ? "error" : ""}
              help={errors["code2FA"]}
              hidden={!require2FA}
            >
              <Input
                className="modalInput"
                name="code2FA"
                placeholder={t("Code2FA")}
                value={this.state.code2FA}
                onChange={this.handleInputChange}
                prefix={<Icon type="qrcode" className="inputIcon" />}
                onPressEnter={() => this.handleLogin()}
                autoComplete="off"
              />
            </Form.Item>
            {this.props.errors && this.props.errors.length > 0 && (
              <p className="errors">
                {getValidationError(this.props.errors, this.props.t)}
              </p>
            )}
            {this.props.errors && this.props.errors.length > 0 && this.props.errors[0].message.includes("User's account is not verified") &&
              <>
                <a onClick={this.showResendValidationModal}>
                  {t("Resend verification email")}
                </a>
                <br></br>
              </>
            }
            { this.state.alert2Fa ? (
              <Alert
                style={{marginBottom: "15px", backgroundColor: "#fa8c1617"}}
                description={
                  <>
                    {t("To log in, you must enable two-factor authentication (2FA) by accessing the CMS.")}{" "}
                    <a href={getUrl(window.location)} target="_blank" rel="noopener noreferrer">
                      <Icon type="link" />
                    </a>
                  </>}
                type="error"
              />
            ) : null}
            <a onClick={this.showForgotPasswordModal}>
              {t("I forgot my password")}
            </a>
            {this.state.domain != "territorioDigital" &&
              <a onClick={() => this.setState({
                selectRegistrationTypeModalVisible: true,
                loginModalVisible: false,
                registrationModalVisible: false,
              })}>
                <span>{t("Dont have an account?")}</span> {t("Sign up")}
              </a>
            }
            <Button
              className="modalButton"
              onClick={() => this.handleLogin()}
              loading={this.props.fetching}
            >
              {t("Login")}
            </Button>
          </div>
        </Modal>
        <Modal
          visible={this.state.registrationAgencyModalVisible}
          onOk={this.handleRegistrationOk}
          onCancel={this.handleRegistrationAgencyCancel}
          footer={null}
        >
          {!this.state.successfullRegistration ? (
            <>
              <div className="modalHeader">
                <h2>{t("Sign up as an agency")}</h2>
              </div>
              <div className="modalContent">
                <Form.Item
                  validateStatus={!!errors["firstName"] ? "error" : ""}
                  help={errors["firstName"]}
                >
                  <Input
                    autoFocus
                    name="companyName"
                    className="modalInput"
                    placeholder={t("Company name")}
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    prefix={<Icon type="company" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["lastName"] ? "error" : ""}
                  help={errors["lastName"]}
                >
                  <Input
                    name="country"
                    className="modalInput"
                    placeholder={t("Country")}
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    prefix={<Icon type="user" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["phone"] ? "error" : ""}
                  help={errors["phone"]}
                >
                  <Input
                    name="state"
                    className="modalInput"
                    placeholder={t("State")}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    autoComplete="new-phone"
                    prefix={<Icon type="phone" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["email"] ? "error" : ""}
                  help={errors["email"]}
                >
                  <Input
                    name="userEmail"
                    className="modalInput"
                    placeholder={t("User Email")}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    type="email"
                    autoComplete="new-username"
                    prefix={<Icon type="mail" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["email"] ? "error" : ""}
                  help={errors["email"]}
                >
                  <Input
                    name="firstAndLastName"
                    className="modalInput"
                    placeholder={t("First and last name")}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    type="email"
                    autoComplete="new-username"
                    prefix={<Icon type="mail" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={!!errors["email"] ? "error" : ""}
                  help={errors["email"]}
                >
                  <Input
                    name="phoneNumber"
                    className="modalInput"
                    placeholder={t("Phone number")}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    autoComplete="new-phone"
                    prefix={<Icon type="phone" className="inputIcon" />}
                    onPressEnter={() => this.handleRegister()}
                  />
                </Form.Item>
                {this.props.errors && this.props.errors.length > 0 && (
                  <p className="errors">
                    {getValidationError(this.props.errors, this.props.t)}
                  </p>
                )}

                <a onClick={this.showLoginModal}>
                  <span>{t("Do you already have your account?")}</span>{" "}
                  {t("Log in")}
                </a>
                <a className="link" target="_blank" href={((i18n.language == 'es' || i18n.language == 'es_ar') ? UrlEnum.landingEs : "https://en.latinad.com") + "#register-form-title"}>
                  <span>{t("Are you a media owner?")}</span>{" "}
                  {t("Register here")}
                </a>
                {this.state.domain != "territorioDigital" &&
                  <Button
                    className="modalButton"
                    onClick={() => this.handleRegister()}
                    loading={this.props.fetching}
                  >
                    {t("Sign up")}
                  </Button>
                }
              </div>
            </>
          ) : (
            <div className="modalContent">
              <Result
                status="success"
                title={this.props.t("Good! Just one step left, activate your account.")}
                subTitle={this.props.t("In order to use your account you have to activate it. Check your email address to finish the registration process.")}
              />
            </div>
          )}
        </Modal>
        <Modal
          visible={this.state.providerRegistrationModalVisible}
          onOk={this.handleProviderRegistrationOk}
          onCancel={this.handleProviderRegistrationCancel}
          footer={null}
        >
          <div className="modalHeader">
            <h2>{t("I offered your screens")}</h2>
          </div>
          <div className="modalContent">
            <Form.Item
              validateStatus={!!errors["companyRegistration"] ? "error" : ""}
              help={errors["companyRegistration"]}
            >
              <Input
                autoFocus
                name="companyRegistration"
                value={this.state.companyRegistration}
                onChange={this.handleInputChange}
                className="modalInput"
                placeholder={t("Company")}
                prefix={<Icon type="shop" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["firstNameRegistration"] ? "error" : ""}
              help={errors["firstNameRegistration"]}
            >
              <Input
                className="modalInput"
                name="firstNameRegistration"
                value={this.state.firstNameRegistration}
                onChange={this.handleInputChange}
                placeholder={t("First Name")}
                prefix={<Icon type="user" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["lastNameRegistration"] ? "error" : ""}
              help={errors["lastNameRegistration"]}
            >
              <Input
                className="modalInput"
                name="lastNameRegistration"
                value={this.state.lastNameRegistration}
                onChange={this.handleInputChange}
                placeholder={t("Last name")}
                prefix={<Icon type="user" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["emailRegistration"] ? "error" : ""}
              help={errors["emailRegistration"]}
            >
              <Input
                className="modalInput"
                name="emailRegistration"
                value={this.state.emailRegistration}
                onChange={this.handleInputChange}
                placeholder="Email"
                prefix={<Icon type="mail" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["phoneRegistration"] ? "error" : ""}
              help={errors["phoneRegistration"]}
            >
              <Input
                className="modalInput"
                name="phoneRegistration"
                value={this.state.phoneRegistration}
                onChange={this.handleInputChange}
                placeholder={t("Phone")}
                prefix={<Icon type="phone" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            <Form.Item
              validateStatus={!!errors["passwordRegistration"] ? "error" : ""}
              help={errors["passwordRegistration"]}
            >
              <Input.Password
                className="modalInput"
                name="passwordRegistration"
                value={this.state.passwordRegistration}
                onChange={this.handleInputChange}
                placeholder={t("Password")}
                type="password"
                prefix={<Icon type="lock" className="inputIcon" />}
                onPressEnter={() => this.handleRegisterCompany()}
              />
            </Form.Item>
            {this.props.errors && this.props.errors.length > 0 && (
              <p className="errors">
                {getValidationError(this.props.errors, this.props.t)}
              </p>
            )}

            <a href="https://gestor.latinad.com/#/terms" target="_blank">
              {t("Terms")}
            </a>
            <Button
              className="modalButtonCompany"
              onClick={() => this.handleRegisterCompany()}
              loading={this.props.creatingCompany}
            >
              {t("Create company account")}
            </Button>
          </div>
        </Modal>

        <Modal
          visible={this.state.forgotPasswordModalVisible}
          onOk={this.handleForgotPasswordOk}
          onCancel={this.handleForgotPasswordCancel}
          footer={null}
        >
          <div className="modalHeader">
            <h2>{t("Recover your password")}</h2>
          </div>
          <div className="modalContent">
            <p>
              {t(
                "Enter your email and we will send you the instructions to reset your password."
              )}
            </p>

            <Form.Item
              validateStatus={!!errors["forgottenEmail"] ? "error" : ""}
              help={errors["forgottenEmail"]}
            >
              <Input
                className="modalInput"
                placeholder="Email"
                name="forgottenEmail"
                value={this.state.forgottenEmail}
                onChange={this.handleInputChange}
                prefix={<Icon type="mail" className="inputIcon" />}
                onPressEnter={this.handleForgotPasswordOk}
              />
            </Form.Item>
            {this.props.errors && this.props.errors.length > 0 && (
              <p className="errors">
                {getValidationError(this.props.errors, this.props.t)}
              </p>
            )}

            <Button
              loading={requestingPassword}
              className="modalButtonForgoten"
              onClick={this.handleForgotPasswordOk}
            >
              {t("Send instructions")}
            </Button>
          </div>
        </Modal>

        <Modal
          visible={this.state.resendValidationModalVisible}
          onOk={this.handleResendValidationOk}
          onCancel={this.handleResendValidationCancel}
          footer={null}
        >
          <div className="modalHeader">
            <h2>{t("Resend verification email")}</h2>
          </div>
          <div className="modalContent">
            <p>
              {t(
                "Enter your email and we will resend you the verification instructions."
              )}
            </p>

            <Form.Item
              validateStatus={!!errors["resendVerificationEmail"] ? "error" : ""}
              help={errors["resendVerificationEmail"]}
            >
              <Input
                className="modalInput"
                placeholder="Email"
                name="resendVerificationEmail"
                value={this.state.resendVerificationEmail}
                onChange={this.handleInputChange}
                prefix={<Icon type="mail" className="inputIcon" />}
                onPressEnter={this.handleResendValidationOk}
              />
            </Form.Item>
            {this.props.errors && this.props.errors.length > 0 && (
              <p className="errors">
                {getValidationError(this.props.errors, this.props.t)}
              </p>
            )}

            <Button
              loading={requestingResendVerification}
              className="modalButtonForgoten"
              onClick={this.handleResendValidationOk}
            >
              {t("Resend email")}
            </Button>
          </div>
        </Modal>
        {
          this.props.editCampaignActive && this.props.editCampaign ? (
            <Row
              type="flex"
              no-gutters
              className={this.getFooterClassName(window.location.pathname)}
            >
              {this.settingFooterPading()}
              <Col
                xs={24}
                sm={24}
                md={16}
                lg={16}
                xl={17}
                className="footerCardColRight"
              >
                <Row type="flex" align="middle" className="footerContentRow">
                  <Col
                    xs={24}
                    sm={24}
                    md={2}
                    lg={2}
                    xl={1}
                    className="footerCardColRight footerIcon"
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      size="lg"
                      className="iconEdit"
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={21}
                    lg={22}
                    xl={22}
                    className="footerCardColLeft footerText"
                  >
                    <span>
                      <Dotdotdot clamp={1}>
                        {t("Editing the campaign")}&nbsp;
                        {this.props.editCampaign.name}
                      </Dotdotdot>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={5}
                className="footerCardColLeft footerButtons"
              >
                <Button
                  className="buttonFooter"
                  size="default"
                  type="text"
                  onClick={() => this.exitEditModal()}
                >
                  {t("Exit edit")}
                </Button>
                {window.location.pathname != "/videoUpload" && !this.props.newVersionCampaign ? (
                  <Button
                    className="buttonFooter"
                    size="default"
                    type="text"
                    onClick={() => this.props.history.push("/videoUpload")}
                  >
                    {t("Edit")}
                  </Button>
                ) : null}
              </Col>
            </Row>
          ) : null
        }
        {
          path != "/campaignManager" ? (
            domain == "latinad" ? (
              <a
                target="_blank"
                href={t("Url whatsapp, Hello, I would like to have advice latinad")}
              >
                <Button type="primary whatsappButton" shape="circle" size={"large"}>
                  <img src={logoWhatsApp} className="logoWhatsApp" alt="" />
                </Button>
              </a>
            ) : window.location.hostname === "fenix.publinet.us" ||
              window.location.hostname
                .split(".")
                .splice(1)
                .join(".") === "fenix.publinet.us" ? (
              <a
                target="_blank"
                href={t("Url whatsapp, Hello, I would like to have advice fenix")}
              >
                <Button type="primary whatsappButton" shape="circle" size={"large"}>
                  <img src={logoWhatsApp} className="logoWhatsApp" alt="" />
                </Button>
              </a>
            ) : domain == "mediaShake" ? (
              <a
                target="_blank"
                href={t("Url whatsapp, Hello, I would like to have advice mediashake")}
              >
                <Button type="primary whatsappButton" shape="circle" size={"large"}>
                  <img src={logoWhatsApp} className="logoWhatsApp" alt="" />
                </Button>
              </a>
            ) : domain == 'duoPrint' ? (
              <a
                target="_blank"
                href={t("Url whatsapp, Hello, I would like to have advice duoprint")}
              >
                <Button type="primary whatsappButton" shape="circle" size={"large"}>
                  <img src={logoWhatsApp} className="logoWhatsApp" alt="" />
                </Button>
              </a>
            ) : domain == 'hakMedia' ? (
              <a
                target="_blank"
                href={t("Url whatsapp, Hello, I would like to have advice hak media")}
              >
                <Button type="primary whatsappButton" shape="circle" size={"large"}>
                  <img src={logoWhatsApp} className="logoWhatsApp" alt="" />
                </Button>
              </a>
            ) : null
          ) : null
        }
      </TopbarHolder >
    );
  }
}

const Extended = withTranslation()(TopbarComponent);
Extended.static = TopbarComponent.static;

export default withRouter(Form.create({ name: "topbarComponent" })(Extended));

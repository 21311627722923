import styled from "styled-components";
import Colors from "../../themes/Colors";


const TopbarHolder = styled.div`
  ${"" /* height: 120px; */}
  height: 0px;
  position:relative;
  z-index: 101;
  background-color: ${Colors.background};

  .ant-affix {
    z-index: 995;
  }
  .headerContainerPadding {
    padding: 10px 10px 0px 10px;
    backdrop-filter: blur(10px);
  }

  .disabled-link {
    pointer-events: none;
    cursor: default;
  }

  .customTopbar {
    background-color: #000000 !important;
  }

  .headerContainer {
    height: 110px;
    background-color: ${Colors.snow};
    padding: 10px, 20px, 10px, 20px;
    border-radius: 16px;
    box-shadow: 0px 1px 4px rgba(39, 140, 242, 0.25);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px;
    @media only screen and (min-width: 768px) {
      padding: 0px 20px;
    }
  }

  .maxWidth {
    width: 100%;
    
    @media (max-width: 768px) {
      .ant-col {
        margin-bottom: 0;
        padding: 5px 0;
      }
    }
  }

  .agencyMember {
    color: #9e9e9e;
    height: 35px;
    padding: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 768px) {
      padding: 15px 20px;
    }

  }

  .cmsLink {
    a {
      color: #9e9e9e;
    }

    height: 35px;
    padding: 15px 15px 15px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cmsLinkIcon {
    padding-right: 5px;
  }

  .mobile {
    display: flex;
    padding-left: 20px;

    @media only screen and (min-width: 768px) {
      display: none;
      margin-left: 2%;
    }
  }

  .btn-secondary {
    border-radius: 8px;
    color: ${Colors.primary};
    margin-left: 1%;
    margin-right: 1%;
  }
  .btn-secondary:hover{
    border-color: ${Colors.primary};
  }

  .btn-primary {
    border-radius: 8px;
    margin-left: 1%;
    margin-right: 1%;
  }

  .ant-btn.button {
    border: none;
    padding: 0px;
    margin-right: 10px;
  }
  .ant-btn:hover, .ant-btn:focus {
    color: ${Colors.primary}
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: nowrap;
    
    @media (max-width: 767px) {
      display: none;
    }
  }

  .left {
    justify-content: flex-start;
    display: none;

    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }
  .content-logo {
    margin-left: 15px;
    margin-right: 10px;
  }
  .territorioDigitalText {
   color: #000000d9; !important;
  }
  .logo {
    height: 35px;
    margin: 0 15px 0 0;
  }

  .logoFenix {
    height: 75px;
    top: 1px;
    margin: 0 10px 7px 0;
  }

  .logoFenixMobile {
    position: absolute;
    height: 75px;
    top: 1px;
    margin: 0 10px 7px 0;
  }

  .logoGrupovia {
    height: 45px;
  }

  .logoGrupoviaMobile {
    height: 50px;
    top: 1px;
    margin: -10px 0 0 0;
  }

  .logoMediaShake {
    height: 45px;
  }
  .logoTerritorioDigital {
    height: 80px;
  }
  .logoMovImagen{
    height: 40px;
  }
  .logoYoda {
    height: 80px;
  }
  .logoHakMedia {
    height: 100px;
  }
  .logoSmartFit {
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .avatar-user {
    height: 40px;
    border-radius: 40px;
  }

  .link {
    margin: 0 10px;
  }

  .myCampaign {
    margin-right: 15px;
  }

  .myProfile {
    margin: 0 10px;
  }

  .whatsappButton {
    position: fixed;
    bottom: 4%;
    right: 2%;
    width: 60px;
    height: 60px;
    background-color: #00E576;
    border-color: #00E576;
    box-shadow: 0px 1px 4px rgba(39, 140, 242, 0.25);
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .whatsappButton {
      position: fixed;
      bottom: 4%;
      right: 2%;
      width: 60px;
      height: 60px;
      background-color: #00E576;
      border-color: #00E576;
      box-shadow: 0px 1px 4px rgba(39, 140, 242, 0.25);
    }
  }
  .footerCardCenter {
    position: fixed;
    bottom: 2%;
    width: 50rem;
    right: 20%;
    height: auto;
    min-height: 5rem;
    border-radius: 16px;
    opacity: 0.9;
    background-color: ${Colors.primary};
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex !important;
    padding: 1rem;
    z-index: 999;
    @media only screen and (max-width: 767px) {
      width: 90%;
      right: 5%;
      flex-direction: column;
      .footerContentRow {
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
      }

      .footerIcon {
        margin-bottom: 0.5rem;
      }

      .footerText {
        margin-bottom: 1rem;
        text-align: center;
      }

      .footerButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .buttonFooter {
          margin: 0.25rem 0;
          width: 100%;
        }
      }
    }

    &.footerCardCentered {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90%;
      margin: 0 auto;
    }
    &.sliderContainer {
      bottom: 20px;
    }
    &.footerCardMap {
      bottom: 80px;
    }
  }
  .footerCardCenter span{
    color: white;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  .name-footer-edit{
    display: contents;
  }
  .footerCardColRight{
    text-align: initial;
  }
  .footerCardColLeft{
    padding: 0 15px;
    @media only screen and (min-width: 768px) {
      text-align: initial;
      padding: 0 20px;
    }
    @media only screen and (max-width: 360px) {
      padding: 0 10px;
    }
  }
  .buttonFooter{
    margin: 0 2px;
    @media only screen and (min-width: 768px) {
      margin: 0 5px;
    }
  }
  .buttonFooter span{
    color: ${Colors.primary} !important;
  }
  .iconEdit{
    margin: 0;
    color: white;
  }
  .logoWhatsApp {
    width: 35px;
  }

  .globalIcon {
    color: ${Colors.primary};
    margin-right: 20px;
  }

  .currencyIcon {
    color: ${Colors.primary};
    margin-right: 20px;
    font-size: 16px;
  }

  .languageText {
    padding: 4px 10px 4px 10px;
    border: solid;
    border-radius: 7px;
    border-width: 1px;
  }

  .registration-type-modal {
    .ant-modal-content {
      border-radius: 16px;
      overflow: hidden;
      }    
      
      .modalContent {
        text-align: center;
      }

      .logo-container {
        margin-bottom: 20px;
      }
        
      .modal-title {
        color: ${Colors.lightPrimary};
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .modal-title-brande {
        color: ${Colors.primary} !important;
      }
      .registration-options {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin: 20px 0;

        .option-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 20px;

        }

        .vertical-divider {
          width: 1px;
          background-color: #E8E8E8;
          margin: 0 20px;
        }
      }

      .login-link {
        margin-top: 25px;
        
        .login-link-text {
          color: ${Colors.coal};
          font-weight: bold;
        }
      }
    }
  }

  .registration-button {
    background-color: #3996f3;
    color: white;
    border: none;
    height: 40px;
    border-radius: 4px;
    font-weight: 500;
  }

  .registration-button:hover {
    background-color: #2d7fd6;
    color: white;
  }

  
`;

export default TopbarHolder;

import React, { Component } from "react";
import AdScreenCardHolder from "./Styles/adScreenCardStyle";
import { Button, Row, Col, Card, Tooltip, Icon, Modal, Checkbox, DatePicker, Input, Upload, message, Spin, notification, Tag } from "antd";
import { Link } from "react-router-dom";
import Rectangle from "react-rectangle";
import moment from "moment";
import "moment-duration-format";
import placeholder from "../images/bg.jpg";
import { withTranslation } from 'react-i18next';
import { history } from '../redux';
import Slider from "react-slick";
import NumberFormat from "./NumberFormat";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole } from "../services/utils";
import { SeparateNumberFormat } from "../services/utils";
import QualitativeAudienceComponent from "./QualitativeAudienceComponent";
import VideoPlayerComponent from "./VideoPlayerComponent"
import VerifiedAgencyInfoModal from "./VerifiedAgencyInfoModal";
import { removeContentExtensions } from "../services/contents";
import ContentsLibraryContainer from "../containers/ContentsLibraryContainer";
import LazyRender from './HighOrderComponent';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "6px", top: "17px"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "6px", top: "17px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

class AdScreenCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleCamTrafficView: false,
      visibleCamScreenView: false,
      visibleEvidences: false,
      cam_traffic: null,
      cam_display: null,
      loadingSingleReport: true,
      shows: 0,
      newContent: [],
      pendingContent: false,
      loadingInternalNewSpot: false,
      audienceDatsWhy: null,
      idDisplay: this.props.display.display_id,
      visibleAudienceDatsWhyView: false,
      isVerifiedAgencyModalVisible: false,
      displayVisibleLibraryOfContents: null,
    };
  }

  componentDidMount() {

    // It is required when you go out of view before it loads
    this.props.cleanUpdateSpots();

    this.whileRequestUpdateContent = async (content) => {
      var {updateContentCampaignRequest, campaign, display, updateContentDisplayByContent} = this.props;
      this.setState({ pendingContent: true }); // This state is for changing the content state when it's modified.
      var newContent = {
          "content_id" : content.id,
          "content_name" : content.name,
          "content_type" : content.type,
          "file_thumb": content.file_thumb,
          "file": content.file,
          "content_file" : content.file,
          "content_file_thumb": content.file_thumb,
          "content_width": content.width,
          "content_height": content.height
      }
      if (content.type === 'image') {
        updateContentCampaignRequest(content, display, campaign.id);
        updateContentDisplayByContent(newContent, display);
      } else {
        var stop = false;
        updateContentCampaignRequest(content, display, campaign.id);

        while ((!content.ready && !stop) || (this.props.newSpotsChanged[0] && this.props.newSpotsChanged[0].content.file_thumb == "pending")) {
          await new Promise(resolve => setTimeout(resolve, 3000));
          const campaignRoute = '/campaign/' + campaign.id;
          if (history.location.pathname === campaignRoute && this.props.loadingChangeSpot) {
            updateContentCampaignRequest(content, display, campaign.id);
          } else if(!this.props.loadingChangeSpotSuccess ) {
            stop = true;
          }
        }

        // It is required when you go out of view before it loads
        if(!this.props.newSpotsChanged[0])
          return;
        newContent.file = this.props.newSpotsChanged[0].content.file
        newContent.file_thumb = this.props.newSpotsChanged[0].content.file_thumb
        newContent.content_file = this.props.newSpotsChanged[0].content.file
        newContent.content_file_thumb = this.props.newSpotsChanged[0].content.file_thumb
        // Update currentCompany for the nex view
        updateContentDisplayByContent(newContent, display);
      }
    }
    setTimeout(() => {
      if (this.props.reportSingleData && this.props.reportSingleData.report) {
        const reportDisplay = this.props.reportSingleData.report.find(element => element.display === this.props.display.display_id && element.content === this.props.display.content_id)
        if (reportDisplay !== undefined) {
          this.setState({ shows: reportDisplay.shows });
        }
      }
      this.setState({ loadingSingleReport: false });
    }, 4000);
  }

  getSingleReport() {
    if (!this.props.loadingSingleReport && this.props.display.display_id && this.props.display.content_id && !this.state.loadingSingleReport) {
      this.props.getSingleReportRequest({
        campaign: this.props.campaign.id,
        displays: this.props.display.display_id,
        contents: this.props.display.content_id,
        from_date: moment(this.props.display.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.props.display.to_date).format('YYYY-MM-DD'),
        is_campaign: 1
      })
      this.setState({ loadingSingleReport: true });

    }
  }

  componentDidUpdate(prevProps) {
    const wasLoadingSingleReportRedux = prevProps.loadingSingleReport && !this.props.loadingSingleReport;
    const hasSingleReportData = this.props.reportSingleData && this.props.reportSingleData.report && this.props.reportSingleData.report.length === 1;
    if (wasLoadingSingleReportRedux  && this.state.loadingSingleReport && hasSingleReportData) {
      if (this.props.reportSingleData && this.props.reportSingleData.report && this.props.reportSingleData.report[0].display === this.props.display.display_id ) {
        this.setState({ shows: this.props.reportSingleData.report[0].shows });
      } else {
        this.setState({ shows: 0 });
      }
      this.setState({ loadingSingleReport: false });
    }
    // Loader datsWhy info in this component
    if(this.props.audienceDatsWhy && this.props.audienceDatsWhy.id === this.state.idDisplay){
      if(this.state.audienceDatsWhy === null){
        this.setState({
          audienceDatsWhy: {id: this.props.display.display_id , data: this.props.audienceDatsWhy.data},
        });
      }
    }
  }

  setCamlyticsTokens() {
    // Set live view cam and traffic
    var baseLiveViewUrl = 'https://cloud.camlytics.com/widget/live_view/';
    if (this.props.display && this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
      if (camlyticsTokens && camlyticsTokens.cam_traffic) {
        this.setState({cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic});
      }
      if (camlyticsTokens && camlyticsTokens.cam_display) {
        this.setState({cam_display: baseLiveViewUrl + camlyticsTokens.cam_display});
      }
    }
  }

  showChangeSpotModal = () => {
    this.setState({
      warningChangeSpot: true,
    });
  }

  okChangeSpot = e => {
    this.setState({
      warningChangeSpot: false,
    });
  };

  cancelChangeSpot = e => {
    this.setState({
      warningChangeSpot: false,
    });
  };

  showLibraryOfContents = (display) => {
    this.setState({
      displayVisibleLibraryOfContents: display,
    });
  }

  cancelShowLibraryOfContents = e => {
    this.setState({
      displayVisibleLibraryOfContents: null,
    });
  };

  showCamTrafficModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamTrafficView: true,
    });
  }

  showQualitativeAudienceModal = (display_id) => {
    if (this.state.audienceDatsWhy && display_id === this.state.audienceDatsWhy.id) {
      this.props.setAudienceDatsWhy({id:display_id , data: this.state.audienceDatsWhy.data});
    }else{
      this.props.getAudienceDatsWhyRequest(display_id);
    }
    this.setState({
      visibleAudienceDatsWhyView: true,
    });
  }

 okQualitativeAudienceModal = e => {
    this.setState({
      visibleAudienceDatsWhyView: false,
    });
  };

  cancelQualitativeAudienceModal = e => {
    this.setState({
      visibleAudienceDatsWhyView: false,
    });
  };

  okCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  cancelCamTrafficModal = e => {
    this.setState({
      visibleCamTrafficView: false,
    });
  };

  showCamScreenModal = () => {
    this.setCamlyticsTokens();
    this.setState({
      visibleCamScreenView: true,
    });
  }

  okCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  cancelCamScreenModal = e => {
    this.setState({
      visibleCamScreenView: false,
    });
  };

  showEvidencesModal = () => {
    this.setState({
      visibleEvidences: true,
    });
  }

  okEvidencesModal = e => {
    this.setState({
      visibleEvidences: false,
    });
  };

  cancelEvidencesModal = e => {
    this.setState({
      visibleEvidences: false,
    });
  };

  uploadTimeExceededSpot = (placement, message) => {
    notification.error({
      message: message + ('(' + (this.props.display.display_slot_length/1000) + ' seg)'),
      description: this.props.t('This would modify the value of the campaign, create a new campaign or contact support'),
      duration: 10,
      placement
    });
  };


  showWarningNoAutomateModal = () => {
    this.setState({visibleWarningNoAutomateView: true});
  }

  okWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  cancelWarningNoAutomateModal = e => {
    this.setState({visibleWarningNoAutomateView: false});
  };

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  render() {
    const { t, user, newSpotsChanged, loadingChangeSpotSuccess} = this.props;
    var { audienceDatsWhy, display, currentCampaign} = this.props;

    // Is necesaty when update content in the campaign
    const displayAux = currentCampaign.contents_displays.find(element => element.id === display.id);

    const viewDisplay = {
      id: displayAux.id,
      display_id: displayAux.display_id,
      content_id: displayAux.content_id,
      file_thumb: displayAux.content_child_contents ? displayAux.content_child_contents[this.props.index].file_thumb : displayAux.content_file_thumb,
      content_type: displayAux.content_child_contents ? displayAux.content_child_contents[this.props.index].type : displayAux.content_type,
      fill_screen: displayAux.fill_screen ? displayAux.fill_screen : display.fill_screen,
      content: displayAux.content,
    };

    audienceDatsWhy = audienceDatsWhy ? audienceDatsWhy.data: null;
    let gender = null;
    let age = null;
    let se = null;
    let flow_data = null;
    if(audienceDatsWhy){
      age = audienceDatsWhy.age ? audienceDatsWhy.age : null;
      gender = audienceDatsWhy.gender ? audienceDatsWhy.gender : null;
      se = audienceDatsWhy.se ? audienceDatsWhy.se : null;
      flow_data = audienceDatsWhy.flow_data ? audienceDatsWhy.flow_data : null;
    }
    const { RangePicker } = DatePicker;

    const dateFormat = 'DD/MM/YYYY';

    const rules = JSON.parse(this.props.display.rules)

    // if rules not have days, update array for select all days
    if(rules && rules.length > 0){
      rules.map((rule)=>{
        let day = rule.conditions.find(condition => condition.type === 'day')
        if(day){
            if(day.value.length < 1){
              rule.conditions.find(condition => condition.type === 'day').value = [1,2,3,4,5,6,0]
            }
        }else{
          rule.conditions.push({type:'day', value:[1,2,3,4,5,6,0]})
        }
      })
    }
    // const putMethod = {_method: 'PUT'}

    const uploadProps = {
      name: "file",
      accept: "video/*,image/*,video/mp4,video/x-m4v",
      action: `${apiUrl}/contents`,
      data: file => ({
        name: removeContentExtensions(file.name)
      }),
      multiple: false,
      beforeUpload(file, fileList) {
        const isLt2M = file.size / 1024 / 1024 < 50;
        if (!isLt2M) {
          message.error(this.props.t('The file cannot be larger than 50MB!'), 10);
        }
        return isLt2M;
      },
      headers: { authorization: `Bearer ${this.props.token}` },
      onChange: async info => {
        this.props.loadingContentInCampaign(true)
        const status = info.file.status;
        if (status === "done") {
          if (user && hasAccessRole(user.roles, [UserRoleEnum.companyMember]) || (info.file.response.length < (display.display_slot_length + 1000))) {
            message.success(`${info.file.name} ${this.props.t('successfully uploaded')}`);
            this.whileRequestUpdateContent(info.file.response);
          } else {
            if (display.belong_to_smart_campaign) {
              this.uploadTimeExceededSpot('topBottom', this.props.t('In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time'));
            } else {
              this.uploadTimeExceededSpot('topBottom', this.props.t('You cannot upload files longer than what is allowed on the screen'));
            }
          }
          this.props.loadingContentInCampaign(false)
        } else if (status === "error") {
          message.error(`${this.props.t('Upload error')} ${info.file.name}`);
          this.props.loadingContentInCampaign(false)
        }
      }
    };

    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };

    return (
      <AdScreenCardHolder>
      {/* <LazyRender> */}
        <Card className="adScreenCard">
          <Row type="flex" justify="center" align="middle" gutter={16}>
            <Col xs={24} sm={9} md={9} lg={8} xl={8}>
              <img
                src={display.display_picture || placeholder}
                className="adScreenCardImage"
                style={{maxHeight: "280px"}}
                alt=""
              />
            </Col>
            <Col xs={24} sm={15} md={15} lg={7} xl={7}>
              <div className="screenInfo">
                <div>
                  <span style={{fontSize: "16px"}}>{display.display_name}</span>{" "}
                  {display.display_use_cms === 0 ? (
                    <Tooltip placement="bottomLeft" title={t('Non-automated screen. Click to see detail')}>
                      <Button className="screenWarningNoAutomateView" shape="circle" size='small' onClick={() => this.showWarningNoAutomateModal()}>
                        <Icon type="warning" theme="outlined" style={{color: "#ffc107"}}/>
                      </Button>
                    </Tooltip>
                  ) : null}
                  <Modal
                    title={t('Non-automated screen')}
                    visible={this.state.visibleWarningNoAutomateView}
                    onOk={this.okWarningNoAutomateModal}
                    onCancel={this.cancelWarningNoAutomateModal}
                  >
                    <p style={{margin: "25px"}}>{t('This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.')}</p>
                  </Modal>
                  <br />
                  {this.props.spotsLength > 1 ? (
                    <Tag style={{marginTop: "3px"}}>
                      Spot {this.props.index + 1} de {this.props.spotsLength}
                    </Tag>
                  ) : null }
                  {display.revision_status === "approved" && !this.state.pendingContent ? (
                    <Tag color="green" style={{marginTop: "3px"}}>
                      {t('Spot Approved')}
                    </Tag>
                  ) : null }
                  {display.active === 0 && (
                    <Tag style={{marginTop: "3px", borderColor: "8d8d8d", color: "#8d8d8d" ,background: "#fcfcfc"}}>
                      {t('Paused')}
                    </Tag>
                  )}
                  {display.revision_status === "rejected" && !this.state.pendingContent ? (
                    <Tag color="red" style={{marginTop: "3px"}}>
                      <span title={display.reject_reason}>
                        {t('Spot Rejected') + ' '}
                        ( {display.reject_reason.slice(0, 50)}
                          {display.reject_reason.length > 50 ? '...' : ''} )
                      </span>
                    </Tag>
                  ) : null }
                  {display.revision_status === "pending" || this.state.pendingContent ? (
                    <Tag color="orange" style={{marginTop: "3px"}}>
                      {t('The owner of this screen has not yet approved')}
                    </Tag>
                  ) : null }
                  <div className="screenStats">
                    {t('Real time impressions')}:{" "}
                    {!this.state.loadingSingleReport ? this.state.shows : (<Icon type="loading" theme="outlined"/>)}{" "}{" "}
                    <Icon type="reload" theme="outlined" onClick={() => this.getSingleReport()} disabled={this.props.loadingSingleReport}/>
                    <br />
                    {display.belong_to_smart_campaign ? t('Purchased impressions') : t('Promised impressions')}
                    {" "}<b>{SeparateNumberFormat(display.promised_shows)}</b>
                  </div>
                  <Link to={"/screen/" + display.display_id}>
                    <Button
                      type="primary"
                      className="screenCardMapDetailsButton detailButton"
                      icon="info-circle"
                    >
                      {t('View screen details')}
                    </Button>
                  </Link>
                  <br />
                  <br />
                  <b>
                    {t('Screen Format')}{" "}
                    <span className="primary">
                      {display.display_resolution_width}x
                      {display.display_resolution_height}
                    </span>
                  </b>{" "}
                  <br />
                  <b>
                    {t('Ad format')}{" "}
                    <span className="primary">
                      {display.content_width}x
                      {display.content_height}
                    </span>
                  </b>{" "}
                  <br />
                  <br />
                  <b>
                    {t('Price')}:{" "}
                    <span className="primary">
                    <NumberFormat
                      tax={this.props.campaign.invoice_issuing.alpha_2_code  != 'F'}
                      value={display.paid_amount_with_profit}
                      currency= {this.props.campaign.currency}
                    />
                    </span>
                    {display.paid_amount_with_profit == 0 ?
                      <span className="ant-tag ant-tag-orange" style={{marginLeft: "5px"}}>
                        {t('Subsidized')}
                      </span>
                      :null
                    }
                  </b>{" "}
                  <br />
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={9}
              xl={9}
              style={{textAlign:"center"}}
            >
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={24}
                xl={24}
                style={{marginTop: "15px", marginBottom: "10px"}}
              >
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {t('Preview')}
                  <Rectangle
                    aspectRatio={[
                      display.display_resolution_width,
                      display.display_resolution_height
                    ]}
                    style={{width: "70%", marginLeft: "15%"}}
                  >
                  <div className="screen">
                    { !(loadingChangeSpotSuccess && (viewDisplay.display_id == loadingChangeSpotSuccess.display_id ))?
                      <>
                        { viewDisplay.content_type === 'image' ?
                          <img
                            src = { newSpotsChanged[0] && (viewDisplay.id == newSpotsChanged[0].relation_id) ? newSpotsChanged[0].content.file_thumb : viewDisplay.file_thumb }
                            className={ viewDisplay.fill_screen ? "screenPreviewFilled": "screenPreview" }
                            alt=""
                          />
                        : null }
                        { viewDisplay.content_type === 'video' ?
                          <VideoPlayerComponent
                            relation={ newSpotsChanged[0] && (viewDisplay.id == newSpotsChanged[0].relation_id) ?
                            newSpotsChanged[0] : viewDisplay }
                          />
                        : null
                        }
                      </>
                      : null }
                      { loadingChangeSpotSuccess && (viewDisplay.display_id == loadingChangeSpotSuccess.display_id ) ? (
                        <div className="pulseLoaderContainer">
                          <Spin size="large" spinning />
                        </div>
                      ) : null}
                  </div>
                  </Rectangle>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{marginTop:"8px"}}
                >
                  {(!display.content_child_contents || display.content_child_contents.lenght === 0) && ( user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember])) ? (
                    <div style={{textAlign: "left", marginBottom: "8px"}}>
                      {user && user.company && user.company.type == 'agency' && !user.company.is_verified ?
                        <Button type="dashed" size="small" onClick={this.showVerifiedAgencyModal} style={{ borderRadius: "20px" }}>
                        {t('Change ad')}
                        </Button>
                      :
                        (this.props.campaign.new_ssp ? (
                            <Tooltip
                              placement="top"
                              title={display.display_use_cms === 1 ? (
                                <span>
                                  {t('Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration.')}
                                </span>
                              ) : (
                                <span>
                                  {t('Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration. No immediately')}
                                </span>
                              )}
                            >
                              <Button
                                type="primary"
                                size="small"
                                className="changeSpotButton"
                                style={{borderRadius: "20px"}}
                                disabled={loadingChangeSpotSuccess || this.props.loadingChangeSpotInCampign}
                                onClick={()=> this.showLibraryOfContents(display)}
                                >
                                {t('Change ad')}
                              </Button>
                            </Tooltip>
                        ):
                        <Upload {...uploadProps} disabled={loadingChangeSpotSuccess || this.props.loadingChangeSpotInCampign}>
                          <Tooltip
                            placement="top"
                            title={display.display_use_cms === 1 ? (
                              <span>
                                {t('Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration.')}
                              </span>
                            ) : (
                              <span>
                                {t('Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration. No immediately')}
                              </span>
                            )}
                            >
                            <Button type="primary" size="small" className="changeSpotButton" style={{borderRadius: "20px"}} disabled={loadingChangeSpotSuccess || this.props.loadingChangeSpotInCampign}>
                                {t('Change ad')}
                            </Button>
                          </Tooltip>
                        </Upload>
                      )}
                      <Modal
                        title={t('Change ad')}
                        visible={this.state.warningChangeSpot}
                        onOk={this.okChangeSpot}
                        onCancel={this.cancelChangeSpot}
                      >
                        <p className="changeSpotWarning">
                          {t('This action will change the ad (new piece that will go up). It does require a new approval in the event that the previous piece has already been approved. Remember to upload a similar piece, from the same campaign, client and brand.')}
                        </p>
                      </Modal>
                    </div>
                  ) : null}
                  { (this.props.campaign.new_ssp && this.state.displayVisibleLibraryOfContents && this.state.displayVisibleLibraryOfContents.display_id === display.display_id) ?
                    <Modal
                      title={t('Library of contents')}
                      visible={this.state.displayVisibleLibraryOfContents != null}
                      onOk={this.cancelShowLibraryOfContents}
                      onCancel={this.cancelShowLibraryOfContents}
                      width={1500}
                      bodyStyle={{ padding: '30px' }}
                      transitionName="fade"
                    >
                      <ContentsLibraryContainer
                        origin="infoCampaign"
                        relation={display}
                        exitModal={this.cancelShowLibraryOfContents}
                      />
                    </Modal> : null
                  }
                  {display.display_dats_why_poi_id !== null && display.display_dats_why_poi_id !== 0 ? (
                      <div style={{textAlign: "left", marginBottom: "8px"}}>
                        <Tooltip placement="bottomLeft" title={t('Audience Hyper-Insights that help you plan, monitor, and analyze Out of Home sites and campaigns to maximize ROI.')}>
                          <Button className="camTrafficViewButton"
                                  size='small'
                                  loading={this.state.visibleAudienceDatsWhyView && this.props.loadingAudienceDatsWhy}
                                  onClick={() => this.showQualitativeAudienceModal(display.display_id)}>
                            <Icon type="eye-o" theme="outlined"/>
                            {t("Dats Why audience")}
                          </Button>
                        </Tooltip>
                        <Modal
                          title={t("Dats Why audience")}
                          visible={this.state.visibleAudienceDatsWhyView && !this.props.loadingAudienceDatsWhy}
                          onOk={this.okQualitativeAudienceModal}
                          onCancel={this.cancelQualitativeAudienceModal}
                          width={1000}
                          style={{ top: "10px" }}
                        >
                          <QualitativeAudienceComponent
                            age={age}
                            gender={gender}
                            se={se}
                            flow_data={flow_data}
                            loadingAudienceDatsWhy={this.props.loadingAudienceDatsWhy}
                          />
                        </Modal>
                      </div>
                   ):null}
                  <div style={{textAlign: "left", marginBottom: "8px"}}>
                    <Tooltip placement="topLeft" title={t('Camera to traffic')}>
                      <Button className="camTrafficViewButton" size='small' onClick={() => this.showCamTrafficModal()}>
                        <Icon type="car" theme="outlined"/>
                        {t('View live traffic')}
                      </Button>
                    </Tooltip>
                    <Modal
                      title={t('Traffic camera for audience control and measurement')}
                      visible={this.state.visibleCamTrafficView}
                      onOk={this.okCamTrafficModal}
                      onCancel={this.cancelCamTrafficModal}
                    >
                      {this.state.cam_traffic ? (
                        <iframe id="live-view-traffic" src={this.state.cam_traffic} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                      ) : (
                        <p style={{ fontSize: "16px", textAlign: "center", marginTop: "30px", marginBottom: "30px"}}>{t('You have not configured a camera pointing at traffic')}</p>
                      )}
                    </Modal>
                  </div>
                  <div style={{textAlign: "left", marginBottom: "8px"}}>
                    <Tooltip placement="bottomLeft" title={t('Camera for certification')}>
                      <Button className="camCertificationButton" size='small' onClick={() => this.showCamScreenModal()}>
                        <Icon type="video-camera" theme="outlined"/>
                        {t('View live screen')}
                      </Button>
                    </Tooltip>
                    <Modal
                      title={t('Camera looking at screen for certification')}
                      visible={this.state.visibleCamScreenView}
                      onOk={this.okCamScreenModal}
                      onCancel={this.cancelCamScreenModal}
                    >
                      {this.state.cam_traffic ? (
                        <iframe id="live-view-traffic" src={this.state.cam_display} frameBorder="0" scrolling="no" width="100%" height="300px"></iframe>
                      ) : (
                        <p style={{ fontSize: "16px", textAlign: "center", marginTop: "30px", marginBottom: "30px"}}>{t('You have not configured camera pointing at the screen')}</p>
                      )}
                    </Modal>
                  </div>
                  <div style={{textAlign: "left", marginBottom: "8px"}}>
                    <Tooltip placement="bottomLeft" title="Ver Evidencias">
                      <Button className="evidencesButton" size='small' onClick={() => this.showEvidencesModal()} disabled={display.graphic_evidences && display.graphic_evidences.length > 0 ? false : true}>
                        <Icon type="camera" theme="outlined"/>
                        {t('See evidence')}: {display.graphic_evidences && display.graphic_evidences.length > 0 ? display.graphic_evidences.length : 0}
                      </Button>
                    </Tooltip>
                    <Modal
                      title={t('Evidence')}
                      visible={this.state.visibleEvidences}
                      onOk={this.okEvidencesModal}
                      onCancel={this.cancelEvidencesModal}
                    >
                      {display.graphic_evidences && display.graphic_evidences.length > 0 ?
                        (display.graphic_evidences.map((evidence, index) => (
                          <div className="screen" style={{ textAlign: "center"}}>
                            { evidence.type === 'image' ? (
                              <img
                                src={ evidence.evidence }
                                style={{ width: "250px", marginBottom: "10px", marginTop: "10px"}}
                                alt=""
                              />
                            ) : null }
                            { evidence.type === 'video' ? (
                              <video
                                controls
                                style={{ width: "250px", marginBottom: "20px", marginTop: "10px"}}
                              >
                                <source src={ evidence.evidence } type="video/mp4" />
                              </video>
                            ) : null }
                          </div>
                        )))
                      : null}
                    </Modal>
                  </div>
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={24}
                xl={24}
                style={{marginBottom:"8px"}}
              >
                {this.props.display.belong_to_smart_campaign || this.props.display.content_child_contents ? (
                  <div>
                    <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                      {rules ? (
                        rules.map((rule, index) => (
                          <Card key={index} style={{borderRadius: 10, marginBottom: "5px", paddingTop: "5px"}}>
                            {t('Rule')} {index + 1} {t('of')} {rules.length}:
                            <div style={{marginBottom: 15, marginTop: 10}}>
                              <Input
                                className="inputDateFrom"
                                disabled={true}
                                style={{width: "40%"}}
                                value={
                                  rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').from_date ? (
                                  moment(rule.conditions.find(condition => condition.type === 'date').from_date, 'YYYY-MM-DD').format('DD/MM/YYYY')) : (moment(display.from_date, 'YYYY-MM-DD').format('DD/MM/YYYY'))
                                }
                              /> a {" "}
                              <Input
                                className="inputDateTo"
                                disabled={true}
                                style={{width: "40%"}}
                                value={
                                  rule.conditions && rule.conditions.find(condition => condition.type === 'date') && rule.conditions.find(condition => condition.type === 'date').to_date ? (
                                  moment(rule.conditions.find(condition => condition.type === 'date').to_date, 'YYYY-MM-DD').format('DD/MM/YYYY')) : (moment(display.to_date, 'YYYY-MM-DD').format('DD/MM/YYYY'))
                                }
                              />
                            </div>
                            <div style={{marginBottom: 15}}>
                              {rule.conditions && rule.conditions.find(condition => condition.type === 'day') ? (
                                <div>
                                  <Checkbox disabled={true} value="1" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 1) ? true : false}><span className="checkboxDay">{t('M')}</span></Checkbox>
                                  <Checkbox disabled={true} value="2" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 2) ? true : false}><span className="checkboxDay">{t('T')}</span></Checkbox>
                                  <Checkbox disabled={true} value="3" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 3) ? true : false}><span className="checkboxDay">{t('W')}</span></Checkbox>
                                  <Checkbox disabled={true} value="4" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 4) ? true : false}><span className="checkboxDay">{t('TH')}</span></Checkbox>
                                  <Checkbox disabled={true} value="5" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 5) ? true : false}><span className="checkboxDay">{t('F')}</span></Checkbox>
                                  <Checkbox disabled={true} value="6" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 6) ? true : false}><span className="checkboxDay">{t('SA')}</span></Checkbox>
                                  <Checkbox disabled={true} value="0" checked={rule.conditions && rule.conditions.find(condition => condition.type === 'day') && rule.conditions.find(condition => condition.type === 'day').value.find(day => day === 0) !== undefined ? true : false}><span className="checkboxDay">{t('SU')}</span></Checkbox>
                                </div>
                              ) : (
                                t('Every day')
                              )}
                            </div>
                            <div style={{marginBottom: 15}}>
                              {rule.conditions.find(condition => condition.type === 'hour') ? (
                                <div>
                                  <Input
                                    disabled={true}
                                    style={{width: "40%"}}
                                    value={
                                      rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').from ?
                                      moment(rule.conditions.find(condition => condition.type === 'hour').from, 'HH:mm').format('HH:mm') : (t('All day'))
                                    }
                                  /> a {" "}
                                  <Input
                                    disabled={true}
                                    style={{width: "40%"}}
                                    value={
                                      rule.conditions && rule.conditions.find(condition => condition.type === 'hour') && rule.conditions.find(condition => condition.type === 'hour').to ?
                                      moment(rule.conditions.find(condition => condition.type === 'hour').to, 'HH:mm').format('HH:mm') : (t('All day'))
                                    }
                                  />
                                </div>
                              ) : (
                                t('All day')
                              )}
                            </div>
                          </Card>
                        ))
                      ):null}
                    </Slider>
                  </div>
                ) : null}
              </Col>
            </Col>
          </Row>
        </Card>
      {/* </LazyRender> */}
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal}></VerifiedAgencyInfoModal>
      </AdScreenCardHolder>
    );
  }
}
export default withTranslation()(AdScreenCard);

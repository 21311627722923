import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import AudienceProviderInfoHolder from "./Styles/AudienceProviderInfoStyle";
import genericAudience from "../images/generic-audience-icon.png";
import { Icon } from "antd";

const AudienceProviderInfo = ({ displays, t }) => {

    const [providerList, setProviders] = useState([]);
    const [hasNoData, setHasNoData] = useState(false);

    const screenOwnerProviderId = 2;

    const removeDuplicates = (arr) => {
        return Array.from(new Set(arr.map(JSON.stringify)), JSON.parse);
    };

    const getProviderName = (provider) => {
        return provider.id !== screenOwnerProviderId ? provider.stylized_name : t('display owner');
    };

    const getProviderImg = (provider) => {
        return provider && provider.image ? provider.image : genericAudience;
    };

    const sanitizeDisplayProviders = () => {
        // If a display has no provider, it will be assigned to the screen owner provider
        return displays.map(d => {
          if (!d.audience_provider_id) {
            return {
              ...d,
              audience_provider_id: screenOwnerProviderId,
              audience_provider: { id: screenOwnerProviderId },
            };
          }
          return d; // Return the original object if no changes are needed
        });
      };

    useEffect(() => {
        if (displays && displays.length > 0) {
            const auxDisplays = sanitizeDisplayProviders();
            const uniqueProviders = removeDuplicates(auxDisplays.filter(d => d.audience_provider_id).map(d => d.audience_provider));
            const hasNoData = displays.every(d => d.third_party_average_monthly_audience == null && d.audience_provider_id != 1);
            setProviders(uniqueProviders);
            setHasNoData(hasNoData);
        }
    }, [displays]);

    return (
        <AudienceProviderInfoHolder>
                {hasNoData ? (
                    <div className="audience-provider-info-component">
                        <div className="provider-container">
                            <div className="provider-image" >
                                <Icon type="warning" style={{ fontSize: '35px', color: '#FFA700'}}/>
                            </div>
                            <div>
                                {displays && displays.length == 1 ? t("There is no audience data for this screen") : t("There is no audience data for these screens")}
                            </div>
                        </div>
                    </div>
                ) :
                providerList.length && (
                    <div className="audience-provider-info-component">
                        {providerList.length < 2 && (
                            <div className="provider-container">
                                <img src={getProviderImg(providerList[0])} className="provider-image" />
                                <div>
                                    {displays && displays.length == 1 ? t("Audience data for this screen provided by") : t("Audience data for these screens provided by")} {' '}
                                    {providerList[0].url ? (
                                        <a href={providerList[0].url} target="_blank">{getProviderName(providerList[0])}</a>
                                    ) : (
                                        <span>{getProviderName(providerList[0])}</span>
                                    )}
                                </div>
                            </div>
                        )}
                        {providerList.length >= 2 && (
                            <div className="provider-container">
                                {displays && displays.length == 1 ? t("Audience data for this screen provided by") : t("Audience data for these screens provided by")} {' '}:
                                <div className="external-provider-names">
                                    {providerList.map((provider, index) => (
                                        <span key={index}>
                                            {index !== 0 && <span>&nbsp;/&nbsp;</span>}
                                            <img src={getProviderImg(provider)} className="small-provider-image" />
                                            {provider.url ? (
                                                <a href={provider.url} target="_blank">{getProviderName(provider)}</a>
                                            ) : (
                                                <span>{getProviderName(provider)}</span>
                                            )}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
        </AudienceProviderInfoHolder>
)};

export default withTranslation()(AudienceProviderInfo);
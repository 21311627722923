import React, { Component } from "react";
import QuotesHolder from "./Styles/quotesComponentStyle";
import { Row, Col, Table, Button, Tooltip, message , Icon, Input , Tabs} from "antd";
import moment from "moment";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole, getUrl } from "../services/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInbox} from '@fortawesome/free-solid-svg-icons';

import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

class QuotesComponent extends Component {

  constructor(props) {
    super(props);
    this.searchQuotes = this.searchQuotes.bind(this);
  }
  state = {
    search: "",
    archived: false,
    quoteEditingId: -1,
    quoteEditingAuxName: ""
  };
  componentDidMount() {
    const { user } = this.props;
    if (user && ( hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]))) {
      this.props.getQuotes(this.props.user.id, {archived: this.state.archived});
    } else {
      this.props.setShouldPromptLogin(true, window.location.pathname);
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {archived} = this.state;
    const { t , fetchingDeleteQuote, fetchingArchivedQuote, errors,user, getQuotes} = this.props;
    let is_deleted = fetchingDeleteQuote && !prevProps.fetchingDeleteQuote
    let is_archived = fetchingArchivedQuote && !prevProps.fetchingArchivedQuote

    if (is_deleted || is_archived || archived !== prevState.archived) {
      if (errors.length === 0) {
        this.props.getQuotes(user.id, {'archived': archived});
        if(is_deleted) message.success(t('Quote removed successfully'));
        if(is_archived) message.success(!archived ? t('Quote archived successfully') : t('Quote unarchived successfully'));
        return;
      }
      message.error(this.props.t('An error occurred while trying to delete quote'));
    }

    const wasEditingQuote = prevProps.editingQuote && !this.props.editingQuote;
    const editingQuoteSuccess = prevProps.editQuoteSuccess !== this.props.editQuoteSuccess;
    const editingQuoteFailure = prevProps.editQuoteFailure !== this.props.editQuoteFailure;

    if (wasEditingQuote) {
      if (editingQuoteSuccess) {
        message.success(t('Quote updated successfully'));
        this.cancelEditQuoteName();
        getQuotes(this.props.user.id, {archived: this.state.archived});
      } else if (editingQuoteFailure) {
        message.error(t('An error occurred while trying to update quote'));
      }
    }

  }
  searchQuotes(e){
    var value = e.target.value
    setTimeout(() => {
      this.setState({ search: value })
    }, 500)
  }

  callback = () =>  {
    this.setState({ archived: !this.state.archived })
  }

  seeProposal = (dataSource) => {
    const { i18n, user } = this.props;
    let lenguageParse
    switch (i18n.language) {
      case "es":
        lenguageParse = "esLA";
        break;
      case "es_ar":
        lenguageParse = "es";
        break;
      default:
        break;
    }
    const companyData = btoa(
      JSON.stringify(user.company)
    );
    const parameter = `lenguage=${lenguageParse || i18n.language}&token=${dataSource.token}&company=${companyData}`;
    window.open(getUrl(window.location, "quote.html", parameter));
  }

  editQuoteName = (id, name) => {
    this.setState({ quoteEditingId: id });
    this.setState({ quoteEditingAuxName: name });
  }

  cancelEditQuoteName = () => {
    this.setState({ quoteEditingId: -1 });
    this.setState({ quoteEditingAuxName: "" });
  }

  saveQuoteName = async () => {
    const quoteObject = {
      name: this.state.quoteEditingAuxName,
    };
    this.props.editQuote(this.state.quoteEditingId, quoteObject);
  }

  render() {
    const { t, editingQuote,  } = this.props;
    const {archived} = this.state;

    const pagination = {
      pageSize: 10,
    }

    const columns = [
      {
        title: "Id",
        key: "id",
        className: "customBody",
        render: (_, dataSource) => <span>{dataSource.id}</span>,
      },
      {
        title: t("Name"),
        key: "name",
        className: "customBody",
        render: (_, dataSource) =>
        <span>
            {this.state.quoteEditingId !== dataSource.id ?
              <span >
                <span>{dataSource.name}</span>
                <Button
                  type="dashed"
                  shape="circle"
                  icon="edit"
                  style={{ marginLeft: "5px", color: "#bfbfbf" }}
                  onClick={() => this.editQuoteName(dataSource.id, dataSource.name)}
                />
              </span>
            :
              <span style={{display: 'flex'}}>
                <Input
                  value={this.state.quoteEditingAuxName}
                  onChange={e => this.setState({ quoteEditingAuxName: e.target.value })}
                  onKeyDown={(e) => e.key === 'Enter' && this.saveQuoteName()}
                />
                <Button
                  type="dashed"
                  icon="check"
                  style={{ marginLeft: "5px" }}
                  onClick={() => this.saveQuoteName()}
                  loading={editingQuote}
                  disabled={this.state.quoteEditingAuxName === "" || this.state.quoteEditingAuxName === dataSource.name}
                />
                <Button
                  type="dashed"
                  icon="close"
                  style={{ marginLeft: "5px" }}
                  onClick={() => this.cancelEditQuoteName()}
                  disabled={editingQuote}
                />
              </span>
            }
        </span>
        ,
      },
      {
        title: t("Client"),
        key: "client",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {dataSource.client ? dataSource.client.name : t("Not assigned")}
          </span>
        ),
      },
      {
        title: t("Mark"),
        key: "brand",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {dataSource.brand ? dataSource.brand.name : t("Not assigned")}
          </span>
        ),
      },
      {
        title: t("Type"),
        key: "brandType",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {JSON.parse(dataSource.properties).budget
              ? t("Programmatic")
              : t("Traditional")}
          </span>
        ),
      },
      {
        title: t("Budget"),
        key: "brandBudget",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {JSON.parse(dataSource.properties).budget
              ? "$ " + JSON.parse(dataSource.properties).budget
              : t("See quote")}
          </span>
        ),
      },
      {
        title: t("Created"),
        key: "created",
        className: "customBody",
        render: (_, dataSource) => (
          <span>{moment(dataSource.created_at).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: t("Start"),
        key: "from_date",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {moment(JSON.parse(dataSource.properties).from_date).format(
              "DD/MM/YYYY"
            )}
          </span>
        ),
      },
      {
        title: t("End"),
        key: "to_date",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {moment(JSON.parse(dataSource.properties).to_date).format(
              "DD/MM/YYYY"
            )}
          </span>
        ),
      },
      {
        title: t("Qty. Screens"),
        key: "count_displays",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            {(JSON.parse(dataSource.properties).displays.match(/,/g) || [])
              .length + 1}
          </span>
        ),
      },
      {
        title: t("Action"),
        key: "action",
        className: "customBody",
        render: (_, dataSource) => (
          <span>
            <Tooltip placement="top" title={t("See quote")}>
              <Button
                shape="circle"
                icon="eye"
                onClick={async () => this.seeProposal(dataSource)}
              />
            </Tooltip>{" "}
            <Tooltip placement="top" title={t("Create campaign")}>
              <Button
                shape="circle"
                icon="notification"
                onClick={async () => {
                  let properties = JSON.parse(dataSource.properties);
                  let {countries, user} = this.props;
                  let invoice_issuing_country = properties.invoice_issuing_country
                  let dsp_selected = properties.external_dsp_id
                  let country, code_country, matchCountry;
                  if(invoice_issuing_country && invoice_issuing_country.length > 0){
                    invoice_issuing_country = this.props.countries.filter((country) => country.alpha_2_code === invoice_issuing_country)[0];
                    country = invoice_issuing_country.name
                    code_country = invoice_issuing_country.alpha_2_code
                  }else{
                    matchCountry = countries.filter((country) => country.name === user.company.country);
                    country = matchCountry[0].name
                    code_country = matchCountry[0].alpha_2_code
                  }
                  let currency = properties.currency
                  if(!currency){
                    currency = user.company.currency
                  }
                  this.props.setBilling(currency,
                                        country,
                                        code_country);
                  const data = await this.props.getQuote(
                    dataSource.token,
                    dataSource.client,
                    dataSource.brand,
                    this.props.cart,
                    dsp_selected,
                  );
                }}
              />
            </Tooltip>{" "}
            <Tooltip placement="top" title={t("Delete quote")}>
              <Button
                icon="delete"
                shape="circle"
                onClick={() => this.props.deleteQuote(dataSource.id)}
              />
            </Tooltip>
            <Tooltip placement="top" title={archived ? t("Unarchived quote") : t("Archived quote")}>
              <Button
                style={{marginLeft: "3px"}} shape="circle"
                onClick={() => this.props.setArchivedQuote(dataSource.id, !archived)}>
                <FontAwesomeIcon icon={faInbox}/>
              </Button>
            </Tooltip>
          </span>
        ),
      },
    ];
    var listQuotes = []
    if(this.props.quotes != undefined){
      if(this.state.search==""){
        listQuotes = this.props.quotes
      }else{
        this.props.quotes.forEach(c => {
          if(c.name.toLowerCase().includes(this.state.search.toLowerCase())||c.id.toString().includes(this.state.search)){
            listQuotes.push(c)
          }
        });
      }
    }
    return (
      <QuotesHolder>
        <Row
          className="quoteContainer"
          type="flex"
          justify="center"
        >
          <Col xs={23} sm={22} md={20} lg={20} xl={20}>
            <h1>{t('Quotes')}</h1>
            <Row
              className="campaignContainer row-search"
              type="flex"
              justify="end"
            >
              <Col xs={23} sm={22} md={12} lg={12} xl={12}>
                <Input
                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={t('Search')}
                onChange={this.searchQuotes}
                />
              </Col>
            </Row>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane
                  tab={<span><Icon type="solution" theme="outlined" /> {t('Quotes')}</span>} key="1"
                >
                  <Table
                    rowKey="id"
                    bordered
                    columns={columns}
                    dataSource={listQuotes}
                    pagination={pagination}
                    loading={this.props.fetching}
                    style={{overflowX:"auto"}}
                  />
                </TabPane>
                <TabPane
                  tab={<span><FontAwesomeIcon icon={faInbox} className="icon-archived"/>{t('Archived')}</span>} key="2"
                >
                  <Table
                    rowKey="id"
                    bordered
                    columns={columns}
                    dataSource={listQuotes}
                    pagination={pagination}
                    loading={this.props.fetching}
                    style={{overflowX:"auto"}}
                  />
                </TabPane>
            </Tabs>
          </Col>
        </Row>
      </QuotesHolder>
    );
  }
}
export default withTranslation()(QuotesComponent);

import { createReducer, createActions } from "reduxsauce";

import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userLoginRequest: ["email", "password", "code2FA"],
  userLoginFromCmsRequest: ["token"],
  userLoginSuccess: ["user", "token"],
  userLoginFailure: ["errors"],
  setInitWithToken: ["loginInfo"],
  userUpdate: ["user"],
  cleanErrors: [],
  userLoginRequire2fa: ["require2FA"],
  userRegisterRequest: ["user"],
  userRegisterSuccess: [],
  resetUserAccountCreatedSuccessfully: [],
  userRegisterFailure: ["errors"],
  userResetPasswordRequest: [
    "email",
    "password",
    "password_confirmation",
    "token "
  ],
  userResetPasswordSuccess: ["user"],
  userResetPasswordFailure: ["errors"],
  userForgotPasswordRequest: ["email"],
  userForgotPasswordSuccess: ["user"],
  userForgotPasswordFailure: ["errors"],
  resendVerificationEmailRequest: ["email"],
  resendVerificationEmailSuccess: ["user"],
  resendVerificationEmailFailure: ["errors"],
  companyRegisterRequest: ["company"],
  companyRegisterSuccess: ["messageCompanyOk"],
  companyRegisterFailure: ["errors"],

  getCountriesRequest: [],
  getCountriesRequestSuccess: ["countries"],
  getCountriesRequestFailure: ["errors"],

  getLocationRequest: [],
  getLocationRequestSuccess: ["location"],
  getLocationRequestFailure: ["errors"],

  setShouldPromptLogin: ["shouldPromptLogin", "redirectTarget"],
  userEmailUpdateRequest: [
    "id",
    "first_name",
    "last_name",
    "username",
    "email"
  ],
  userEmailUpdateSuccess: ["email"],
  userEmailUpdateFailure: ["errors"],
  userPasswordUpdateRequest: [
    "id",
    "old_password",
    "password",
    "password_confirmation",
  ],
  userPasswordUpdateSuccess: [""],
  userPasswordUpdateFailure: ["errors"],
  userLogout: null,
  userReset: null,
  setBilling: ["currency", "billingCountry" , "invoiceIssuingCountry"],
  setIsSuperUser: ["isSuperUser"],
});

export const UserAccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  token: null,
  require2FA: false,
  shouldPromptLogin: false,
  redirectTarget: null,
  closeModal: false,
  passwordForgotSent: false,
  verificationResent: false,
  requestingResendVerification: false,
  errors: [],
  fetching: false,
  fetchingCountries: false,
  creatingCompany: false,
  currency: null,
  billingCountry: null,
  invoiceIssuingCountry: null,
  countries: [],
  location: null,
  fetchingLocation: false,
  userAccountCreatedSuccessfully: false,
  isSuperUser: false, // When user is superAdmin || superModeratorAgency || superModeratorMediaCompany
  newVersionCampaign: false,
  loginInfo: null
});

/* ------------- Reducers ------------- */

export const userLoginRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  });

export const userLoginFromCmsRequest = (state: Object) =>
state.merge({
  fetching: true,
  errors: []
});

export const userLoginSuccess = (state: Object, { user, token }: Object) =>
  state.merge({
    user,
    token,
    fetching: false
  });

export const userUpdate = (state: Object, { user }: Object) =>
  state.merge({
    user,
  });

export const userLoginFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  });

export const setInitWithToken = (state: Object, { loginInfo }: Object) =>
  state.merge({
    loginInfo: loginInfo
  });

export const userLoginRequire2fa = (state: Object, { require2FA }: Object) =>
  state.merge({
    require2FA,
    fetching: false
  });

export const userRegisterRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  });

export const userRegisterSuccess = (state: Object) =>
  state.merge({
    fetching: false,
    userAccountCreatedSuccessfully: true
  });

export const resetUserAccountCreatedSuccessfully = (state: Object) =>
  state.merge({
    userAccountCreatedSuccessfully: false
  });

export const userRegisterFailure = (state: Object, { errors }) =>
  state.merge({
    fetching: false,
    errors,
    userAccountCreatedSuccessfully: false
  });

export const userEmailUpdateRequest = (state: Object) =>
  state.merge({
    fetching: true,
    closeModal: false,
    errors: []
  });

export const userEmailUpdateSuccess = (state: Object, { email }) =>
  state.merge({
    user: Object.assign({}, state.user, { email }),
    email,
    closeModal: true,
    fetching: false
  });

export const userEmailUpdateFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  });

export const userPasswordUpdateRequest = (state: Object) =>
  state.merge({
    fetching: true,
    closeModal: false,
    errors: []
  });

export const userPasswordUpdateSuccess = (state: Object) =>
  state.merge({
    closeModal: true,
    fetching: false
  });

export const userPasswordUpdateFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  });

export const userResetPasswordRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  });

export const userResetPasswordSuccess = (state: Object, { user }: Object) =>
  state.merge({
    user,
    fetching: false
  });

export const userResetPasswordFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  });

export const userForgotPasswordRequest = (state: Object) =>
  state.merge({
    requestingPassword: true,
    errors: []
  });

export const userForgotPasswordSuccess = (state: Object) =>
  state.merge({
    passwordForgotSent: true,
    requestingPassword: false
  });

export const userForgotPasswordFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingPassword: false,
    errors
  });

  export const resendVerificationEmailRequest = (state: Object) =>
  state.merge({
    requestingResendVerification: true,
    errors: []
  });

export const resendVerificationEmailSuccess = (state: Object) =>
  state.merge({
    verificationResent: true,
    requestingResendVerification: false
  });

export const resendVerificationEmailFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingResendVerification: false,
    errors
  });

export const companyRegisterRequest = (state: Object) =>
  state.merge({
    creatingCompany: true,
    errors: []
  });

export const companyRegisterSuccess = (state: Object, { messageCompanyOk }) =>
  state.merge({
    messageCompanyOk,
    creatingCompany: false
  });

export const companyRegisterFailure = (state: Object, { errors }) =>
  state.merge({
    creatingCompany: false,
    errors
  });

export const getCountriesRequest = (state: Object) =>
  state.merge({
    fetchingCountries: true,
    errors: []
  });

export const getCountriesRequestSuccess = (state: Object, { countries }: Object) =>
  state.merge({
    countries: [... countries]
  });

export const getCountriesRequestFailure = (
  state: Object,
    { errors }: Object
  ) =>
    state.merge({
      fetchingCountries: false,
      errors
  });

export const getLocationRequest = (state: Object) =>
  state.merge({
    fetchingLocation: true,
    errors: []
  });

export const getLocationRequestSuccess = (state: Object, { location }) =>
  state.merge({
    location: location,
    fetchingLocation: false,
  });

export const getLocationRequestFailure = (state: Object, { errors }) =>
  state.merge({
    location: null,
    fetchingLocation: false,
    // errors
  });

export const setShouldPromptLogin = (
  state: Object,
  { shouldPromptLogin, redirectTarget }: Object
) =>
  state.merge({
    shouldPromptLogin,
    redirectTarget
  });

export const setIsSuperUser = (state: Object, { isSuperUser }) =>
  state.merge({
    isSuperUser
  });

export const userReset = (state: Object) => INITIAL_STATE;

export const userLogout = userReset;

export const setBilling = (state: Object, { currency , billingCountry, invoiceIssuingCountry}: Object) =>
  state.merge({
    currency,
    billingCountry,
    invoiceIssuingCountry
  });

export const cleanErrors = (state: Object) =>
state.merge({
  errors: []
});
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_LOGIN_REQUEST]: userLoginRequest,
  [Types.USER_LOGIN_FROM_CMS_REQUEST]: userLoginFromCmsRequest,
  [Types.USER_LOGIN_SUCCESS]: userLoginSuccess,
  [Types.USER_LOGIN_FAILURE]: userLoginFailure,
  [Types.SET_INIT_WITH_TOKEN]: setInitWithToken,
  [Types.USER_UPDATE]: userUpdate,
  [Types.CLEAN_ERRORS]: cleanErrors,
  [Types.USER_LOGIN_REQUIRE2FA]: userLoginRequire2fa,
  [Types.USER_REGISTER_REQUEST]: userRegisterRequest,
  [Types.USER_REGISTER_SUCCESS]: userRegisterSuccess,
  [Types.USER_REGISTER_FAILURE]: userRegisterFailure,
  [Types.RESET_USER_ACCOUNT_CREATED_SUCCESSFULLY]: resetUserAccountCreatedSuccessfully,
  [Types.USER_RESET_PASSWORD_REQUEST]: userResetPasswordRequest,
  [Types.USER_RESET_PASSWORD_SUCCESS]: userResetPasswordSuccess,
  [Types.USER_RESET_PASSWORD_FAILURE]: userResetPasswordFailure,
  [Types.USER_FORGOT_PASSWORD_REQUEST]: userForgotPasswordRequest,
  [Types.USER_FORGOT_PASSWORD_SUCCESS]: userForgotPasswordSuccess,
  [Types.USER_FORGOT_PASSWORD_FAILURE]: userForgotPasswordFailure,
  [Types.RESEND_VERIFICATION_EMAIL_REQUEST]: resendVerificationEmailRequest,
  [Types.RESEND_VERIFICATION_EMAIL_SUCCESS]: resendVerificationEmailSuccess,
  [Types.RESEND_VERIFICATION_EMAIL_FAILURE]: resendVerificationEmailFailure,
  [Types.USER_EMAIL_UPDATE_REQUEST]: userEmailUpdateRequest,
  [Types.USER_EMAIL_UPDATE_SUCCESS]: userEmailUpdateSuccess,
  [Types.USER_EMAIL_UPDATE_FAILURE]: userEmailUpdateFailure,
  [Types.USER_PASSWORD_UPDATE_REQUEST]: userPasswordUpdateRequest,
  [Types.USER_PASSWORD_UPDATE_SUCCESS]: userPasswordUpdateSuccess,
  [Types.USER_PASSWORD_UPDATE_FAILURE]: userPasswordUpdateFailure,
  [Types.COMPANY_REGISTER_REQUEST]: companyRegisterRequest,
  [Types.COMPANY_REGISTER_SUCCESS]: companyRegisterSuccess,
  [Types.COMPANY_REGISTER_FAILURE]: companyRegisterFailure,
  [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
  [Types.GET_COUNTRIES_REQUEST_SUCCESS]: getCountriesRequestSuccess,
  [Types.GET_COUNTRIES_REQUEST_FAILURE]: getCountriesRequestFailure,
  [Types.GET_LOCATION_REQUEST]: getLocationRequest,
  [Types.GET_LOCATION_REQUEST_SUCCESS]: getLocationRequestSuccess,
  [Types.GET_LOCATION_REQUEST_FAILURE]: getLocationRequestFailure,
  [Types.SET_SHOULD_PROMPT_LOGIN]: setShouldPromptLogin,
  [Types.USER_LOGOUT]: userLogout,
  [Types.USER_RESET]: userReset,
  [Types.SET_BILLING]: setBilling,
  [Types.SET_IS_SUPER_USER]: setIsSuperUser,
});

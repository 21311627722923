import React, { Component } from "react";
import PropTypes from "prop-types";
import ScreenHolder from "./Styles/screenComponentStyle";
import { Icon, Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Tabs } from "antd";
import  AudienceProviderInfo  from "./AudienceProviderInfo";
import AudienceGraphResumenComponent from "./AudienceGraphResumenComponent";
import GenerateSkeletonComponent from "./GenerateSkeletonComponent";

const { TabPane } = Tabs;

class AudienceSummaryComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchAudienceData();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldRefreshData(prevProps)) {
      this.fetchAudienceData();
    }
  }

  shouldRefreshData(prevProps) {
    const { start_date, end_date, displays, campaign } = this.props;
    return prevProps.start_date !== start_date ||
           prevProps.end_date !== end_date ||
           prevProps.displays !== displays ||
           prevProps.campaign !== campaign;
  }

  fetchAudienceData() {
    const { campaign, start_date, end_date, displays, getProposalAudience } = this.props;

    if (campaign) {
      // Campaign mode
      getProposalAudience({
        campaign_id: campaign.id
      });
    } else {
      // Cart/proposal mode
      const contentDisplays = displays.map(display => ({
        display_id: display.id,
        rules: display.rules || []
      }));

      getProposalAudience({
        contentDisplays,
        startDate: `${start_date} 00:00:00`,
        endDate: `${end_date} 23:59:59`,
      });
    }
  }

  render() {
    const { t, user, campaign, displays, proposalAudience} = this.props;

    return (
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <Icon type="edit" />
              {t("Audience")}
            </span>
          }
          key="1"
        >
          <ScreenHolder>
            <Row
              className="screenContainer"
              type="flex"
              justify="center"
              align="middle"
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <AudienceProviderInfo displays={displays} user={user}/>
                { proposalAudience ? (
                  <AudienceGraphResumenComponent proposalAudience={ proposalAudience }></AudienceGraphResumenComponent>
                ) : (
                  <GenerateSkeletonComponent
                    count={1}
                    height={400}
                    width="100%"
                    type="graph"
                  />
                )}
              </Col>
            </Row>
          </ScreenHolder>
        </TabPane>
      </Tabs>
    );
  }
}

AudienceSummaryComponent.contextTypes = {
  router: PropTypes.object,
};

const Extended = withTranslation()(AudienceSummaryComponent);
Extended.static = AudienceSummaryComponent.static;

export default AudienceSummaryComponent;

import React from 'react';

class HideWhenCampaignActiveComponent extends React.Component {

  isContentInCurrentCampaign = (contentId, displaysId = null) => {
    const { currentContentsDisplays, editCampaignActive, isPaymentStatusApproved } = this.props;
    if (!editCampaignActive) return false;
    if (!isPaymentStatusApproved) return false;
    if (currentContentsDisplays.length === 0) return false;
    if (!displaysId) {
      return currentContentsDisplays.some(content => content.contentId === contentId);
    }

    if (displaysId) {
      if (Array.isArray(displaysId)) {
        return displaysId.some(displayId =>
          currentContentsDisplays.some(contentDisplay =>
            contentDisplay.contentId === contentId && contentDisplay.displayId === displayId
          )
        );
      } else {
        return currentContentsDisplays.some(content => content.contentId === contentId && content.displayId === displaysId);
      }
    }
  };

  render() {
    const { contentId, displayId, children } = this.props;
    const shouldRender = !this.isContentInCurrentCampaign(contentId, displayId);

    if (!shouldRender) return null;

    return (
      <>
        {children}
      </>
    );
  }
}

export default HideWhenCampaignActiveComponent;
import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreenHolder = styled.div`
  .topbar {
    min-height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: ${Colors.primary};
    padding: 15px 45px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
  .squeletorImage {
    margin: 2rem !important;
    height: 400px;
    background-color: #f2f2f2;
  }
  .squeletorRectangle {
    margin: 1.7rem !important;
    height: 12rem;
    background-color: #f2f2f2;
  }
  .squeletorCircle{
    border-radius: 200px;
    height: 15rem;
    width: 15rem;
    background-color: #f2f2f2;
    textAlign: center;
  }
  .loading::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: -webkit-gradient(linear, left top,
                  right top, from(transparent), 
                  color-stop(rgba(255, 255, 255, 0.2)),
                  to(transparent));

      background: linear-gradient(90deg, transparent,
              rgba(255, 255, 255, 0.2), transparent);

      /* Adding animation */
      animation: loading 0.8s infinite;
  }
    /* Loading Animation */
  @keyframes loading {
        100% {
            transform: translateX(100%);
        }
    }
  }
  .backButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    border-color: ${Colors.snow};
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5 !important;
  }
  .addCartButton {
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${Colors.snow};
    color: ${Colors.primary};
    border-color: ${Colors.primary};
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 153px;
    right: 25px;
    z-index: 1;
    .icon {
      fontSize: 18px;
      color: ${Colors.primary};
    }
    @media (max-width: 772px) {
      top: 240px !important;
      right: 45px !important;
      z-index: 1;
    }
  }
  .popup {
    top: 58px !important;
    right: 16px !important;
    @media (max-width: 772px) {
      top: 142px !important;
      right: 28px !important;
    }
  }

  h1 {
    color: ${Colors.snow};
    margin: 5px 15px;
  }

  .screenContainer {
    padding: 10px 0 0;
    align-items: flex-start;
  }
  .screenCard {
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    margin: 0 10px 10px 10px;
  }

  .screenCard .ant-card-body {
    padding: 0;
  }

  .screenImageContainer {
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .screenDescriptionContainer {
    margin-bottom: 5px;
  }
  .selectTranslate {
    margin-top: 10px;
  }
  .screenItemContainer {
    margin-bottom: 15px;
  }

  .screenImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .slick-dots {
    bottom: 15px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: ${Colors.snow};
  }

  .primary {
    color: ${Colors.primary};
  }

  h4 {
    color: ${Colors.coal};
    font-size: 16px;
    font-weight: 600;
  }

  .backButtonTranslate {
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
  }
  .iconTranslate {
    margin-right: 5px;
  }
  .span-description {
    margin-left: 20px;
  }
  .screenCardContent {
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 12px;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 32px !important;
    opacity: 0.9 !important;
    color: #bfbfbff2;
  }
  .box-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
  .box {
    height: auto;
    background: #fafafa;
    overflow: hidden;
    height: 400px;
  }
  .box img {
    width: 100%;
    height: 400px;
    background-color: #fafafa;
    border-radius: 8px;
  }
  @supports (object-fit: cover) {
    .box img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  .contentDescription {
    border-top-color: rgb(128 128 128 / 11%);
    border-top-style: solid;
  }
  .slick-slide {
    background-color: #fafafa;
  }
  .col-info {
    text-align: center;
  }
  .title-col {
    color: #342f2f91;
  }
  .title-inherit {
    font-size: inherit;
  }
  .number-col {
    font-size: 1.1em;
  }
  .number-col-info {
    font-size: 1em;
  }
  .link-right {
    height: 15%;
    border-right: solid #acc0d8 1px;
  }
  .line-divider {
    margin-top: 15px;
    margin-bottom: 14px;
  }
  .important-text-information {
    margin-left: 20px;
  }
  .list-location {
    margin-left: 20px;
    padding-left: 10px;
    margin-right: 20px;
  }
  .margin-card-image {
    padding-bottom: 5px !important;
    padding-top: 10px !important;
  }
  .list-location h4 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  @media (max-width: 772px) {
    .link-right {
      height: 0%;
      border-right: solid #acc0d8 0px;
    }
    .margin-card-image {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .logoDatswhy {
    width: 100px;
    padding: 3px;
    background-color: #272547;
  }
  .tab-screen .ant-tabs-tab {
    margin: 0;
  }
  .icon-bundle {
    width: 18px;
    height: 18px;
    border-radius: 16px;
    margin-right: 6px;
    margin-bottom: 2px;
    padding: 0px;
    object-fit: cover;
  }
  .titleModalBundle {
    text-align: center;
    padding: 15px;
    width: 80%;
    background-color: ${Colors.extraLightPrimary};
  }
`;

export default ScreenHolder;

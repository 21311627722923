import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ScreenHolder from "./Styles/screenComponentStyle";
import Slider from "react-slick";
import { Card, Button, Icon, Row, Col, Tooltip } from "antd";
import { withRouter } from "react-router";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { compose } from "recompose";
import { withTranslation } from 'react-i18next';
import PrincipalInformationScreenComponent from './PrincipalInformationScreenComponent'
import GoogleTranslator from "./GoogleTranslator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import { Tabs, Divider, List } from 'antd';
import marker from "../images/marker-latinad.png"
import { Skeleton } from 'antd';
import { SeparateNumberFormat } from "../services/utils";
import defaultImage from "../images/bg.jpg";
import QualitativeAudienceComponent from "./QualitativeAudienceComponent";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import { BundleSvg } from "../images/customIcons/BundleSvg";
import Colors from "../themes/Colors";
import moment from "moment";
import BundleCardComponent from "./BundleCardComponent";
import { marker as markerWhiteLabel } from "../images/customIcons/makersIcons/marker";
import { getDomain } from "../services/utils";

const { TabPane } = Tabs;
const domain = getDomain(window.location.hostname)
var tabs = { info: "1", bundles: "7" };
const DisplayMap = compose(withGoogleMap)(
  class DisplayMapComponent extends Component {
    render() {
      let defaultCenter = { lat: -34.397, lng: 150.644 };
      const { display } = this.props
      defaultCenter = {
        lat: parseFloat(display.latitude ? display.latitude : display[0].latitude, 10),
        lng: parseFloat(display.longitude ? display.longitude : display[0].longitude, 10)
      };
      var listDisplays = display.secondary_displays ? display.secondary_displays : [];
      listDisplays = display.length > 1 ? display : [];
      return (
        this.props.display && (
          <GoogleMap
            defaultZoom={15}
            defaultCenter={defaultCenter}
            options={{
              clickableIcons: false, // not interactue with icons
              // Hide POI (Maker of google maps)
              styles: [
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}>
            {listDisplays.length > 0 ? (
              listDisplays.map(display => {
                return (
                  <Marker
                    key={display.id}
                    icon={{
                      url: domain === "latinad" ? marker : markerWhiteLabel(),
                      size: { width: 25, height: 27 },
                      scaledSize: { width: 25, height: 27 },
                    }}
                    position={{
                      lat: parseFloat(display.latitude, 10),
                      lng: parseFloat(display.longitude, 10)
                    }}
                  />
                )
              })) :
              <Marker
                icon={{
                  url: domain === "latinad" ? marker : markerWhiteLabel(),
                  size: { width: 25, height: 27 },
                  scaledSize: { width: 25, height: 27 },
                }}
                position={defaultCenter}
              />
            }
          </GoogleMap>
        )
      );
    }
  }
);
function SampleArrow(props) {
  const { className, style, left, right, zIndex, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: left, right: right, top: "50%", zIndex: zIndex }}
      onClick={onClick}
    />
  );
}

class ScreenComponent extends Component {
  constructor(props) {
    super(props);
    const { display, displays, match, defaultTab, user } = this.props;
    var displayId = null;
    var displayValue = null;

    if (display) {
      displayValue = display;
    } else if (displays.length > 0) {
      displayId = parseInt(match.params.id, 10);
      displayValue = displays.find((d) => d.id === displayId);
    }

    //defaultTab : Select the tab depending on the button that opens it,
    //if it doesn't have one by default, it's the info tab.
    this.handleLanguageDescriptionChange = this.handleLanguageDescriptionChange.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.state = {
      display: display,
      cam_traffic: null,
      cam_display: null,
      day_count: null,
      difference_entrance_exit: null,
      translateDescription: null,
      audienceDatsWhy: null,
      keyTab: props.defaultTab ? tabs[props.defaultTab] : "1",
    };
  }

  componentDidMount() {
    // Is necessary for update value when open again modal
    const { keyTab } = this.state;
    if (keyTab == "5") {
      this.onChangeTab(keyTab);
    }
    let external_dsp = this.props.external_dsp_id ? parseInt(this.props.external_dsp_id) : null
    if (this.state.display == null) {
      // get view from url
      this.props.getDisplayInfoRequest(
        this.props.match.params.id,
        this.props.client.id,
        this.props.match.params.price_currency ? this.props.match.params.price_currency : (this.props.userCurrency ? this.props.userCurrency : this.props.initialCurrency),
        this.props.campaignInvoiceIssuingCountry ? this.props.campaignInvoiceIssuingCountry : (this.props.invoiceIssuingCountry || 'AR'),
        external_dsp
      );
    } else if (this.state.display.id !== this.props.currentDisplay.id) {
      this.props.getDisplayInfoRequest(
        this.state.display.id,
        this.props.client.id,
        this.state.display.price_currency,
        this.props.campaignInvoiceIssuingCountry ? this.props.campaignInvoiceIssuingCountry : (this.props.invoiceIssuingCountry || 'AR'),
        external_dsp
      );
    }
    this.setCamlyticsTokens();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultTab && (tabs[nextProps.defaultTab] !== this.state.keyTab)) {
      if (this.state.keyTab !== "6") {
        this.onChangeTab(tabs[nextProps.defaultTab]);
      }
    }
    if (this.props.fetchingDisplayInfo && !nextProps.fetchingDisplayInfo) {
      if (nextProps.errors && nextProps.errors[0]) {
        if (nextProps.errors[0] === 'No results for this model.') {
          this.props.history.push("/404");
        } else {
          this.props.history.push("/");
        }
      } else {
        this.setCamlyticsTokens(nextProps.currentDisplay);
      }
    }
    if ((nextProps.audienceDatsWhy && this.state.display) && (nextProps.audienceDatsWhy.id === this.state.display.id)) {
      if (this.state.audienceDatsWhy === null) {
        this.setState({
          audienceDatsWhy: { id: nextProps.audienceDatsWhy.id, data: nextProps.audienceDatsWhy.data },
        });
      }
    }
    if (this.props.userCurrency && (this.props.userCurrency !== nextProps.userCurrency)) {
      if (this.state.display == null) {
        // get view from url
        this.props.getDisplayInfoRequest(
          this.props.match.params.id,
          this.props.client.id,
          nextProps.userCurrency,
          this.props.campaignInvoiceIssuingCountry ? this.props.campaignInvoiceIssuingCountry : this.props.invoiceIssuingCountry,
        );
      } else if (this.state.display.id !== this.props.currentDisplay.id) {
        this.props.getDisplayInfoRequest(
          this.state.display.id,
          this.props.client.id,
          nextProps.userCurrency,
          this.props.campaignInvoiceIssuingCountry ? this.props.campaignInvoiceIssuingCountry : this.props.invoiceIssuingCountry,
        );
      }
    }
  }

  handleLanguageDescriptionChange(to) {
    if (this.state.translateDescription != null) {
      this.setState({ translateDescription: null });
    } else {
      this.setState({ translateDescription: to });
    }
  }

  setCamlyticsTokens(display) {
    // Set live view cam and traffic
    var baseLiveViewUrl = "https://cloud.camlytics.com/widget/live_view/";
    var baseReportUrl = "https://cloud.camlytics.com/widget/report/";
    if (
      (this.state.display && this.state.display.camlytics_tokens) ||
      (display && display.camlytics_tokens)
    ) {
      if (!this.state.display && display) {
        var camlyticsTokens = JSON.parse(display.camlytics_tokens);
      } else {
        var camlyticsTokens = JSON.parse(this.state.display.camlytics_tokens);
      }
      if (!camlyticsTokens) {
        return;
      }
      if (camlyticsTokens.cam_traffic) {
        this.setState({
          cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic + "?wt=1",
        });
      }
      if (camlyticsTokens.cam_display) {
        this.setState({
          cam_display: baseLiveViewUrl + camlyticsTokens.cam_display + "?wt=1",
        });
      }
      if (camlyticsTokens.day_count) {
        this.setState({
          day_count: baseReportUrl + camlyticsTokens.day_count + "?wt=1",
        });
      }
    }
  }

  onChangeTab = (key) => {
    if (this.state.keyTab === key) return;

    this.setState({ keyTab: key, });

    if (key === "5") {
      let displayId = this.state.display ? this.state.display.id : this.props.match.params.id;
      if (this.state.audienceDatsWhy == null || (this.props.audienceDatsWhy && this.props.audienceDatsWhy.id != displayId)) {
        this.props.getAudienceDatsWhyRequest(displayId);
      } else {
        this.props.setAudienceDatsWhy({ id: displayId, data: this.state.audienceDatsWhy.data });
      }
    }
  }

  addDisplayToCart = (display) => {
    if (!this.props.popup) {
      this.props.history.replace("/map?date_from=" + moment().format("YYYY-MM-DD") + "&date_to=" + moment().add(15, 'days').format("YYYY-MM-DD"));
      setTimeout(() => {
        this.props.addDisplayToCart(display);
      }, 1000);
    } else {
      this.props.addDisplayToCart(display);
    }
  }

  getLocation(item) {
    let titeLocation = item.country;
    titeLocation = item.administrative_area_level_1
      ? item.administrative_area_level_1 + ", " + titeLocation
      : titeLocation;
    titeLocation = item.administrative_area_level_2
      ? item.administrative_area_level_2 + ", " + titeLocation
      : titeLocation;
    return titeLocation;
  }

  render() {
    const { t, i18n, user, currentDisplay, fetchingDisplayInfo, editCampaignActive, currentCampaign, programmatic, listBundle } = this.props;
    const { cam_traffic, keyTab } = this.state;
    var { audienceDatsWhy, loadingAudienceDatsWhy } = this.props;
    var currencyView = this.props.match.params.price_currency ? this.props.match.params.price_currency : (this.props.userCurrency ? this.props.userCurrency : this.props.initialCurrency)
    var audienceDatsWhy = null
    if (this.state.audienceDatsWhy) {
      var audienceDatsWhy = this.state.audienceDatsWhy.data;
    } else {
      var audienceDatsWhy = this.props.audienceDatsWhy ? this.props.audienceDatsWhy.data : null;
    }
    let age = null;
    let gender = null;
    let se = null;
    let flow_data = null;
    if (audienceDatsWhy) {
      age = audienceDatsWhy.age ? audienceDatsWhy.age : null;
      gender = audienceDatsWhy.gender ? audienceDatsWhy.gender : null;
      se = audienceDatsWhy.se ? audienceDatsWhy.se : null;
      flow_data = audienceDatsWhy.flow_data ? audienceDatsWhy.flow_data : null;
    }
    let display = {
      pictures: [],
    };
    if (this.props.display) {
      display = this.state.display;
    } else {
      display = currentDisplay;
    }

    var settingsBundle = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      backgroundColor: "#fafafa",
      textAlign: "center",
      centerMode: display.bundles && display.bundles.length > 1 ? true : false,
      nextArrow: <SampleArrow left="auto" right="30px" zIndex="auto" />,
      prevArrow: <SampleArrow left="30px" right="auto" zIndex="1" />,
    };

    let onCart = null;
    onCart = this.props.cart.find((d) => d.id === display.id);

    const imageStyle = {
      filter: 'brightness(2) invert(1) sepia(100%) saturate(100%) hue-rotate(181deg)',
    };

    var settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      backgroundColor: "#fafafa",
      textAlign: "center",
      nextArrow: (
        <SampleArrow
          className="slick-detail"
          left="auto"
          right="20px"
          zIndex="auto"
        />
      ),
      prevArrow: <SampleArrow left="20px" right="auto" zIndex="1" />,
    };

    return (
      <>
        {(fetchingDisplayInfo == undefined || fetchingDisplayInfo) && this.state.display == null ? (
          <ScreenHolder>
            <Row
              className="screenContainer"
              type="flex"
              justify="center"
              align="middle"
              style={!this.props.popup ? { marginTop: "210px", marginLeft: "15px", marginRight: "15px" } : { marginTop: "35px" }}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card className="screenCard">
                  <div className="squeletorImage loading screenCardContent margin-card-image"></div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card className="screenCard">
                  <div className="screenCardContent margin-card-image">
                    <Skeleton active title paragraph={{ rows: 3 }} />
                    <Skeleton active title paragraph={{ rows: 1 }} />
                  </div>
                </Card>
                <Card className="screenCard">
                  <div className="screenCardContent margin-card-image">
                    <Skeleton active title paragraph={{ rows: 3 }} />
                  </div>
                </Card>
              </Col>
            </Row>
          </ScreenHolder>
        ) :
          <ScreenHolder>
            {!display.bundle_only ? (
              <Button className={!this.props.popup ? "addCartButton" : "addCartButton popup"} onClick={() => {
                if (onCart) {
                  this.props.removeDisplayFromCart(display.id);
                } else {
                  this.addDisplayToCart(display);
                }
                onCart = !onCart;
              }}>
                <Icon className="icon" type="shopping-cart" />
                {onCart ? t('Remove from cart') :
                  (this.props.popup ? t('Add to cart') : t('Go to buy'))}
              </Button>) : null}
            <Tabs
              activeKey={keyTab}
              className="tab-screen"
              onChange={this.onChangeTab}
              style={!this.props.popup ? { marginTop: "150px", marginLeft: "15px", marginRight: "15px" } : null}>
              <TabPane
                tab={
                  <span>
                    <Icon type="edit" />
                    {t('Details')}
                  </span>
                }
                key="1"
              >
                <Row
                  className="screenContainer"
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col xs={24} sm={24} md={this.props.popup ? 11 : 12} lg={this.props.popup ? 11 : 12} xl={this.props.popup ? 11 : 12}>
                    <Card className="screenCard">
                      <div className="screenCardContent margin-card-image">
                        <Slider {...settings}>
                          {!display.pictures || (display.pictures && display.pictures.length === 0) ? (
                            <div className="box-content">
                              <div className="box">
                                <img src={defaultImage} alt="Cargando imagen..." />
                              </div>
                            </div>
                          ) : (
                            display.pictures && display.pictures.map(p => (
                              <div key={p.id} className="box-content">
                                <div className="box">
                                  <img src={p.url} alt="Cargando imagen..." />
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                      {display.description ? (
                        <div className="screenCardContent contentDescription">
                          <div className="screenDescriptionContainer">
                            <span>
                              <h4>{t('Description')}</h4>
                              <GoogleTranslator
                                from=''
                                to={this.state.translateDescription}
                                text={display.description}
                              >
                              </GoogleTranslator>
                            </span>
                            <div className="selectTranslate">
                              <Button
                                className='backButtonTranslate'
                                onClick={() => {
                                  this.handleLanguageDescriptionChange(i18n.language)
                                }}
                              >
                                <FontAwesomeIcon icon={faLanguage} size="lg" className='iconTranslate' />
                                {this.state.translateDescription ? t('Show original') : t('Translate to') + " " + t(i18n.language)}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={this.props.popup ? 11 : 12} lg={this.props.popup ? 11 : 12} xl={this.props.popup ? 11 : 12}>
                    <PrincipalInformationScreenComponent
                      display={display}
                      programmatic={this.props.programmatic}
                      currency={currencyView}
                      user={user}
                      editCampaignActive={editCampaignActive}
                      currentCampaign={currentCampaign}
                    />
                    <Card className="screenCard">
                      <div className="screenCardContent" >
                        <Row>
                          <Col className="col-info" xs={24} sm={24} md={7} lg={7} xl={7}>
                            <div className="link-right">
                              <h3 className="title-col title-inherit">{t('Daily audience')}</h3>
                              <h3 className="number-col primary">
                                {fetchingDisplayInfo == undefined || fetchingDisplayInfo ? (<Icon type="loading" theme="outlined" />) : (currentDisplay && currentDisplay.audience && currentDisplay.audience.day != 0 ? SeparateNumberFormat(currentDisplay.audience.day) : null)}
                              </h3>
                            </div>
                          </Col>
                          <Col className="col-info" xs={24} sm={24} md={8} lg={8} xl={8}>
                            <div className="link-right">
                              <h3 className="title-col title-inherit">{t('Weekly audience')}</h3>
                              <h3 className="number-col primary">
                                {fetchingDisplayInfo == undefined || fetchingDisplayInfo ? (<Icon type="loading" theme="outlined" />) : (currentDisplay && currentDisplay.audience && currentDisplay.audience.week != 0 ? SeparateNumberFormat(currentDisplay.audience.week) : null)}</h3>
                            </div>
                          </Col>
                          <Col className="col-info" xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h3 className="title-col title-inherit">{t('Monthly audience')}</h3>
                            <h3 className="number-col primary">
                              {fetchingDisplayInfo == undefined || fetchingDisplayInfo ? (<Icon type="loading" theme="outlined" />) : (currentDisplay && currentDisplay.audience && currentDisplay.audience.month != 0 ? SeparateNumberFormat(currentDisplay.audience.month) : null)}</h3>
                          </Col>
                          {currentDisplay && currentDisplay.audience && currentDisplay.audience.day === 0 && !fetchingDisplayInfo ?
                            (<Col className="col-info" xs={24} sm={24} md={24} lg={24} xl={24}>
                              <h3 className="primary span-description" style={{ fontSize: "15px" }}>{t('There is no audience measurement configured')}</h3>
                            </Col>) : null
                          }
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {display.bundles && display.bundles.length > 0 ? (
                <TabPane
                  tab={
                    <span>
                      {
                        <Icon component={BundleSvg} style={keyTab != '7' ? imageStyle : {}} className="screenCardImage icon-bundle" />}
                      {t('Sales bundles')}
                    </span>
                  }
                  key="7"
                >
                  <Row
                    className="screenContainer"
                    type="flex"
                    justify="center"
                    align="middle"
                  >
                    <div className="titleModalBundle">
                      <span>
                        {t("This screen belongs to the following packages.")}<br />
                        {display.bundle_only ?
                          t("You can buy it only in a bundle (You will buy the entire bundle)") :
                          t("You can buy it individually or as a sales bundle (You will buy the entire bundle)")}
                      </span>
                    </div>
                  </Row>
                  <Row
                    className="screenContainer"
                    type="flex"
                    justify="center"
                    align="middle"
                  >
                    <Col xs={24} sm={24}>
                      <Slider {...settingsBundle}>
                        {display.bundles.map((bundle, index) => (
                          <div key={bundle.id}>
                            <BundleCardComponent
                              editCart={this.props.editCart}
                              closeModal={() => this.props.closeModal("close")}
                              bundleData={bundle}
                              programmatic={programmatic ? programmatic : null}
                              currency={currencyView}
                              popup={this.props.popup}
                              date_from={this.props.date_from}
                              date_to={this.props.date_to}
                              invoiceIssuingCountry={this.props.invoiceIssuingCountry}
                              client={this.props.client}
                              dsp={this.props.external_dsp_id ? parseInt(this.props.external_dsp_id) : null}
                              getDisplaysCart={this.props.getDisplaysCart}
                              cart={this.props.cart}
                              DisplayMap={DisplayMap}
                              listBundle={listBundle}
                              cleanAudience={this.props.cleanAudience}
                              removeDisplaysCart={this.props.removeDisplaysCart}
                              editCampaignActive={editCampaignActive}
                            >
                            </BundleCardComponent>
                          </div>))
                        }
                      </Slider>
                    </Col>
                  </Row>
                </TabPane>
              ) : null}
              <TabPane
                tab={
                  <span>
                    <Icon type="video-camera" />
                    {t('Live cameras')}
                  </span>
                }
                key="2"
              >
                <Row
                  className="screenContainer"
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="screenCard">
                      <div className="screenCardContent">
                        <h1 className="primary" style={{ textAlign: "justify" }}>{t('Traffic')}</h1>
                        {this.state.cam_traffic ? (
                          <iframe id="live-view-traffic" src={this.state.cam_traffic} frameBorder="0" scrolling="no" width="95%" height="350"></iframe>
                        ) : <p style={{ fontSize: "16px", textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>{t('You have not configured a camera pointing at traffic')}</p>}
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="screenCard">
                      <div className="screenCardContent" style={{ textAlign: "center" }}>
                        <h1 className="primary" style={{ textAlign: "justify" }}>{t('Traffic today')}
                          <Tooltip
                            placement="bottom"
                            title={`${t('Real-time vehicular traffic count')}`}>
                            <Icon type="info-circle" theme="outlined" style={{ fontSize: "18px", marginLeft: "7px", color: Colors.primary }} />
                          </Tooltip>
                        </h1>
                        {this.state.day_count && (!this.props.popup || (this.props.screenDetail && this.props.popup)) ? (
                          <iframe id="live-view-traffic" src={this.state.day_count} frameBorder="0" scrolling="no" width="95%" height="350"></iframe>
                        ) : <p style={{ fontSize: "16px", textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>{t('Not data')}</p>}
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row
                  className="screenContainer"
                  type="flex"
                  justify="start"
                  align="middle"
                >
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="screenCard">
                      <div className="screenCardContent">
                        <h1 className="primary" style={{ textTransform: 'capitalize' }}>{t('screen')}</h1>
                        {this.state.cam_display ? (
                          <iframe id="live-view-display" src={this.state.cam_display} frameBorder="0" scrolling="no" width="95%" height="350"></iframe>
                        ) : <p style={{ fontSize: "16px", textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>{t('You have not configured camera pointing at the screen')}</p>}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon type="environment" />
                    {t('Map')}
                  </span>
                }
                key="3"
              >
                <Row
                  className="screenContainer"
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                    <Card className="screenCard">
                      <DisplayMap
                        display={display}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                          <div
                            className="mapHeight"
                            style={{ height: `450px`, borderRadius: "10px" }}
                          />
                        }
                        mapElement={
                          <div style={{ height: `100%`, borderRadius: "10px" }} />
                        }
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card className="screenCard" style={{ height: `450px`, overflow: "scroll" }}>
                      <div className="screenCardContent span-description">
                        <h2>{display.name}</h2>
                        <Divider className="line-divider" style={{ marginBottom: "0px" }} />
                      </div>
                      {display.secondary_displays && display.secondary_displays.length > 0 ? (
                        <List
                          className="list-location"
                          itemLayout="horizontal"
                          dataSource={display.secondary_displays.filter(item => !item.archived)}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                                avatar={<Icon style={{ fontSize: "15px", marginRight: "5px" }} type="environment" />}
                                title={item.name}
                                description={this.getLocation(item)}
                              />
                            </List.Item>
                          )}
                        />
                      ) :
                        <div className="list-location" style={{ marginTop: "10px", marginBottom: "15px" }}>
                          <Icon style={{ fontSize: "15px", marginRight: "5px" }} type="environment" />
                          {display.name}&nbsp;
                          {this.getLocation(display)}
                        </div>
                      }
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {display.dats_why_poi_id !== null &&
                display.dats_why_poi_id !== 0 ? (
                <TabPane
                  tab={
                    <span>
                      <Icon type="eye-o" />
                      {t("Dats Why audience")}
                    </span>
                  }
                  key="5"
                >
                  {age && gender ?
                    <QualitativeAudienceComponent
                      age={age}
                      gender={gender}
                      se={se}
                      flow_data={flow_data}
                      loadingAudienceDatsWhy={loadingAudienceDatsWhy}
                    /> : null
                  }
                </TabPane>
              ) : null}
              <TabPane
                tab={
                  <span>
                    <Icon type="eye-o" />
                    {t("Audience")}
                  </span>
                }
                key="6"
              >
                {keyTab === "6" && (
                  <div>
                    <AudienceSummaryComponentGeneral
                      t={t}
                      display={display}
                      user={user}
                      campaign={editCampaignActive ? currentCampaign : null}
                      audienceHoursOfDay={this.props.audienceHoursOfDay}
                      audienceDaysOfWeek={this.props.audienceDaysOfWeek}
                      getAudienceHoursOfDayRequest={this.props.getAudienceHoursOfDayRequest}
                      getAudienceDaysOfWeekRequest={this.props.getAudienceDaysOfWeekRequest}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </ScreenHolder>
        }
      </>
    );
  }
}

ScreenComponent.contextTypes = {
  router: PropTypes.object
};

const Extended = withTranslation()(ScreenComponent);
Extended.static = ScreenComponent.static;

export default withRouter(Extended);
import React, { useState, useEffect, useMemo } from 'react';
import { Col, Input, Tooltip, Button, Modal, Spin, InputNumber, Icon, Switch, Row } from 'antd';
import ListRulesComponent from './ListRulesComponent';
import ImpressionsDetailsPopover from './ImpressionsDetailsPopover';
import RulesContainer from "../containers/RulesContainer";
import { useTranslation } from 'react-i18next';
import Rectangle from "react-rectangle";
import AudienceImpactsComponent from './AudienceImpactsComponent';
import { calculateValueByCpm, hasAccessRole} from "../services/utils";
import { getImpressionsToShowPerDay} from "../services/display";
import NumberFormat from "./NumberFormat";
import VideoPlayerComponent from "./VideoPlayerComponent"
import GenerateSkeletonComponent from './GenerateSkeletonComponent';
import ModalEditCampaignManagerComponent from "./ModalEditCampaignManagerComponent";
import UserRoleEnum from "../constants/UserRoleEnum";
import HideWhenCampaignActive from "../containers/HideWhenCampaignActiveContainer";
import ImpressionManagementComponent from "./ImpressionManagementComponent";
import SpecificationAlert from "./SpecificationAlert";
import CustomInputCurrency from "./customComponents/CustomInputCurrency/CustomInputCurrency"

function SliderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ right: "15px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{ left: "6px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

const RelationSliderComponent = (props) => {

  const {
    display,
    audience,
    Slider,
    customContents,
    reportSingleData,
    loadingSingleReport,
    currentCampaign,
    editCampaignActive,
    showModalDeleteRelation,
    toggleFillScreen,
    date_to,
    date_from,
    programmatic,
    currency,
    updateImpressionsDisplay,
    updateContentName,
    updateListOfContentsInCampaign,
    user,
    allDisplaysAreOwn,
    updateSubsidizedPrice,
    getAudienceDaysOfWeekRequest,
    applyRelationOnScreens,
    audienceHoursOfDay,
    audienceDaysOfWeek,
    getAudienceHoursOfDayRequest,
    cart,
    creditAvailableOnTransferredImpressions,
    updateCreditAvailable,
    isPaymentStatusApproved,
    setActive,
    setIsCampaignPaused,
    updatePromisedShowsSubsidized,
    cpmDisplay
    } = props

  const [activeSlides, setActiveSlides] = useState(0);
  const [totalPromisedShowsAllRelations , setTotalPromisedShowsAllRelations] = useState(0);
  const [indexOpen, setindexOpen] = useState(null);
  const [transferModalVisiblePreview, setTransferModalVisiblePreview] = useState(null);

  const {t}  = useTranslation();

  var sliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />
  };

  const getImagen = (relation, display) => {
    if(!relation.content) return null
    return (
      <div className="screenContainer">
        <Rectangle
            aspectRatio={[
              display.resolution_width,
              display.resolution_height
            ]}
          >
            {relation.content.type === 'image' ? (
              <div className="screen">
                <img
                  src={
                    relation.content && relation.content.ready
                      ? relation.content.file_thumb.replace(".mp4", "-00001.jpg")
                      : null
                  }
                  className={
                    relation.fill_screen
                      ? "screenPreviewFilled"
                      : "screenPreview"
                  }
                  alt=""
                />
              </div>
            ) : null}

            {relation.content && relation.content.ready && relation.content.type === 'video' ? (
              <VideoPlayerComponent relation={relation} />
            ): null}

            {relation.content && relation.content.id && !relation.content.ready ? (
              <div className="pulseLoaderContainer">
                <Spin size="large" spinning />
              </div>
            ) : null}
        </Rectangle>
        {(
          <SpecificationAlert
            programmatic={programmatic}
            slotLength={display.slot_length}
            contentLength={relation.content.length}
            contentType={relation.content.type}
            pricePerDay={display.price_per_day}
            formatSettings={display.format_settings}
          />
        )}
      </div>
    );
  }

  // Variable to show the modal of the contentDisplay when applying rules
  const showModalRules = (relation = null) => {
      setSelectRelationForRules(relation ? relation.relationId : null)
  }

  const [selectRelationForRules, setSelectRelationForRules] = useState(null);

  const updateImpressionsSubsidizeDisplay = (relationId, e) => {
    if (!(0 < e < 99999999)) e = 99999999
    updatePromisedShowsSubsidized(relationId, e, 'single')
  }

  /**
   * Calculates the number of shows based on the promised shows and the report data when the campaigns is running.
   * @param {Object} relation - The relation object containing `promised_shows` and `content.id`.
   * @param {Object} reportSingleData - The report data object containing an array of reports.
   * @param {Object} display - The display object containing `id`.
   * @returns {number} The calculated value of shows.
   */
  function calculateShows(relation) {
    // Check if reportSingleData and report exist
    if (reportSingleData && reportSingleData.report) {
      // Find the element matching the display.id and relation.content.id
      const foundElement = reportSingleData.report.find(element =>
        element.display === display.id && element.content === relation.content.id
      );

      // If the element is found, return its shows value subtracted from promised_shows
      if (foundElement) {
        return relation.promised_shows - foundElement.shows;
      }
    }

    // If the element is not found or there is no data, return promised_shows
    return relation.promised_shows;
  }

  /**
   * Calculates the maximum value for the InputNumber component.
   * @param {string} paymentStatus - The payment status of the current campaign.
   * @param {Object} relation - The relation object containing `relationId`.
   * @param {Object} reportSingleData - The report data object.
   * @returns {number} The calculated maximum value.
   */
  function calculateMax(relation) {
    let displayId = display.id
    let paymentStatus = currentCampaign ? currentCampaign.payment_status : null
    if (editCampaignActive && paymentStatus === 'approved') {
      const totalCustomContentShows = customContents
        .filter(customContent => customContent.displayId === displayId)
        .filter(customContentRelation => customContentRelation.relationId !== relation.relationId)
        .reduce((sum, customContentRelation) => sum + ( customContentRelation.promised_shows_update_in_transfer ?
         customContentRelation.promised_shows_update_in_transfer : customContentRelation.promised_shows), 0);

      const reportShows = reportSingleData && reportSingleData.report && reportSingleData.report.find(element => element.display === displayId && element.content === relation.content.id)
        ? reportSingleData.report.find(element => element.display === displayId && element.content === relation.content.id).shows
        : 0;

      return totalPromisedShowsAllRelations - totalCustomContentShows - reportShows;
    }

    return 999999999; // Arbitrary large number as a fallback maximum value
  }

  const handleContent = (value, content) => {
    let updatedContent = { ...content, name: value };
    updateListOfContentsInCampaign(updatedContent)
    let relations = customContents.filter(customContent => customContent.content.id === content.id);
    relations.forEach(relation => {
        if(relation){
            updateContentName(relation.relationId, content, value, 'single');
        }
    });

  }

  /**
   * Handles the change event for the InputNumber component.
   *
   * @param {number} newValue - The new value from the InputNumber component.
   * @param {Function} updateImpressionsDisplay - The function to update impressions display.
   * @param {string} displayId - The ID of the display.
   * @param {Object} relation - The relation object.
   * @param {Object} reportSingleData - The report data object.
   */
  function handleChange(newValue, relation) {
    let displayId = display.id
    const additionalShows = (reportSingleData && reportSingleData["report"])
      ? (reportSingleData["report"].find(
          element => element["display"] === displayId && element["content"] === relation["content"]["id"]) || {})["shows"] || 0
      : 0;

    updateImpressionsDisplay(displayId, relation.relationId, newValue + additionalShows);
  }

  // Update proposal of relations
 function handleChangeProposal(e, relation, ePrev) {
    if(e === ePrev ) return;
    let displayId = display.id
    const cpm = parseFloat((cpmDisplay / 1000));
    let impresions = Math.floor(e / cpm);
    if (relation.promised_shows === impresions ) impresions++;
    updateImpressionsDisplay(displayId, relation.relationId, impresions);
  }

  const updateSubsidizedPriceRelation = (relationId) => {
      const isSubsidized = relations.filter(item => item.relationId === relationId).every(item => !item.subsidized_price);
      updateSubsidizedPrice(relationId, isSubsidized, 'relation')
  };

  const handleIsCampaignsPausedToggle = (isCampaignPaused, relation) => {
    const active = isCampaignPaused ? 0 : 1;

    if(!isCampaignPaused) setIsCampaignPaused(false);
    setActive(relation.relationId, active)
  }

  useEffect(() => {
    setTotalPromisedShowsAllRelations(customContents
      .filter(customContent => customContent.displayId === display.id)
      .reduce((sum, customContent) => sum + customContent.promised_shows, 0));
  }, []);


  const updateActiveSlide = (currentSlide) => {
    setActiveSlides(currentSlide);
  };

  var sliderSettingsInstance = { ...sliderSettings, afterChange: current => updateActiveSlide(current) };

  const relations = useMemo(() => {
    return customContents.filter(item => item.displayId === display.id);
  }, [customContents]);

  if (relations.length === 0) return null;

  return (
    <Slider {...sliderSettingsInstance}>
      {relations.map((relation, index) => {
        const spotValue = Math.floor(parseFloat(calculateValueByCpm(cpmDisplay, relation.promised_shows, "calculate_price")) * 100) / 100 || 0;
        return (
          <React.Fragment key={relation.relationId}>
            <div className='content-slider-displays' style={{marginTop:"15px"}}>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                {activeSlides >= 0 && activeSlides < relations.length && getImagen(relation, display)}
                <div style={{textAlign: "center"}}>
                  <AudienceImpactsComponent
                    relationId={relation.relationId}
                    type="relation"
                    audience={audience}
                    audienceHoursOfDay = {audienceHoursOfDay}
                    audienceDaysOfWeek = {audienceDaysOfWeek}
                    getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
                    getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={15} xl={15} style={{ paddingRight: "15px" }}>
                <div className="inline-container">
                  <span className="inline-text">{t("Ad name")}</span>
                  <Input
                    type="text"
                    value={relation.content.name}
                    onChange={e => handleContent(e.target.value, relation.content)}
                  />
                  <span className={"primaryCircle"} >
                    {relation.content.content_version_name || "V1"}
                  </span>
                </div>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} style={{ textAlign: "center", paddingRight: "10px" }}>
                  <div style={{ marginTop: "5px" }}>
                    <ListRulesComponent
                      listContentDisplay={[relation]}
                      styleRules="center"
                      defaultText={t('There are no rule for this spot')}
                    />
                  </div>
                </Col>
                { programmatic === 'programmatic' &&
                  <Col xs={24} sm={24} md={24} lg={11} xl={15} style={{ textAlign: "center"}}>
                    <div style={{ display: "flex"}}>
                      <div style={{ width: "90%"}}>
                        { loadingSingleReport ?
                          <GenerateSkeletonComponent count={1} height={5} width={100} />:
                          <>
                            <div className='textGroup'>{t("Impressions")}</div>
                            <InputNumber
                              type="number"
                              disabled={isPaymentStatusApproved}
                              className='inputGroup'
                              style={{ marginBottom: "10px"}}
                              min={0}
                              value={calculateShows(relation)}
                              onChange={e => handleChange(e, relation)}
                            />
                          </>
                        }
                        <div className="content-info-relation content-info">
                          { getImpressionsToShowPerDay(date_from, date_to, relation.rules) === 0 ? 0 : Math.round(relation.promised_shows / getImpressionsToShowPerDay (date_from, date_to, relation.rules))} {t("spots/day") }
                        </div>
                      </div>
                      <div style={{ marginBottom: "16px" }}>
                        {t("Investment")}
                        <div style={{ marginBottom: "10px"}}>
                          <CustomInputCurrency
                            currency= { editCampaignActive ? currentCampaign.currency : currency }
                            value={spotValue}
                            onChange={e => handleChangeProposal(e, relation, spotValue)}
                            disabled={isPaymentStatusApproved}
                            step={(cpmDisplay / 1000).toFixed(2)}
                          />
                        </div>
                        <div
                          className="content-info content-info-relation"
                          style={{paddingRight: "16px", paddingLeft: "16px"}}>
                          <Tooltip
                            placement="right"
                            title= {t('(CPM) cost per thousand impression')}>
                            <NumberFormat
                              value ={display.cpmi}
                              currency={currency}
                            /> {t("CPM")}
                            </Tooltip>
                        </div>
                      </div>
                    </div>
                    {!!relation.promised_shows_subsidized &&
                      <div className="content-info orange content-info-relation appear-animation-without-movement" style={{paddingRight: "16px", paddingLeft: "16px", float: "left", margin: 0}}>
                        {Math.round(relation.promised_shows_subsidized / getImpressionsToShowPerDay(date_from, date_to, relation.rules))} imp {t('subsidized')}/{t('day')}
                      </div>
                    }
                    <div style={{ marginTop: '10px' }}>
                      <ImpressionsDetailsPopover
                        reportSingleData={reportSingleData}
                        customContents={customContents}
                        display={display}
                        relation={relation}
                        currentCampaign={currentCampaign}
                        editCampaignActive={editCampaignActive}
                      />
                    </div>
                  </Col>
                }
              </Col>
              <Col xs={24} sm={24} md={24} lg={2} xl={2} style={{ textAlign: "center" }}>
                <Tooltip title={t('Set up rules in this spot')}>
                  <Button
                    shape="circle"
                    className='buttonActionsGroup'
                    onClick={() => showModalRules(relation)}
                  >
                    <Icon type="control" />
                  </Button>
                </Tooltip>
                <HideWhenCampaignActive contentId={relation.content.id} displayId={display.id} >
                  {relations.length > 1 && (
                    <Tooltip placement="top" title={t('Remove spot')}>
                      <Button
                        shape="circle"
                        className='buttonActionsGroup'
                        onClick={() => showModalDeleteRelation('deleteRelation', display.id, relation.relationId)}
                      >
                        <Icon type="close-square" />
                      </Button>
                    </Tooltip>
                  )}
                </HideWhenCampaignActive>
                <Tooltip title={!relation.fill_screen ? t('Expand ad') : t('Return original resolution')}>
                  <Button
                    shape="circle"
                    className='buttonActionsGroup'
                    onClick={() => toggleFillScreen(relation.displayId, relation.relationId, !relation.fill_screen, 'single')}
                  >
                    {!relation.fill_screen ? <Icon type="fullscreen" /> : <Icon type="fullscreen-exit" />}
                  </Button>
                </Tooltip>
                { editCampaignActive && currentCampaign.payment_status === 'approved' && (
                    <Tooltip placement="top" title={`${t("Transfer of spots")}`}>
                      <Button
                        shape="circle"
                        className='buttonActionsGroup'
                        onClick={() => setTransferModalVisiblePreview(relation.relationId)}
                      >
                        <Icon type="transaction"/>
                      </Button>
                    </Tooltip>
                  )
                }
              </Col>
              {user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn) && (
                <Col xs={24} className="lastRow">
                  <Row type="flex" justify="end" align="middle" style={{ gap: 10 }}>
                    {t('Subsidize impression')}
                    <InputNumber
                      type="number"
                      min={0}
                      max={999999999}
                      size="small"
                      value={relation.promised_shows_subsidized}
                      onChange={e => updateImpressionsSubsidizeDisplay(relation.relationId,e)}
                    />
                    <Tooltip placement="bottomLeft" title={t('These impressions are reflected as subsidize in the campaign')}>
                      <Icon
                        style={{ fontSize: "20px",color: "#f7d455", verticalAlign: "middle" }}
                        type="warning"
                      />
                    </Tooltip>
                  </Row>
                </Col>
              )}
              {programmatic === 'programmatic' && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="lastRow" style={{gap: 20}}>
                  <div>
                    {!editCampaignActive ?
                        <>
                          {(user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) ? (
                              <div style={{ marginBottom: 10, marginTop: 10 }}>
                                  {t('Subsidize')}
                                  <Switch
                                    checked = {relations.filter(item => item.relationId === relation.relationId).every(item => item.subsidized_price)}
                                    onClick={() => updateSubsidizedPriceRelation(relation.relationId)}
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                  />
                                <Tooltip placement="bottomLeft" title={t('By enabling this option, the screen is subsidize, affecting the price of the campaign')}>
                                  <Icon
                                    style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                    type="warning"
                                  />
                                </Tooltip>
                              </div>
                          ) : ( null ) }
                        </>:<>
                            { relations.filter(item => item.relationId === relation.relationId).every(item => item.subsidized_price) ?
                              ( display.promised_shows != 0 ?
                                <span className="ant-tag ant-tag-orange tag-status-display">
                                  {t('Subsidized')}
                                </span>
                                : // When the user changes the value to zero.
                                <span className="ant-tag ant-tag-blue tag-status-display">
                                    {t('Suspended')}
                                </span>)
                            :null }
                        </>
                      }
                  </div>
                  {relation.indice_relation === null &&
                      ( editCampaignActive
                        && currentCampaign.payment_status !== 'pending'
                        && currentCampaign.payment_status !== 'cancelled'
                        && user
                        && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn))?
                        (
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                      <span>
                        {t('Pause content')}
                        &emsp;
                        <Switch
                          onChange={(value) => handleIsCampaignsPausedToggle(value, relation)}
                          checked={relation.active === 0 ? true : false}
                          size="small"
                        />
                      </span>
                    </div> 
                  ): null} 
                </Col>
              )}
            </div>
            {selectRelationForRules === relation.relationId && (
              <Modal
                key={relation.relationId}
                title={t("Create rules")}
                visible={selectRelationForRules === relation.relationId}
                footer={[
                  <Button key="ok" type="primary" onClick={() => showModalRules()}>
                    {t("Close")}
                  </Button>,
                ]}
                onCancel={() => showModalRules()}
                width={800}
              >
                <RulesContainer
                  key={display.id}
                  typeModal="contentDisplay"
                  listContentDisplay={[relation]}
                />
              </Modal>
            )}
            {indexOpen === index &&
              <ModalEditCampaignManagerComponent
                visible={indexOpen === index}
                onCancel={() => setindexOpen(null)}
                applyRelationOnScreens={applyRelationOnScreens}
                groupedDisplays={[display]}
                groupName={relation.content.name}
                // filterByContent={relation.id}
              />
            }
            { transferModalVisiblePreview === relation.relationId && (
              <Modal
                  key={relation.relationId}
                  title={t("Transfer of spots")}
                  onCancel={() => setTransferModalVisiblePreview(null)}
                  visible={true}
                  footer={null}
                  width={1200}
                  style={{ top: "10px" }}
                >
                  <ImpressionManagementComponent
                    currentCampaign = {currentCampaign}
                    customContents = {customContents}
                    companySelected = {display.company ? display.company : null}
                    reportSingleData = {reportSingleData}
                    transferModalVisible = {transferModalVisiblePreview}
                    cart= {cart}
                    showTransferModal = {setTransferModalVisiblePreview}
                    updateImpressionsDisplay = {updateImpressionsDisplay}
                    creditAvailableOnTransferredImpressions = {creditAvailableOnTransferredImpressions}
                    updateCreditAvailable= {updateCreditAvailable}
                  />
                </Modal>
            )}
          </React.Fragment>
        )})}
    </Slider>
  );
};

export default RelationSliderComponent;
import styled from "styled-components";
import Colors from "../../themes/Colors";

const ContentsLibraryHolder = styled.div`
  .selectTag {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }

  .select {
    padding: 5px 0 5px 12px;
  }
  .pagination {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .contentCard {
    margin-top: 20px;
  }

  .cardContent {
    padding: 12px 12px 8px 12px;
    gap: 7px;
    border-radius: 4px;
    width: min-content;
  }

  .ant-card-body {
    padding: 0px;
  }
  .textCard {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-wrap: nowrap;
    color: #000000;
  }
  .contentCardImage {
    width: 115px;
    height: 70px;
    border-radius: 2px;
    object-fit: scale-down;
  }
  .centerCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .contentCardContent {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .icon-size svg {
    font-size: 24px !important;
  }
  .icon-size p {
    line-height: 1 !important;
    margin-bottom: 0 !important;
    margin-right: 10px !important;
  }
  .icon-card {
    width: 26px;
    height: 32px;
  }
  .icon-card svg {
    font-size: 16px;
  }
  .circle-div {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  .contentCard {
    flex-wrap: nowrap;
    gap: 13px;
  }
  .cardUpload {
    background: #d9d9d900;
  }
  .cardGreen {
    background: #EBF8F2;
  }
  .cardGreen svg{
    color: #7CB354;
  }
  .cardBlue {
    background: #EBF5FE;
  }
  .cardBlue svg {
    color: #2E78C2;
  }
  .cardRed {
    background: #FDEAEE;
  }
  .cardRed svg {
    color: #CD2749;
  }
  .cardUploadContent {
    width: auto;
    height: 140px;
    border-radius: 2px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d9d9d957;
  }
  .cardUploadContent svg {
    font-size: xx-large;
    color: #888888;
    background: #aaa0;
  }
  .libCard {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .contentVideoUpload {
    display: flex;
    align-items: center;
  }
  .disabledVideoUpload {
    pointer-events: none;
    opacity: 0.6;
  }
  .msgAlertLib {
    color: ${Colors.atention};
    font-size: 10px;
  }
  .contentVideoUpload .ant-upload{
    margin: 0;
  }
`;

export default ContentsLibraryHolder;
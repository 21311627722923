import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
// Traslate text if to is null not translate
// ej {to} es or en

var format = {};

//"tax" add (tax incl)
// example tax==true : $10 + IVA
//"tax_include" add (tax incluido)
// example tax_include==true : $10 tax incluido
//"currency" define type of currency

function NumberFormat({value, tax, tax_include, currency, t}) {

  const [format, setFormat] = useState(
    new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    })
  );

  useEffect(() => {
    setFormat(
      new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
      })
    );
  },[currency]);

  return (
    <>
      {currency && currency === "ARS" }{format.format(value)}&nbsp;
      <span style={{ fontSize: "10px" }}>
        {tax_include ? (
          <span style={{ fontSize: "11px" }}> {t('tax included')}</span>
        ) : (
          <>
            {tax ? t('+tax') : null}
          </>
        )}
      </span>
    </>
  );
}
NumberFormat.propTypes = {
    value: PropTypes.number,
    currency: PropTypes.string.isRequired,
}

export default withTranslation()(NumberFormat);
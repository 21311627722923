import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreensCollectionHolder = styled.div`
  .Screens-collection {
    margin-top: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }

  .Screens-collection .collection{
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }

   .Screens-collection .title-collection {
    align-items: center;
    gap: 10px;
  }

  .margin-text {
    margin-left: 5px;
    margin-right: 5px;
  }
  .iconCompany {
    color: #1890ff;
  }

  .amountCampaignPerDisplay {
    border: solid;
    border-radius: 7px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .proposalGroup {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 45%;
  }
  .textGroup {
    color: ${Colors.primary};
    margin-right: 5px
  }
  `;

export default ScreensCollectionHolder;
import { connect } from 'react-redux'
import HomeComponent from '../components/HomeComponent'
import DisplayActions from "../redux/DisplayRedux";
import UserAccountActions from "../redux/UserAccountRedux"

const mapStateToProps = ({ display, userAccount  }) => ({
  user: userAccount.user,
  programmatic: display.programmatic,
  amountBudget: display.amountBudget,
  audience: display.audience,
  shouldPromptLogin: userAccount.shouldPromptLogin,
})

const mapStateToDispatch = (dispatch) => ({
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
  loginFromCms: (token) => dispatch(UserAccountActions.userLoginFromCmsRequest(token)),
  logout: () => dispatch(UserAccountActions.userLogout()),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  userLoginFailure: (error) => dispatch(UserAccountActions.userLoginFailure(error)),
  setInitWithToken: (loginInfo) => dispatch(UserAccountActions.setInitWithToken(loginInfo)),
})

export default connect(mapStateToProps, mapStateToDispatch)(HomeComponent)

import { connect } from 'react-redux'
import ScreensGroupComponent from '../components/ScreensGroupComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount}) => ({
  token: display.token,
  cart: display.cart,
  uploadingFile: display.uploadingFile,
  audience : display.audience,
  user: userAccount.user,
  displays: display.displays,
  listResolutionWithContent: display.listResolutionWithContent,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  customContents: display.customContents,
  programmatic: display.programmatic,
  editCampaignActive: display.editCampaignActive,
  isPendingCurrentCampaign: display.isPendingCurrentCampaign,
  campaignPending: display.campaignPending,
  managerDisplayFilter: display.managerDisplayFilter,
  disabledManagerDisplayFilter: display.disabledManagerDisplayFilter,
  date_from: display.date_from,
  date_to: display.date_to,
  currency: userAccount.currency,
  currentCampaign: display.currentCampaign,
  loadingSingleReport: display.loadingSingleReport,
  audienceHoursOfDay: display.audienceHoursOfDay,
  audienceDaysOfWeek: display.audienceDaysOfWeek,
  reportSingleData: display.reportSingleData,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
})

const mapStateToDispatch = (dispatch) => ({
  updateImpressionsDisplay: (displayId, relationId, impressions, promised_shows_update_in_transfer, add_max_in_transfer) => dispatch(DisplayActions.updateImpressionsDisplay(displayId, relationId, impressions, promised_shows_update_in_transfer, add_max_in_transfer)),
  removeRelationsOnCampaign: (displayId, contentId) => dispatch(DisplayActions.removeRelationsOnCampaign(displayId, contentId)),
  toggleFillScreen: (displayId, relationId, value, typePreview) => dispatch(DisplayActions.toggleFillScreen(displayId, relationId, value, typePreview)),
  onChangeContent: (contentId, displayId, relationId, typePreview) => dispatch(DisplayActions.onChangeContent(contentId, displayId, relationId, typePreview)),
  removeDisplayOnCampaign: (displayId) => dispatch(DisplayActions.removeDisplayOnCampaign(displayId)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  disableManagerDisplayFilter: (disabledManagerDisplayFilter) => dispatch(DisplayActions.disableManagerDisplayFilter(disabledManagerDisplayFilter)),
  addRelationDisplay: (displayId, relationId, impressions) => dispatch(DisplayActions.addRelationDisplay(displayId, relationId, impressions)),
  updateContentName: (relationId, content, value, typePreview) => dispatch(DisplayActions.updateFieldInContent(relationId, content, value, "content_name", typePreview)),
  updateListOfContentsInCampaign: (listOfContentsInCampaign) => dispatch(DisplayActions.updateListOfContentsInCampaign(listOfContentsInCampaign)),
  updateSubsidizedPrice: (id, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(id, value, "subsidized_price", typePreview)),
  getAudienceHoursOfDayRequest: (params) => dispatch(DisplayActions.getAudienceHoursOfDayRequest(params)),
  getAudienceDaysOfWeekRequest: (params) => dispatch(DisplayActions.getAudienceDaysOfWeekRequest(params)),
  setActive: (relationId, value) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "active", "single")),
  setIsCampaignPaused: isCampaignPaused => dispatch(DisplayActions.setIsCampaignPause(isCampaignPaused)),
  updatePromisedShowsSubsidized: (relationId, value, typePreview) => dispatch(DisplayActions.updateFieldInCustomContents(relationId, value, "promised_shows_subsidized", typePreview)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreensGroupComponent)
import React, { useState, useEffect } from 'react';
import { InputNumber } from "antd";
import "./currencyInput.css";

const CustomInputCurrency = ({ currency, value, onChange, disabled, step, style }) => {

  const handleInputChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <div className="currency-input-container">
      <span className="currency-symbol">${currency}</span>
      <InputNumber
        disabled={disabled}
        className="inputGroup"
        min={0}
        value={value || 0}
        onChange={handleInputChange}
        step={step || 1}
        parser={(value) => Number(value.replace(',', '.'))}
        style={style}
      />
    </div>
  );
};

export default CustomInputCurrency;


import { connect } from 'react-redux'
import ContentsLibraryComponent from '../components/ContentsLibraryComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  user: userAccount.user,
  currency: userAccount.currency,
  libraryContents: display.libraryContents,
  loadingContentsbyCompany: display.loadingContentsbyCompany,
  token: userAccount.token,
  listOfContentsInCampaign: display.listOfContentsInCampaign,
  defaultContent: display.defaultContent,
  customContents: display.customContents,
  isSuperUser: userAccount.isSuperUser,
  brand: display.brand,
  fileCanvaNew: display.fileNew,
  currentCampaign: display.currentCampaign,
  statusNewContent: display.statusNewContent,
})

const mapStateToDispatch = (dispatch) => ({
  remplaceContentFromCampaign: (contentId, contentToRemplace) => dispatch(DisplayActions.remplaceContentFromCampaign(contentId, contentToRemplace)),
  remplaceContentFromRelation: (contentId, displayId, contentToReplace) => dispatch(DisplayActions.remplaceContentFromRelation(contentId, displayId, contentToReplace)),
  getContentsByCompany : (companyId, params) => dispatch(DisplayActions.getContentsByCompanyRequest(companyId, params)),
  setLibraryContents : (contents) => dispatch(DisplayActions.setLibraryContents(contents)),
  setListOfContentsInCampaign: (contents) => dispatch(DisplayActions.setListOfContentsInCampaign(contents)),
  cleanListOfContentsInCampaign: () => dispatch(DisplayActions.cleanListOfContentsInCampaign()),
  updateDefaultContentRequest: id => dispatch(DisplayActions.updateDefaultContentRequest(id)),
  updateCustomContents: customContents => dispatch(DisplayActions.updateCustomContents(customContents)),
  setListResolutionWithContent: listResolutionWithContent => dispatch(DisplayActions.setListResolutionWithContent(listResolutionWithContent)),
  updateContentCampaignSuccess: (contentId, displayId, content) => dispatch(DisplayActions.updateContentCampaignSuccess(contentId, displayId, content)),
  updateRelationCampaignRequest: (content, relation, campaignId) => dispatch(DisplayActions.updateRelationCampaignRequest(content, relation, campaignId)),
  updateContentDisplayByContent: (content, displayId) => dispatch(DisplayActions.updateContentDisplayByContent(content, displayId)),
  setStatusNewContent: (statusNewContent) => dispatch(DisplayActions.setStatusNewContent(statusNewContent)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ContentsLibraryComponent)
import React from "react";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;


export function removeContentExtensions (filename) {
    return filename.replace(/\.(png|jpg|mp4)$/i, '');
}

export function createNameNewContent (client, customContents, displays=[]) {
    // customContents.length === 0 is when load the first content
    let contCustomContents = customContents.length === 0 ? displays.length : customContents.length;

    // displays=[] is when add content to new realation
    contCustomContents = displays.length === 0 ? contCustomContents + 1 : contCustomContents;
    let name = (client ? client.name : "Contenido") + "-" + contCustomContents;
    return name;
}

export function uploadContent(t, message, token, setStatusNewContent, setLoading, tag=null) {
    const tagId = tag ? tag.key : null;
    const uploadProps = {
      name:"file",
      listType:"picture-card",
      className:"avatar-uploader",
      showUploadList:false,
      accept: "video/*,image/*,video/mp4,video/x-m4v",
      action: `${apiUrl}/contents`,
      multiple: true,
      data: file => ({
        origin: "dsp",
        name: removeContentExtensions(file.name),
        tags: [tagId]
      }),
      beforeUpload: async (file, _fileList) => {
        // setStatusNewContent(null);
        setLoading(true);
        return new Promise((resolve, reject) => {
          // Check size
          const isLt50M = file.size / 1024 / 1024 < 50;
          if (!isLt50M) {
            message.error(t('The file cannot be larger than 50MB!'), 10);
            reject();
          }
          // Check resolution
          const fileType = file.type;
          const maxResolution = 10000;
          // Check image resolution
          if (fileType.startsWith('image/')) {
            const img = new Image();
            img.onload = function () {
              const width = img.width;
              const height = img.height;
              if (width > maxResolution || height > maxResolution) {
                message.error(t('The resolution can not be larger than 10000px'), 10);
                reject();
              } else {
                resolve(file);
              }
            };
            img.src = URL.createObjectURL(file);
          } else if (fileType.startsWith('video/')) {
            const video = document.createElement('video');
            video.onloadedmetadata = function () {
              const width = video.videoWidth;
              const height = video.videoHeight;
              if (width > maxResolution || height > maxResolution) {
                message.error(t('The resolution can not be larger than 10000px'), 10);
                reject();
              } else {
                resolve(file);
              }
            };
            video.src = URL.createObjectURL(file);
          }
          setLoading(false);
        });
      },
      headers: { authorization: `Bearer ${token}` },
      onChange: async info => {
        setLoading(true);
        const status = info.file.status;
        if (info.fileList.every(file => file.response)) {
          setLoading(false);
          setStatusNewContent(info);
        } else if (status === "error") {
          message.error(`${t('Upload error')} ${info.file.name}`);
          setLoading(false);
        }
      }
    };

  return uploadProps;
}

export function costPerSpots(cpm, contentsDisplay) {
  let spots = 0;
  let investment = 0;
  contentsDisplay =  Array.isArray(contentsDisplay) ? contentsDisplay : [contentsDisplay];

  contentsDisplay.forEach(content => {
    spots = spots + content.promised_shows;
    investment = investment + (spots  * (cpm / 1000))
  });

  return investment / spots;
};

export function convertImage(file_thumb) {
  if(!file_thumb) return ""
  let ext = file_thumb.split(".").pop().toLowerCase();
  if (ext === 'jpg' || ext === 'png' || ext === 'gif') {
    return file_thumb;
  }

  let name = file_thumb.split(".").slice(0, -1).join(".");
  if(name === "") return name

  return name + "-00001.jpg";
}

export function aspectRatiosMatch( contentWidth, contentHeight, screenWidth, screenHeight, toleranceResolution ) {
      // Calculate the aspect ratios of the content and the screen
      const contentAspectRatio = contentWidth / contentHeight;
      const screenAspectRatio = screenWidth / screenHeight;

      let blackBarsPercentage = 0;

      // Adjust and calculate the black bars percentage
      if (contentAspectRatio > screenAspectRatio) {
          // The content is wider in proportion; adjust height
          const adjustedHeight = screenWidth / contentAspectRatio;
          const blackBarsHeight = screenHeight - adjustedHeight;
          blackBarsPercentage = (blackBarsHeight / screenHeight) * 100; // Percentage of black bars in height
      } else if (contentAspectRatio < screenAspectRatio) {
          // The content is taller in proportion; adjust width
          const adjustedWidth = screenHeight * contentAspectRatio;
          const blackBarsWidth = screenWidth - adjustedWidth;
          blackBarsPercentage = (blackBarsWidth / screenWidth) * 100; // Percentage of black bars in width
      } else {
          // Aspect ratios are equal; no black bars
          blackBarsPercentage = 0;
      }

      // Determine if the black bars percentage is within the allowed tolerance
      const isMatch = blackBarsPercentage <= toleranceResolution;

      // Return the result and the absolute difference between aspect ratios
      return [isMatch, Math.abs(contentAspectRatio - screenAspectRatio)];
  }
import React, { useEffect, useState } from 'react';
import { CircleFlag } from "react-circle-flags";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ScreensGroupContainer from "../containers/ScreensGroupContainer";
import ScreensCollectionHolder from "./Styles/screensCollectionStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import {calculeValuesPerGroup} from "../services/utils";
import CustomInputCurrency from "./customComponents/CustomInputCurrency/CustomInputCurrency"

const { Title } = Typography;

const ScreensCollectionComponent = (props) => {
  const { t } = useTranslation();

  const [displaysCollections, setDisplaysCollections] = useState([]);

  const {
    cart,
    managerDisplaySelector,
    disabledManagerDisplayFilter,
    applyRelationOnScreens ,
    allDisplaysAreOwn,
    creditAvailableOnTransferredImpressions,
    updateCreditAvailable,
    programmatic,
    customContents,
    currency,
    audience,
    isPaymentStatusApproved,
    editCampaignActive,
    currentCampaign,
    updateCustomContents
  } = props;

  const getFlag = (collection) => {
    const firstDisplay = collection.displays[0];
    const countryAbbreviation = firstDisplay && firstDisplay.country_iso && firstDisplay.country_iso.toLowerCase();

    return countryAbbreviation;
  }

  const groupByCountry = () => {
    let result =  Object.values(cart.reduce((acc, display) => {
      const country = display.country || "Not assigned";
      const value = parseFloat(display.smart_campaign_cpm / 1000);
      const maxCpm = (Math.floor(value * 100) + 1) / 100 || 0 ;

      if (!acc[country]) {
        acc[country] = {
          key: country,
          displays: [],
          maxCpm: maxCpm };
      }

      acc[country].displays.push(display);
      acc[country].maxCpm = Math.max(acc[country].maxCpm, maxCpm);
      return acc;
    }, {}));

    if (programmatic === "programmatic") {
      result = genereInvestmentGroup(result);
    }
    return result;
  };

  const groupByCompany = () => {
    let result = Object.values(
      cart.reduce((acc, display) => {
        const company_id = display.company_id || "Not assigned";
        const value = parseFloat(display.smart_campaign_cpm / 1000);
        const maxCpm = (Math.floor(value * 100) + 1) / 100 || 0;

        if (!acc[company_id]) {
          acc[company_id] = {
            key: display.company.name,
            displays: [],
            maxCpm: maxCpm
          };
        }

        acc[company_id].displays.push(display);
        acc[company_id].maxCpm = Math.max(acc[company_id].maxCpm, maxCpm);

        return acc;
      }, {})
    );

    if (programmatic === "programmatic") {
      result = genereInvestmentGroup(result);
    }

    return result;
  };


  const genereInvestmentGroup = (result) => {
    result = result.map((group) => {
      let investmentGroup
      const option = editCampaignActive ? { currentCampaign: currentCampaign , calculateImpacts: false }: {calculateImpacts: false };
      [ investmentGroup ] = calculeValuesPerGroup(group, customContents, audience, option);
      return { ...group, investmentGroup };
    });
    return result
  }

  const handleChangeGroupProposal = (e, displays, ePrev) => {
    if (e == ePrev) return;

    const newProposal = e / displays.length;
    let newCustomContent = [...customContents];
    let remainingImpressions = 0;  // Variable to store the decimal remainder of impressions

    displays.forEach(display => {
      const cpmDisplay = display.smart_campaign_cpm;
      const cpm = (cpmDisplay / 1000);

      const filterCustomContent = newCustomContent.filter(content => content.displayId === display.id);
      if (filterCustomContent.length === 0 || cpm === 0) return;

      // Add the decimal remainder to the new proposal calculation
      let totalImpressions = (newProposal + remainingImpressions) / filterCustomContent.length;
      let impressions = Math.floor(totalImpressions / cpm);

      // Calculate the decimal remainder (the part that isn't included in the integer impressions)
      remainingImpressions = totalImpressions - Math.floor(totalImpressions);

      // Update the custom content for this display
      newCustomContent = newCustomContent.map(contentDisplay =>
        contentDisplay.displayId === display.id
          ? { ...contentDisplay, promised_shows: impressions }
          : contentDisplay
      );
    });

    updateCustomContents(newCustomContent);
  };

  const groupAccordingToFilter = () => {
    let displaysGroupAux;

    if (managerDisplaySelector === "country") displaysGroupAux = groupByCountry();
    if (managerDisplaySelector === "company") displaysGroupAux = groupByCompany();
    setDisplaysCollections(displaysGroupAux);
  };

  useEffect(() => {
    if (!managerDisplaySelector) return;
    groupAccordingToFilter();
  }, [cart, managerDisplaySelector, customContents]);

  return (
    <ScreensCollectionHolder>
      {managerDisplaySelector && !disabledManagerDisplayFilter ? (
        <Row type="flex" className="Screens-collection">
          {displaysCollections.map((collection, index) => {
            const spotValue = Math.floor(parseFloat(collection.investmentGroup ? collection.investmentGroup : 0) * 100) / 100 || 0
            return (
              <Row key={index} className="collection" type="flex">
                <Row
                  type="flex"
                  className="title-collection appear-animation-without-movement"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Title level={3} className="margin-text">{t(collection.key)}</Title>
                    {managerDisplaySelector === "country" ? (
                      <CircleFlag className="margin-text" countryCode={getFlag(collection)} height="24" width="24" />
                    ) : (
                      <FontAwesomeIcon className="iconCompany" icon={faBuilding} style={{ fontSize: "20px" }} />
                    )}
                    <Title level={3} className="margin-text">
                      {`(${collection.displays.length || 0})`}
                    </Title>
                  </div>
                  {(programmatic === "programmatic" && (managerDisplaySelector === "company" || managerDisplaySelector === "country")) && (
                    <div style={{ display: 'flex', justifyContent: "flex-end"}}>
                      <span
                        className='textGroup margin-text'
                        style={{alignContent: "center", marginBottom:"5px"}}>
                          {t('Investment')}
                      </span>
                      <span className="proposalGroup" style={{ marginLeft: 3 }}>
                        <CustomInputCurrency
                          currency= { editCampaignActive ? currentCampaign.currency : currency }
                          value={spotValue}
                          onChange={e => handleChangeGroupProposal(e, collection.displays, collection.investmentGroup)}
                          disabled={isPaymentStatusApproved}
                          step={(collection.maxCpm * collection.displays.length) + 1}
                          style={{ width: '400px' }}
                        />
                      </span>
                    </div>
                  )}
                </Row>
                <ScreensGroupContainer
                  displaysCollection={collection.displays}
                  applyRelationOnScreens={applyRelationOnScreens}
                  allDisplaysAreOwn={allDisplaysAreOwn}
                  creditAvailableOnTransferredImpressions={creditAvailableOnTransferredImpressions}
                  updateCreditAvailable={updateCreditAvailable}
                  isPaymentStatusApproved={isPaymentStatusApproved}
                />
              </Row>
            )
          })}
        </Row>
      ) : (
        <ScreensGroupContainer
          displaysCollection={cart}
          applyRelationOnScreens={applyRelationOnScreens}
          allDisplaysAreOwn = {allDisplaysAreOwn}
          isPaymentStatusApproved={isPaymentStatusApproved}
          creditAvailableOnTransferredImpressions={creditAvailableOnTransferredImpressions}
          updateCreditAvailable={updateCreditAvailable}
        />
      )}
    </ScreensCollectionHolder>
  );
};

export default ScreensCollectionComponent;
import React, { useMemo } from 'react';
import { Tooltip, Icon, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

const ContentSpecificationAlertComponent = ({
  displays,
  customContentsFiltered,
  styleCustom,
  listContents,
  listResolutionWithContent,
  hasText = false
}) => {
  const { t } = useTranslation();

  const aletSpecification = useMemo(() => {

    if (displays && displays.length === 0) return false;
    if (listResolutionWithContent && listResolutionWithContent.length === 0 ) return false;

    // Define when send listContents and displays
    if(displays) {
      return displays.some(display => {
        let activeAlert = false;
        if(customContentsFiltered && !listContents) {
          const customContentAux = customContentsFiltered.filter(customContent => customContent.displayId === display.id);
          activeAlert = !customContentAux.some(customContent => display.format_settings[customContent.content.type ])

          if(activeAlert) return true;
          activeAlert = customContentAux.some(customContent => (customContent.content.type === "video" && Math.floor(customContent.content.length / 1000) * 1000 > display.slot_length));
          return activeAlert;
        }
      });
    }

    // Define when send listResolutionWithContent
    if (listResolutionWithContent) {
      let activeAlert = false;
      return listResolutionWithContent.some(resolution =>
        resolution.contentsDisplays.some(contentDisplay => {
          const content = resolution.contents.find(content => content.id === contentDisplay.contentId);
          const display = resolution.displays.find(display => display.id === contentDisplay.displayId);

          if (content && display) {
            activeAlert = !display.format_settings[content.type] ||
                          (content.type === "video" && (Math.floor(content.length / 1000) * 1000) > display.slot_length);
            return activeAlert;
          }
          return false;
        })
      );
    }
  }, [listContents, customContentsFiltered, listResolutionWithContent]);

  const getTooltipMessage = () => {
    if (displays && displays.length === 1) {
      return t('Some content does not meet the specifications on this screen');
    }
    return t('Some content does not meet the specifications on one or more screens in this group');
  };

  return (
    aletSpecification && (
      <>
        <Tooltip
          placement="left"
          title={getTooltipMessage()}
        >
          <Icon
            style={styleCustom ? styleCustom : {fontSize: '20px', marginLeft: '10px', color: '#f44336', verticalAlign: 'bottom'}}
            type="warning"
          />
        </Tooltip>
          {hasText && (
            <Text style={{ color: "#888888", fontSize: "13px", fontStyle: "italic" }}>
                {t("Some content does not meet the specifications on one or more screens")}
            </Text>
          )}
        <br />
      </>
    )
  );
};

export default ContentSpecificationAlertComponent;
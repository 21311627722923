import styled from "styled-components";
import Colors from "../../themes/Colors";

const CampaignManagerHolder = styled.div`
  padding: 120px 0;

  h3 {
    color: ${Colors.primary};
    font-size: 18px;
    font-weight: 600;
  }

  p {
    color: ${Colors.coal};
    font-size: 15px;
  }

  .warningCard {
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    background-color: #f74f48;
    border: 1px solid #e8e8e8;
  }
  .warningTextCard {
    color: white;
  }

  .campaingInfoCard {
    backgroundColor: ${Colors.snow};
    padding: 20px;
    margin-bottom:20px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }

  .successCard {
    margin: 0 0 15px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .successTextCard {
    color: ${Colors.primary};
  }

  .formInput.ant-input::placeholder {
    color: #4242428f;
  }

  .formInput.ant-input:hover,
  .formInput.ant-input:focus {
    border-right-width: 2px !important;
  }

  .formInput input:focus,
  .formInput input[type="text"]:focus,
  .formInput input[type="password"]:focus,
  .formInput input[type="email"]:focus,
  .formInput input[type="number"]:focus,
  .formInput textarea:focus {
    border: 2px solid #424242;
    color: #424242;
  }

  .formInput.ant-input:focus {
    box-shadow: none;
  }

  .draggerContainer {
    margin: 0 0 15px;
  }

  .draggerContainerHelp {
    line-height: 100%;
  }

  .button {
    height: 46px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    padding: 0 25px;
  }

  .clearButton {
    margin-top: 20px;
  }
  .addGeneralRuleButton {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .canvaButton {
    margin-top: 0px;
  }
  .videoUploadEmpty {
    border: dashed;
    border-radius: 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-color: #e0e0e0;
    padding-left: 10% !important;
    padding-right: 10% !important;
    min-height: 200px;
    left: 10px;
  }
  .actuallyBudget {
    margin-right: 2%;
    border: solid;
    border-radius: 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
    color: #1e5285;
  }
  .info-format-file {
    color: #a1a1a1;
    font-size: 13px;
  }
  .textArea {
    margin-bottom: 1%;
  }
  .imprSubsidize {
    color: rgb(252, 189, 118);
    margin-left: 10px;
    font-size: 15px;
  }
  .titleField {
    margin-bottom: 5px !important;
  }
  .selectCategories {
    width: 200px;
  }
  .selectCategoriesCampaignManager {
    width: 200px  ;
  }
  .ant-form-item-children {
    span{
      padding-left: 5px;
    }
  }
  .stepsContent {
    margin-top: 45px;
    margin-bottom: 20px;
  }

  /* Customize collapse element */
  .customPanelStyle {
    background: '#f7f7f7' !important;
    borderRadius: 4 !important;
    marginBottom: 24 !important;
    border: 0 !important;
    ${'' /* overflow: 'hidden'; */}
  };

  .ant-collapse-content {
    border-top: 0px;
  }

  .ant-collapse-header {
    color: ${Colors.primary} !important;
    font-size: 24px !important;
    padding: 20px !important;
    padding-left: 8px !important;
    padding-right: 15px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: auto !important;
    right: 16px !important;
  }

  /* Customize css for collapse content*/
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px;
  }
  .ant-collapse-item {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    gap: 20px;
    border-radius: 8px !important;
    border: 1px !important;
  }
  .ant-collapse-arrow svg {
    height: 28px;
    width: 28px;
    padding: 6px 2px 6px 2px;
  }

  .contentSettingCampaign {
    display: flex;
    padding-left: 7px;
    padding-right: 5px;
  }
  .clientBrandTag {
    color: ${Colors.primary};
    height: 35px;
    padding: 15px;
    margin-right: 10px;
    display: inline;
    font-size: 12px;
    text-align: left;
    word-wrap: break-word;
  }
  .ant-collapse-extra {
    margin-right: 20px;
  }
  .clientBrandTagName {
    color: white;
    background: ${Colors.primary};
    width: Hug (96px);
    height: Fixed (28px);
    padding: 5px 16px 5px 16px;
    border-radius: 4px
  }

  .clientBrandTagNameText {
    color: #6A6A6A;
    margin-right: 6px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .contentBrandTag {
    display: inline;
  }
  .contentMobileBrandTag {
    display: none;
  }

  /* Show brand and tag for mobile in content of panel */
  @media only screen and (max-width: 850px) {
    .ant-collapse-extra {
      display: none;
    }
    .contentMobileBrandTag {
      display: inline;
    }
    .clientBrandTag {
      padding-left: 0px;
    }
    .contentSettingCampaign {
      display: block;
    }
  }

  /* Setting brand and tag for responive */
  @media only screen and (max-width: 768px) {
    .clientBrandTagNameText, .clientBrandTagName {
      font-size: 10px;
    }
  }
  /* Setting brand and tag for responive */
  @media only screen and (max-width: 546px) {
    .ant-collapse-extra {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
      float: left;
    }
    .clientBrandTagNameText, .clientBrandTagName {
      font-size: 9px;
    }
  }

  /* Setting brand and tag for responive */
  @media only screen and (max-width: 470px) {
    .contentBrandTag {
      display: grid;
    }
    .ant-collapse-extra {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .clientBrandTagNameText, .clientBrandTagName {
      font-size: 10px;
    }
  }
  .continueButtons {
    position: fixed;
    width: 11%;
    z-index: 996;
    top: 159px;
    right: 10px;
    height: 45px;
    line-height: 30px;
    background-color: #23CBF2;
    font-size: 18px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    @media only screen and (max-width: 1200px) {
      width: 30%;
      top: 140px;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
      top: 140px;
    }
  }
  .continueButtonsEditCreate {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  .continueButtonsEditCreate i {
    margin-left: 0px !important;
    padding-left: 10px;
  }
  .goBackButtons {
    position: fixed;
    width: 11%;
    z-index: 996;
    top: 159px;
    left: 10px;
    height: 45px;
    line-height: 30px;
    background-color: #23CBF2;
    font-size: 18px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    @media only screen and (max-width: 1200px) {
      width: 30%;
      top: 140px;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
      top: 140px;
    }
  }
  .customPanelStyleDisplays {
    border: 1px solid #e8e8e8;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 4px 6px 2px #0000001A;
  }
  .contentFilter {
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: 10px;
    padding-left: 8px;
  }
  .groupFilter {
    gap:10px;
  }
  .groupFilter .ant-btn {
    border-radius: 8px;
  }
  .createButton {
    padding-left: 10px;
    padding-right: 10px;
    marginLeft: 4px;
  }
`;

export default CampaignManagerHolder;
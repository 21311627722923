import React , { useState, useEffect } from 'react';
import { List , Row, Col, InputNumber, Tooltip, Icon, Button} from "antd";
import ImpressionManagementHolder from "./Styles/ImpressionManagementStyle"
import { useTranslation } from 'react-i18next';
import { calculateImpressions } from "../services/display";
import { calculateValueForPrice } from "../services/utils";
import NumberFormat from './NumberFormat';

const ImpressionManagementComponent = (props) => {

  const { t } = useTranslation();
  const { currentCampaign ,
          companySelected,
          reportSingleData,
          customContents,
          transferModalVisible,
          cart,
          showTransferModal,
          updateImpressionsDisplay,
          creditAvailableOnTransferredImpressions, // Save impresions in transfer when close modal
          updateCreditAvailable } = props;
  const DECIMAL = 8; // Is necessary for not lose precision in the calculations when the cmp are more diferent
  const DECIMAL_FOR_CHECK = 4;
  const [contentsDisplaysSelect, setContentsDisplaysSelect] = useState([]);

  const [creditAvailable, setCreditAvailable] = useState(0);
  const format = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: currentCampaign.currency,
    minimumFractionDigits: 3,
  })

  useEffect(() => {
    let versionCounter = {};
    // Check if there was a transfer of impressions before opening the modal.
    let creditAvailableRegister = creditAvailableOnTransferredImpressions.find(item => item.company_id === companySelected.id);
    if(creditAvailableRegister){
      setCreditAvailableFormat(creditAvailableRegister.creditAvailable);
    } else{
      setCreditAvailable(0);
    }

    let displayIdList = currentCampaign.contents_displays
      .filter(item => item.display_company_id === companySelected.id)
      .map(item => item.display_id);

    let filteredContents = customContents.filter(item => displayIdList.includes(item.displayId));

    // add smart_campaign_cpm
    const updatedContents = filteredContents.map(item => {
      const matchingDisplay = currentCampaign.contents_displays.find(contentDisplay => contentDisplay.display_id === item.displayId)
      if (matchingDisplay) {
        if (!versionCounter[matchingDisplay.display_name]) {
          versionCounter[matchingDisplay.display_name] = 1;
        } else {
          versionCounter[matchingDisplay.display_name]++;
        }
        return {
          ...item,
          name: matchingDisplay.display_name,
          smart_campaign_cpm: matchingDisplay.cpm,
          content_version_name: item.content.content_version_name || "V1"
        };
      }
      return item;
    });
    let updatedContentsOrderByDisplay = [...updatedContents].sort((a, b) => a.displayId - b.displayId);
    setContentsDisplaysSelect(updatedContentsOrderByDisplay);
  }, [transferModalVisible]);

  const setCreditAvailableFormat = (value) => {
    const factor = Math.pow(10, DECIMAL);
    const valueAux = Math.floor(value * factor) / factor;
    setCreditAvailable(valueAux);
  }

  function customRound(value) {
    if (value % 1 >= 0.9) {
      return Math.ceil(value);
    } else {
      return Math.floor(value);
    }
  }

  // Update promised_shows_update in contentsDisplaysSelectAux and update creditAvailable
  const updateImpressions = (value, relationId) => {
    const valueAux = parseInt(value, 10)
    let contentsDisplaysSelectAux = contentsDisplaysSelect.map(item => {
      if (item.relationId === relationId) {
        let promised_shows_update = parseInt((item.promised_shows_update === 0 ||
          item.promised_shows_update) ? item.promised_shows_update : calculateImpressionsView(item))

        //  Add creditAvailable
        if ((valueAux > promised_shows_update) && creditAvailable > 0) {
          let diff = valueAux - promised_shows_update
          let credit = calculateValueForPrice(item.smart_campaign_cpm, diff)
          setCreditAvailableFormat(creditAvailable - credit)
        }

        // Subtract creditAvailable
        if (valueAux < promised_shows_update ) {
          let diff = parseInt(promised_shows_update) - parseInt(valueAux)
          let credit = calculateValueForPrice(item.smart_campaign_cpm, diff)
          if(credit > 0) setCreditAvailableFormat(creditAvailable + credit)
        }
        return {
          ...item,
          promised_shows_update: valueAux,
          promised_shows_origin: item.promised_shows_origin || calculateImpressionsView(item)
        };
      }
      return item;
    });
    setContentsDisplaysSelect(contentsDisplaysSelectAux);
  };

  // Check if there is credit available is enough for the user to continue distributing
  const checkCreditAvailable = () => {
      return contentsDisplaysSelect.some( item => {
          const cpm = parseFloat((item.smart_campaign_cpm / 1000).toFixed(DECIMAL_FOR_CHECK))
          return cpm <= parseFloat(creditAvailable.toFixed(DECIMAL_FOR_CHECK))
      }
      );
  }

  // Update promised_shows to max value
  const updateImpressionsToMax = (relationId) => {
    let contentsDisplaysSelectAux = contentsDisplaysSelect.map(item => {
      if (item.relationId === relationId) {
        let maxImpressions = parseFloat((item.smart_campaign_cpm / 1000).toFixed(DECIMAL_FOR_CHECK));
        const countImpression = customRound(creditAvailable / maxImpressions)
        if ( countImpression < 1 ) return item; // Check if there is credit available
        maxImpressions = Math.floor(countImpression)

        let promised_shows_update = parseInt((item.promised_shows_update === 0 ||
          item.promised_shows_update) ? item.promised_shows_update : calculateImpressionsView(item))

        //  Add creditAvailable
        const creditUsed = countImpression * item.smart_campaign_cpm / 1000
        let sum = maxImpressions + parseInt(promised_shows_update)
        const restCredit = creditAvailable - creditUsed
        setCreditAvailableFormat(restCredit)

        return {
          ...item,
          promised_shows_update: sum,
          promised_shows_origin: item.promised_shows_origin || calculateImpressionsView(item)
        };
      }
      return item;
    });
    setContentsDisplaysSelect(contentsDisplaysSelectAux);
  };

  const calculateImpressionsView = (relation) => {
    if(relation.length < 1) return 0
    if (!relation.content ){
      return relation.promised_shows
    }
    let impressions = calculateImpressions("to_consumed_not_subsidized" , relation, reportSingleData, {id: relation.displayId})
    return impressions
  }

  const calculateBudget = (origin, display) => {
    let impression =
      (origin == "origin") || (origin == "no_origin" && display.promised_shows_update === undefined) ?
      calculateImpressionsView(display) :
      display.promised_shows_update
    let calculate = calculateValueForPrice(display.smart_campaign_cpm, impression)
    return format.format(calculate)
  }

// Calculate the impressions added and subtracted for displays
 const calculateDiffValue = (type, content_display) => {
    if (content_display.promised_shows_update == undefined){
      return 0
    }
    if(type === "add"){
      let aux = content_display.promised_shows_update - content_display.promised_shows_origin
      if(aux > 0){
        return aux
      }
    }
    if(type === "subtract"){
      let aux = content_display.promised_shows_origin - content_display.promised_shows_update
      if(aux > 0){
        return aux
      }
    }
    return 0
  }

  const messageStatus = (item) => {
    if (item.subsidized_price) {
      if (item.promised_shows !== 0) {
        return (
          <span className="ant-tag ant-tag-orange" style={{ marginLeft: "5px", fontSize: "10px" }}>
            {t('Subsidized')}
          </span>
        );
      } else {
        // When the user changes the value to zero.
        return (
          <span className="ant-tag ant-tag-blue" style={{ marginLeft: "5px", fontSize: "10px" }}>
            {t('Suspended')}
          </span>
        );
      }
    }
    if ((item.promised_shows_origin !== item.promised_shows_update ? item.promised_shows_update : calculateImpressionsView(item)) === 0) {
      return (
        <span style={{ color: "red", marginLeft: "5px", fontSize: "12px" }}>
          {t("It will be in suspended state if the value is 0.")}
        </span>
      );
    }
  }

  const limitAddImpressions = (item) => {
    // Convert smart_campaign_cpm (1 impre = 0.001 cpm) to cpm (1 impre = 1 cpm)
    let smart_campaign_cpm_per_impre = item.smart_campaign_cpm / 1000;
    let limit = parseFloat((creditAvailable / smart_campaign_cpm_per_impre).toFixed(DECIMAL_FOR_CHECK));
    if ( limit < 1 || creditAvailable === 0) {
      return  item.promised_shows_update || item.promised_shows_update == 0 ? item.promised_shows_update : calculateImpressionsView(item)
    }
    return item.promised_shows_origin && (item.promised_shows_origin != item.promised_shows_update) ?
          (item.promised_shows_update) + limit: (calculateImpressionsView(item) + limit)
  }

 //Update CustomContents with contentsDisplaysSelect
 const updateImpressionsCustomContents = () => {
   contentsDisplaysSelect.map(item => {
    // promised_shows_update only exists if the user has modified the value
    if (item.promised_shows_update != undefined) {
      let add_max_in_transfer = calculateDiffValue( "add", item )
      let promisedInSingleData = reportSingleData.report ? reportSingleData.report.find(element => element.display === item.displayId && element.content === item.content.id) : null
      let impression_update = item.promised_shows_update + (promisedInSingleData ? promisedInSingleData.shows : 0 )
      updateImpressionsDisplay(item.displayId, item.relationId, impression_update, item.promised_shows_update, add_max_in_transfer);
    } else {
      updateImpressionsDisplay(item.displayId, item.relationId, item.promised_shows, 0 );
    }
   });

   if (creditAvailable || creditAvailable === 0){
    updateCreditAvailable({company_id:companySelected.id, creditAvailable: creditAvailable});
   }

   showTransferModal();
  }

  return (
      <ImpressionManagementHolder>
        <Row type="flex">
          {currentCampaign.company ?
            <Col xs={8} sm={8} md={8} lg={8} xl={8} justify="end" className='col-info'>
                <div style={{ display: "flex"}}>
                  <h3>{t("Screen owners")}:</h3>
                  <h2 className='row-info-name'>
                    {companySelected ? companySelected.name: t("Company")}
                  </h2>
                </div>
            </Col>:null
          }
          <Col
            type="flex"
            align="start"
            xs={15} sm={15} md={15} lg={15} xl={15} justify="end">
              <div className='row-info'>
                <InputNumber
                  disabled
                  min={0}
                  value={Math.max(0, creditAvailable)}
                  formatter={value =>
                    value === 0 ? format.format(0) : format.format(value)
                  }
                  style={{width:"150px"}}
                  className='text-impression-disabled-important'
                  />
                <p style={{marginRight: "10px", paddingTop: "5px"}}>{t('Available credit')}</p>
              </div>
          </Col>
          <Col type="flex" align="center" xs={24} sm={24} md={24} lg={24} xl={24} className='content-list'>
            <List
              style={{ width: "95%" }}
              size="large"
              bordered
              dataSource={contentsDisplaysSelect}
              renderItem={item =>
                <List.Item key={item.id}>
                  <Row style={{width: "100%"}}>
                    <Col style={{paddingTop:"5px"}} xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div style={{marginRight:"10px"}}>{item.name} - {item.content_version_name}</div>
                      { messageStatus(item) }
                     </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Tooltip placement="topLeft" title={t('Increase prints to fill all remaining')}>
                        <Button
                          shape="circle"
                          style={{width: "26px", height: "26px", fontSize: "14px", marginRight: "5px"}}
                          onClick={()=>updateImpressionsToMax(item.relationId)}
                        >
                          <Icon style={{ fontSize: "14px"}} type="arrow-up" />
                        </Button>
                      </Tooltip>
                      <InputNumber
                        style={{ width:"60%" }}
                        min={0}
                        disabled={item.subsidized_price }
                        max={ limitAddImpressions(item) }
                        value={item.promised_shows_origin !== item.promised_shows_update ? item.promised_shows_update : calculateImpressionsView(item)}
                        onChange={(value) => {
                          const newValue = Math.min(Math.max(value, 0), limitAddImpressions(item));
                          updateImpressions(newValue, item.relationId);
                        }}
                        step={1}
                      />
                      { item.promised_shows_origin != item.promised_shows_update ?
                        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="topLeft" title={`${t('Initial spots')}: ${item.promised_shows_origin}`}>
                          <Icon
                            style={{ fontSize: "15px", marginLeft: "5px", color:"#faad14"}}
                            type="info-circle"/>
                        </Tooltip>: null
                      }
                      <br/>
                      <span className="ant-form-text text-impression" style={{paddingLeft:"30px", paddingTop: "2px"}}>
                        {t("Pending spots")}
                      </span>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <InputNumber
                        className='text-impression-disabled'
                        disabled
                        defaultValue={0}
                        step={1}
                        value={calculateDiffValue("subtract", item)} />
                        <br/>
                        <span className="ant-form-text text-impression"> {t("Subtracted spots")}</span>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <InputNumber
                        className='text-impression-disabled'
                        value={calculateDiffValue("add", item)}
                        step={1}
                        disabled
                        defaultValue={0}/><br/>
                      <span className="ant-form-text text-impression">{t("Added spots")}</span>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    { !item.subsidized_price &&
                      <>
                        <InputNumber
                          className='text-impression-disabled'
                          disabled min={0} defaultValue={0}
                          value={calculateBudget("no_origin", item)}
                          />
                          { item.promised_shows_origin != item.promised_shows_update ?
                            <Tooltip
                              placement="topLeft"
                              title={`${t('Initial budget')}: ${calculateBudget("origin", item)}`}
                            >
                              <Icon style={{ fontSize: "15px", marginLeft: "5px", color:"#faad14"}} type="info-circle" />
                            </Tooltip>
                            :null}
                        <br/>
                        <span className="ant-form-text text-impression"> {t('Budget')} </span>
                      </>
                    }
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <span className="ant-form-text text-impression row-info-name">
                        <NumberFormat
                          value={item.smart_campaign_cpm}
                          currency={currentCampaign.currency}
                        />
                        </span>
                        <br/>
                      <span className="ant-form-text text-impression row-info-name"> CPM </span>
                    </Col>
                  </Row>
                </List.Item>
              }
            />
          </Col>
          <Col
            className="action-button"
            style={{marginBottom: "0px"}}
            type="flex"
            align="end"
            xs={24} sm={24} md={24} lg={24} xl={24}>
            { creditAvailable > 0 &&
              <p style={{marginBottom: "0px", fontSize: "10px", color: "#ff9800"}}>
                *{ t(checkCreditAvailable() ?
                 "You have available credit. You must use it to continue distributing.":
                 "Consumed credit. You can apply changes and edit. The remaining credit (n_credite) cannot be used to purchase 1 spot." ).replace(
                      "n_credite",
                      format.format(creditAvailable)
                )}
              </p>
            }
          </Col>
          <Col
            className="action-button"
            type="flex"
            align="end"
            xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button key="Cancel" onClick={showTransferModal}>
              {t('Cancel')}
            </Button>
            <Button
              key="Apply"
              onClick={updateImpressionsCustomContents}
              disabled={checkCreditAvailable()}>
              {t('Apply changes')}
            </Button>
          </Col>
        </Row>
      </ImpressionManagementHolder>
  )
}

export default ImpressionManagementComponent;